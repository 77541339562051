import * as Icons from "@mui/icons-material";
import { roleConstants as roles } from "core/utils/roleUtils";

export const builderAdminMenu = [
	{
		id: "menu_alerts",
		label: "Alerts",
		link: "/alerts",
		icon: <Icons.NotificationImportant />,
	},
	{
		id: "menu_dashboard",
		label: "Dashboard",
		link: "/dashboard",
		icon: <Icons.Dashboard />,
	},
	{
		id: "menu_properties",
		label: "Properties",
		link: "/properties",
		icon: <Icons.CorporateFareOutlined />,
		// roles: [
		//  roles.BUILDER_ADMIN,
		//  roles.BUILDER_GENERAL_MANAGER,
		//  roles.BUILDER_PROJECT_MANAGER,
		// ],
	},
	{
		id: "menu_suppliers",
		label: "Suppliers",
		link: "/builder/connections",
		icon: <Icons.Diversity2Outlined />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		id: "menu_financials",
		label: "Financials",
		link: "/builder/financials",
		icon: <Icons.FolderCopyOutlined />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	// {
	//   id: "menu_crm",
	//   label: "CRM",
	//   link: "builder/crm",
	//   icon: <Icons.Inventory2Outlined />,
	//   roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	// },
	{
		id: "menu_users",
		label: "Users",
		link: "/users",
		icon: <Icons.GroupsOutlined />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		id: "menu_reports",
		label: "Reports",
		link: "/reports",
		icon: <Icons.Summarize />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		id: "menu_estimates",
		label: "Estimates",
		link: "/builder/estimations",
		icon: <Icons.ApprovalOutlined />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		id: `menu_file_manager`,
		label: "File Manager",
		link: "/filemanager",
		icon: <Icons.FolderOutlined />,
	},
	// {
	//  id: "menu_settings",
	//  label: "Settings",
	//  link: "/page/underDevelopment",
	//  icon: <Icons.SettingsOutlined />,
	// },
	// {
	//  id: "menu_orders_history",
	//  label: "Orders History",
	//  icon: <Icons.PaymentsOutlined />,
	//  roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	//  children: [
	//      {
	//          id: "submenu_purchase_orders",
	//          label: "Purchase Orders",
	//          link: "/builder/purchaseOrders",
	//          icon: (
	//              <FontAwesomeIcon
	//                  icon={faShop}
	//                  style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	//              />
	//          ),
	//      },
	//      {
	//          id: "submenu_invoices",
	//          label: "Invoices",
	//          link: "/builder/invoiceOrders",
	//          icon: (
	//              <FontAwesomeIcon
	//                  icon={faFileInvoiceDollar}
	//                  style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	//              />
	//          ),
	//      },
	//      {
	//          id: "submenu_payments",
	//          label: "Payments",
	//          link: "/builder/payments",
	//          icon: (
	//              <FontAwesomeIcon
	//                  icon={faDollarSign}
	//                  style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	//              />
	//          ),
	//      },
	//  ],
	// },

	// {
	//  id: "menu_timelines",
	//  label: "Timelines",
	//  link: "/timelines",
	//  icon: <Icons.TimelineOutlined />,
	//  roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	// },
	// {
	//  id: "menu_timesheets",
	//  label: "TimeSheets",
	//  link: "/timesheet",
	//  icon: <Icons.PunchClockOutlined />,
	// },
	// {
	//  id: "menu_users",
	//  label: "Users",
	//  link: "/users",
	//  icon: <Icons.PeopleOutlineOutlined />,
	//  roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	// },

	// {
	//  id: "menu_change_requests",
	//  label: "Change Requests",
	//  link: "/page/underDevelopment",
	//  icon: <Icons.BorderAllOutlined />,
	//  roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	// },
	{
		id: "menu_signout",
		label: "Signout",
		link: "/login",
		hiddenOn: { mdUp: true },
		icon: <Icons.LogoutOutlined />,
		onClick: () => {
			localStorage.removeItem("token");
		},
	},
];
