import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Paper, Divider } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AppRoleContext from "context/AppRoleContext";

import { selectAllFiles } from "selectors/documentsSelectors";
import { convertCamelToNormal, getMomentDate } from "core/utils/appUtils";
import { get } from "lodash";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAdminRole, isAssignee } from "core/utils/roleUtils";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import moment from "moment";
import SiteLocationMap from "pages/property/SiteLocationMap";
import { getProjectData } from "selectors/projectSelector";
import AddNewProject from "../AddNewProject";

import projectDetailsJobIcon from "assets/project/projectDetailsJobsIcon.png";
import projectDetailsDocumentIcon from "assets/project/projectDetailsDocsIcon.png";
import projectDetailsTeamIcon from "assets/project/projectDetailsMemIcon.png";

export const ProjectDetails = ({ getProjectDetails }) => {
	const appRoleDetails = useContext(AppRoleContext);
	const dispatch = useDispatch();
	const params = useParams();
	const projectData = useSelector(getProjectData);

	const allFiles = useSelector(selectAllFiles);

	const [editProject, setEditProject] = React.useState(false);

	useEffect(() => {
		getProjectDetails();
	}, [dispatch, getProjectDetails]);

	useEffect(() => {
		dispatch(fetchFiles("Project", params.projectId));
	}, [dispatch, params.projectId]);

	return editProject ? (
		<AddNewProject
			onCancel={() => {
				setEditProject(false);
				getProjectDetails();
			}}
			projectId={projectData.projectId}
			selectedProject={{ data: projectData }}
			title={" "}
		/>
	) : (
		<>
			<div style={{ background: "#F2F2F2" }}>
				<div className="m-2 mt-0" data-testid="project-details-header">
					<div
						className="d-flex flex-wrap mb-3"
						style={{ width: "100%", justifyContent: "space-between" }}
					>
						<Typography
							className="h4 mt-4 mb-4 col-lg-7 col-md-6 col-12"
							style={{ fontWeight: "700  " }}
						>
							Project Details
						</Typography>
						<div className="mt-4 col-lg-5 col-md-6 col-12 d-flex justify-content-between">
							<Typography
								className="body1"
								style={{ fontSize: "14px", color: "#8B8B8B" }}
							>
								<div>Last modified</div>
								<div>
									<span>
										{projectData.modifiedBy},{" "}
										{moment(projectData.modifiedDate).calendar()}
									</span>
								</div>
							</Typography>
							<HiddenOn
								isHidden={
									!hasAdminRole(appRoleDetails) ||
									isAssignee(projectData.accessType)
								}
							>
								<button
									className="primaryButton"
									style={{ width: "5rem" }}
									onClick={() => {
										setEditProject(true);
									}}
								>
									<Edit style={{ fontSize: "16px" }} /> Edit
								</button>
							</HiddenOn>
						</div>
					</div>
				</div>

				<div
					className="mt-3 d-flex flex-wrap full-width"
					style={{ padding: "0.7rem" }}
				>
					<div className="col-lg-7 col-md-6 col-12">
						<div className="d-flex full-width">
							{[
								{
									key: "totalJobs",
									label: "Total Jobs",
									data: get(projectData, "jobStatus.jobs").length || 0,
									icon: projectDetailsJobIcon,
								},
								{
									key: "documents",
									label: "Documents",
									data: allFiles.length,
									icon: projectDetailsDocumentIcon,
								},
								{
									key: "teamMembers",
									label: "Team Members",
									data: 0,
									icon: projectDetailsTeamIcon,
								},
							].map((field) => {
								return (
									<div
										style={{ width: "33%" }}
										className="align-items-center ps-2 pe-2 m-2 bg-white d-flex justify-content-between"
									>
										<div>
											<Typography
												variant="body1"
												style={{ fontSize: "18px", color: "#0B2240" }}
											>
												{field.label}
											</Typography>
											<span className="card-value" style={{ lineHeight: 1 }}>
												{field.data || 0}
											</span>
										</div>
										<div>
											<img
												src={field.icon}
												alt={field.label}
												style={{ width: "54px" }}
											/>
										</div>
									</div>
								);
							})}
						</div>
						<RenderPropertyFields projectData={projectData} />
					</div>
					<div className="col-lg-5 col-md-6 col-12">
						<Paper style={{ marginRight: "0.75rem" }}>
							<div id="property-contant-section">
								<div
									id="contact-fields"
									className="m-2 d-flex flex-wrap justify-content-between"
								>
									{[
										{
											key: "projectSupervisor",
											label: "Project Supervisor",
											dataPath: "projectSupervisor",
										},
										{
											key: "mobile",
											label: "Phone",
											dataPath: "property.homeOwnerMobile",
										},
										{
											key: "address",
											label: "Site Address",
											dataPath: "property.address",
										},
									].map((key) => {
										return (
											<div className="m-1 col-6">
												<Typography
													className="body1"
													style={{
														fontSize: "12px",
														fontWeight: 500,
														color: "#0B2240",
													}}
												>
													{key.label}
												</Typography>

												<Typography
													style={{
														fontSize: "12px",
														wordBreak: "all",
														fontWeight: 700,
														color: "#0B2240",
													}}
													className="body1"
												>
													{get(projectData, key.dataPath, "NA") || "NA"}
												</Typography>
											</div>
										);
									})}
								</div>
								<Divider
									className="divider"
									style={{ width: "90%", justifySelf: "center" }}
								/>
								<div id="map-location" className="mt-2">
									<SiteLocationMap propertyData={projectData.property} />
								</div>
							</div>
						</Paper>
						<div
							id="contact-fields"
							className="m-2 d-flex flex-wrap justify-content-between"
						>
							{["createdBy", "createdDate"].map((key) => {
								return (
									<div className="m-1 col-6">
										{projectData[key] && projectData[key] !== "" && (
											<Typography
												className="body1"
												style={{
													fontSize: "12px",
													fontWeight: 500,
													color: "#0B2240",
												}}
											>
												{convertCamelToNormal(key)}
											</Typography>
										)}

										<Typography
											style={{
												fontSize: "12px",
												wordBreak: "all",
												fontWeight: 700,
												color: "#0B2240",
											}}
											className="body1"
										>
											{projectData[key]}
										</Typography>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<Divider className="divider" />
				<div className="d-flex flex-wrap col-12 pt-5 mb-5">
					<div className={"col-12 "}>
						{/* Pass the files, labels, upload status, and existing files to FilesList */}
						<FilesList
							title={"Project Documents"}
							showToolbar
							files={allFiles}
							fileLabels={{}}
							uploadStatus={{}}
						/>
					</div>
				</div>
				{/* </HiddenOn> */}
			</div>
		</>
	);
};

export const RenderPropertyFields = ({ projectData }) => {
	const displayedFields = [
		{
			key: "projectName",
			label: "Project Name",
			dataPath: "projectName",
		},
		{
			key: "projectCode",
			label: "Project Code",
			dataPath: "projectName",
		},
		{
			key: "projectEstimatedCost",
			label: "Estimated Cost ",
			dataPath: "projectEstimatedCost",
			formatter: (data) => `$ ${data}`,
		},
		{
			key: "interiorArea",
			label: "Area in Sq.Ft",
			dataPath: "interiorArea",
			formatter: (data) => `${data ? `${data}Sq.Ft` : "NA"}`,
		},

		{
			key: "plannedStartDate",
			label: "Project Start Date",
			dataPath: "plannedStartDate",
			formatter: (data) => getMomentDate(data, "ll"),
		},
		{
			key: "plannedEndDate",
			label: "Project Completion Date",
			dataPath: "plannedEndDate",
			formatter: (data) => getMomentDate(data, "ll"),
		},
		{
			key: "projectNotes",
			label: "Onboarding Notes",
			dataPath: "projectNotes",
			formatter: (data) => `${data || "NA"}`,
		},

		{
			key: "warrantyStartDate",
			label: "Tenative warranty start date",
			dataPath: "warrantyStartDate",
			formatter: (data) => getMomentDate(data, "ll"),
		},
		{
			key: "warrantyEndtDate",
			label: "Tentative warranty end date",
			dataPath: "warrantyEndtDate",
			formatter: (data) => getMomentDate(data, "ll"),
		},
	];

	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 "}>
				<div className="d-flex flex-wrap col-12">
					{displayedFields.map((field) => {
						return (
							<div className="m-2" style={{ width: field.width || "40%" }}>
								<Typography
									className="body1"
									style={{ fontWeight: 500, color: "#0B2240" }}
								>
									{field.label}
								</Typography>

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{field.formatter
										? field.formatter(get(projectData, field.dataPath, "NA"))
										: get(projectData, field.dataPath, "NA") || "NA"}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default ProjectDetails;
