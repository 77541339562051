import React, { useMemo } from "react";
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	Typography,
	Paper,
} from "@mui/material";

import DownloadExcel from "components/Downloads/CreateExcel";

const EstimateTemplatePDF = (props) => {
	const { type } = props;
	const rows = props.data;
	// Group rows by jobName
	const groupedRows = useMemo(() => {
		return rows.reduce((groups, row) => {
			if (!groups[row.jobName]) {
				groups[row.jobName] = [];
			}
			groups[row.jobName].push(row);
			return groups;
		}, {});
	}, [rows]);

	const handleCalculate = () => {
		const totalEstimate = rows.reduce((sum, row) => sum + (row.total || 0), 0);
		// setTotal(totalEstimate);
		return totalEstimate;
	};
	const calculateGroupTotal = (groupRows) => {
		return groupRows.reduce((sum, row) => sum + (row.total || 0), 0);
	};

	const tableFields = [
		{ field: "hours", text: "Hours" },
		{
			field: "laborRate",
			text: "Labor Rate",
		},
		{
			field: "units",
			text: "Units",
		},
		{
			field: "materialRate",
			text: "Material Rate",
		},
		{
			field: "fixedCost",
			text: "Fixed Cost",
		},
	];

	if (type === "xlsx") {
		return (
			<DownloadExcel
				tableFields={[
					{
						field: "taskName",
						text: "Job Name",
					},
					...tableFields,
					{
						field: "total",
						text: "Total",
					},
				]}
				{...props}
				data={[
					...props.data,
					{
						taskName: "Total Estimated Budget",
						total: handleCalculate(),
						highlight: true,
						highlightColor: "yellow",
					},
				]}
			/>
		);
	}

	return (
		<div className="p-3">
			<div>
				<Typography className="h4">
					Total Budget Estimation - $ {handleCalculate()}
				</Typography>
			</div>
			<TableContainer component={Paper}>
				<Table className="w-full cstm-datagrid-table mb-4">
					<TableHead className="cstm-datagrid-table-header">
						<TableRow className="cstm-datagrid-table-row">
							<TableCell>Job</TableCell>
							{tableFields.map((col) => (
								<TableCell>{col.text}</TableCell>
							))}
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(groupedRows || {}).map(
							([jobName, groupRows], grpIndex) => (
								<>
									<TableRow style={{ background: "#345DAE99", color: "white" }}>
										<TableCell
											style={{
												color: "white",
												fontWeight: "700",
												fontSize: "16px",
											}}
										>
											{jobName}
										</TableCell>
										{tableFields.map((_) => (
											<TableCell></TableCell>
										))}
										<TableCell
											style={{
												color: "white",
												fontWeight: "700",
												fontSize: "16px",
											}}
										>
											$ {calculateGroupTotal(groupRows).toFixed(2)}
										</TableCell>
									</TableRow>
									{groupRows.map((row, subIndex) => (
										<TableRow key={row.estimateItemID}>
											<TableCell style={{ width: " 25%" }}>
												{row.taskName}
											</TableCell>
											{tableFields.map((col) => (
												<TableCell style={{ width: "8%" }}>
													{row[col.field]}
												</TableCell>
											))}
											<TableCell style={{ width: "10%" }}>
												${row.total?.toFixed(2) || "0.00"}
											</TableCell>
										</TableRow>
									))}
									{}
								</>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default EstimateTemplatePDF;
