import {
	ExcelExport,
	ExcelExportColumn,
	ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import React, { useEffect, useRef, useState } from "react";

export const DownloadExcel = ({
	data,
	tableFields,
	children,
	fileName = "estimate-data.xlsx",
	onDownloadComplete,
	shouldSave = true, // Added a prop to control PDF download behavior
	autoDownload = true, // Added a prop to control auto-download behavior
	groupedRows = {},
}) => {
	const [isExporting, setIsExporting] = useState(false);
	const [error, setError] = useState(null);
	const excelExportRef = useRef(null);

	useEffect(() => {
		let timeoutId;

		const exportExcel = async () => {
			if (shouldSave && excelExportRef.current) {
				setIsExporting(true);
				setError(null);

				try {
					// Add a delay if necessary to ensure rendering is complete
					timeoutId = setTimeout(async () => {
						try {
							// Trigger the Excel export
							await excelExportRef.current.save();

							// Call the completion callback
							onDownloadComplete?.();
						} catch (err) {
							setError("Failed to generate Excel file. Please try again.");
							console.error("Excel Export Error:", err);
						} finally {
							setIsExporting(false); // Reset exporting state
						}
					}, 1000); // Optional delay for smoother execution
				} catch (err) {
					setError("Failed to initialize Excel export. Please try again.");
					console.error("Excel Init Error:", err);
					setIsExporting(false);
				}
			}
		};

		exportExcel();

		// Cleanup timeout on component unmount or re-render
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [shouldSave, onDownloadComplete]);
	// Custom row rendering to apply highlights
	const customRowRender = (props) => {
		const { row, dataItem } = props; // Access the row data
		console.log(" customRowRender Row Data : ", props, dataItem);

		// If shouldHighlight is true, add a background color to the row in the Excel file
		if (dataItem.highlight) {
			row.style = { backgroundColor: dataItem.highlightColor || "yellow" }; // Change background color for highlighting
		}

		return row;
	};
	return (
		<div className="p-4">
			{isExporting && <p>Exporting to excel. Please wait...</p>}
			{error && <p className="text-red-500">{error}</p>}
			<ExcelExport
				data={data}
				ref={excelExportRef}
				fileName={fileName}
				customRowRender={customRowRender} // Apply custom row styling
			>
				<ExcelExportColumnGroup>
					{tableFields.map((col) => {
						return <ExcelExportColumn field={col.field} title={col.text} />;
					})}
				</ExcelExportColumnGroup>
			</ExcelExport>
		</div>
	);
};

export default DownloadExcel;
