import { Edit } from "@mui/icons-material";
import {
	Autocomplete,
	CircularProgress,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import {
	fetchProjRoles,
	fetchProjUserRoles,
	fetchUsers,
	updateProjUserRoles,
} from "actions/userActions";
import { FormLabel } from "components/TextFields/FormInputField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getProjectRolesList,
	getProjectUserRolesList,
	getUsersList,
	getUsersLoading,
} from "selectors/userSelector";

export const ManageUsers = ({ projectRefId }) => {
	const dispatch = useDispatch();
	const usersList = useSelector(getUsersList);
	const usersLoading = useSelector(getUsersLoading);
	const { projectRolesList } = useSelector(getProjectRolesList);
	const { projectUsersRolesList } = useSelector(getProjectUserRolesList);

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [selectedRole, setSelectedRole] = React.useState(null);

	useEffect(() => {
		dispatch(fetchUsers({ AllUsers: true }));
		dispatch(fetchProjRoles());
	}, [dispatch, projectRefId]);

	const handleInputChange = (event, newInputValue) => {
		// setInputValue(newInputValue);
		if (newInputValue === "" && event && event.type === "click") {
			console.log("Clear icon clicked - handleInputChange");
		}
	};
	return (
		<div>
			<div className="d-flex flex-wrap">
				<div className="m-2 col-md-5 col-12">
					<FormLabel text={"Select User"} className={"mb-3"} />
					<Autocomplete
						onChange={(event, value) => {
							setSelectedUser(value);
						}}
						// style={{ width: "15rem" }}
						onInputChange={handleInputChange}
						id="asynchronous-demo"
						className="form-field"
						open={autoCompleteOpen}
						onOpen={() => {
							setAutoCompleteOpen(true);
						}}
						onClose={() => {
							setAutoCompleteOpen(false);
							console.log("Check on Close ");
						}}
						isOptionEqualToValue={(option, value) =>
							option.userId === value.userId
						}
						// filterOptions={(x) => x}
						getOptionLabel={(option) =>
							`${option.firstName} ${option.lastName}`
						}
						options={usersList}
						loading={usersLoading}
						renderInput={(params) => (
							<TextField
								{...params}
								className="form-field"
								onChange={(event, value) => {
									const textLen = event.target.value.length;
									if (textLen >= 3) {
										console.log("Fetching the record");
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{usersLoading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</div>
				<div className="m-2 col-md-5 col-12">
					<FormLabel text={"Select Role"} className={"mb-3"} />
					<TextField
						className={`form-field ${selectedUser ? "" : "disabled"}`}
						select
						disabled={!selectedUser}
						onChange={(event) => {
							setSelectedRole(event.target.value);
						}}
					>
						<MenuItem value={"Select Role"} disabled>
							Select Role
						</MenuItem>
						{projectRolesList.map((role) => {
							return (
								<MenuItem value={role.projRoleId}>
									<Typography>{role.projRoleName}</Typography>
								</MenuItem>
							);
						})}
					</TextField>
				</div>
				<div className="d-flex align-items-center mt-sm-4 mt-3 col-md-2 col-2">
					<button
						className="secondaryButton"
						onClick={() => {
							dispatch(
								updateProjUserRoles({
									projectId: projectRefId,
									userId: selectedUser.userId,
									projRoleId: selectedRole,
								})
							);
						}}
					>
						{"Add User"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageUsers;
