import React from "react";
import get from "lodash/get";
import { Typography } from "@mui/material";

export const RenderDataFields = ({
	data,
	fields = { key: "", label: "", dataPath: "", formatter: () => {} },
}) => {
	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 "}>
				<div className="d-flex flex-wrap col-12">
					{fields.map((field) => {
						return (
							<div className="m-2" style={{ width: field.width || "40%" }}>
								<Typography
									className="body1"
									style={{ fontWeight: 500, color: "#0B2240" }}
								>
									{field.label}
								</Typography>

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{field.formatter
										? field.formatter(
												get(data, field.dataPath, "NA"),
												data,
												field
										  )
										: get(data, field.dataPath, "NA") || "NA"}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default RenderDataFields;
