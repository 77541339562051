import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// ErrorMessage Component
const ErrorMessage = ({
	title,
	message,
	severity = "error",
	icon = true,
	customFooter,
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: 600,
				margin: "20px auto",
				padding: "10px",
			}}
		>
			<Alert
				severity={severity}
				icon={icon ? <ErrorOutlineIcon fontSize="medium" /> : false}
				sx={{
					border: "1px solid",
					borderColor: "primary.light",
					boxShadow: 2,
					borderRadius: 2,
					padding: 2,
				}}
			>
				{title && (
					<AlertTitle sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
						{title}
					</AlertTitle>
				)}
				<Typography variant="body1">{message}</Typography>
			</Alert>
			<div>{customFooter && customFooter}</div>
		</Box>
	);
};

// PropTypes for type checking
ErrorMessage.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
	icon: PropTypes.bool,
};

export default ErrorMessage;
