export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP_EMAIL = "SIGNUP_EMAIL";
export const SIGNUP_EMAIL_SUCCESS = "SIGNUP_EMAIL_SUCCESS";
export const SIGNUP_EMAIL_ERROR = "SIGNUP_EMAIL_ERROR";

export const REGISTRATION = "REGISTRATION";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";

export const PAYMENT = "PAYMENT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const GET_GUID_DETAILS = "GET_GUID_DETAILS";
export const GET_GUID_DETAILS_SUCCESS = "GET_GUID_DETAILS_SUCCESS";
export const GET_GUID_DETAILS_ERROR = "GET_GUID_DETAILS_ERROR";

export const SEND_RESET_PASSWORD_EMAIL = "SEND_RESET_PASSWORD_EMAIL";
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS =
	"SEND_RESET_PASSWORD_EMAIL_SUCCESS";
export const SEND_RESET_PASSWORD_EMAIL_ERROR =
	"SEND_RESET_PASSWORD_EMAIL_ERROR";
