import React, { useCallback, useEffect } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import { getCompanyByIdAction } from "actions/companiesActions";
import { getCompanyDetailsSelector } from "selectors/companiesSelectors";
import ContractorBusinessProfile from "../ContractorProfile";
import { Edit } from "@mui/icons-material";
import RenderDataFields from "components/DataFields/RenderDataFields";

export const CompanyProfileContent = () => {
	const userDetails = useSelector(getUserProfileDetails);
	const companyDetails = useSelector(getCompanyDetailsSelector);
	const dispatch = useDispatch();
	const [allowEdit, setAllowEdit] = React.useState(false);

	const fetchCompanyDetails = useCallback(() => {
		dispatch(getCompanyByIdAction(userDetails.companyId));
	}, [userDetails.companyId, dispatch]);

	useEffect(() => {
		fetchCompanyDetails();
	}, [fetchCompanyDetails]);

	const dataFields = [
		{
			key: "companyName",
			label: "Company Name",
			dataPath: "companyName",
			formatter: "",
		},
		{
			key: "companyType",
			label: "Company Type",
			dataPath: "companyType",
		},
		{
			key: "address",
			label: "Company Address",
			dataPath: "address",
			formatter: (_, data) => {
				return (
					<p style={{ fontWeight: 700, color: "#0b2240" }}>{`${
						data.address1
					}, ${data.address2 || ""}, ${data.city} ${data.state} ${
						data.zipcode
					}`}</p>
				);
			},
		},
		{
			key: "website",
			label: "Company Website",
			dataPath: "website",
			formatter: (data) => {
				return data ? (
					<a href={data} target="_blank" rel="noreferrer" className="link-text">
						{data}
					</a>
				) : (
					"NA"
				);
			},
		},
		{
			key: "mobileNumber",
			label: "Contact Number",
			dataPath: "mobileNumber",
			formatter: (data) => {
				return <p style={{ fontWeight: 700, color: "#0b2240" }}>+1 {data}</p>;
			},
		},
		{
			key: "landlineNumber",
			label: "Landline Number",
			dataPath: "landlineNumber",
		},
		{
			key: "marketSectors",
			label: "Market Sectors",
			dataPath: "marketSectors",
		},
		{
			key: "suppliersAndServices",
			label: "Services",
			dataPath: "suppliersAndServices",
			formatter: (data) => {
				const arrayData = data.split(",");
				return arrayData.map((item) => (
					<Chip style={{ fontSize: "12px", margin: "5px" }} label={item} />
				));
			},
		},
		{
			key: "serviceAreas",
			label: "Service Areas",
			dataPath: "serviceAreas",
			formatter: (data) => {
				const arrayData = data.split(",");
				return arrayData.map((item) => (
					<Chip style={{ fontSize: "12px", margin: "5px" }} label={item} />
				));
			},
		},
	];

	return allowEdit ? (
		<div className="p-2 pe-4">
			<div className="d-flex justify-content-end">
				<button
					className="secondaryButton mb-3"
					onClick={() => setAllowEdit(false)}
				>
					Cancel
				</button>
			</div>
			<ContractorBusinessProfile
				isEdit={true}
				editClose={() => {
					setAllowEdit(false);
					fetchCompanyDetails();
				}}
			/>
		</div>
	) : (
		<Box className="p-3">
			<div className="mb-2 d-flex justify-content-between">
				<div>
					<Typography className="h1 mt-3">{"Business Profile"}</Typography>
				</div>
				<button
					className="primaryButton w-auto m-2"
					style={{ alignSelf: "end" }}
					onClick={() => setAllowEdit(true)}
				>
					<Edit style={{ fontSize: "14px" }} /> Edit Details
				</button>
			</div>
			<div className="company-info-content">
				<RenderDataFields data={companyDetails} fields={dataFields} />
			</div>
		</Box>
	);
};

export default CompanyProfileContent;
