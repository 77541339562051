import React from "react";

const DashboardIcon = ({ color = "#345DAE" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.88 14.4C15.6753 14.4 16.32 13.7553 16.32 12.96C16.32 12.1647 15.6753 11.52 14.88 11.52V14.4ZM1.44 11.52C0.644717 11.52 0 12.1647 0 12.96C0 13.7553 0.644717 14.4 1.44 14.4V11.52ZM14.88 11.52C14.0847 11.52 13.44 12.1647 13.44 12.96C13.44 13.7553 14.0847 14.4 14.88 14.4V11.52ZM28.32 14.4C29.1153 14.4 29.76 13.7553 29.76 12.96C29.76 12.1647 29.1153 11.52 28.32 11.52V14.4ZM13.44 12.96C13.44 13.7553 14.0847 14.4 14.88 14.4C15.6753 14.4 16.32 13.7553 16.32 12.96H13.44ZM16.32 1.44C16.32 0.644717 15.6753 0 14.88 0C14.0847 0 13.44 0.644717 13.44 1.44H16.32ZM2.88 12.96C2.88 12.1647 2.23528 11.52 1.44 11.52C0.644717 11.52 0 12.1647 0 12.96H2.88ZM29.76 12.96C29.76 12.1647 29.1153 11.52 28.32 11.52C27.5247 11.52 26.88 12.1647 26.88 12.96H29.76ZM0 12.96C0 13.7553 0.644717 14.4 1.44 14.4C2.23528 14.4 2.88 13.7553 2.88 12.96H0ZM14.88 2.88C15.6753 2.88 16.32 2.23528 16.32 1.44C16.32 0.644717 15.6753 0 14.88 0V2.88ZM26.88 12.96C26.88 13.7553 27.5247 14.4 28.32 14.4C29.1153 14.4 29.76 13.7553 29.76 12.96H26.88ZM14.88 0C14.0847 0 13.44 0.644717 13.44 1.44C13.44 2.23528 14.0847 2.88 14.88 2.88V0ZM14.88 11.52H1.44V14.4H14.88V11.52ZM14.88 14.4H28.32V11.52H14.88V14.4ZM16.32 12.96V1.44H13.44V12.96H16.32ZM0 12.96V20.64H2.88V12.96H0ZM0 20.64C0 25.6769 4.08317 29.76 9.12 29.76V26.88C5.67373 26.88 2.88 24.0862 2.88 20.64H0ZM9.12 29.76H20.64V26.88H9.12V29.76ZM20.64 29.76C25.6769 29.76 29.76 25.6769 29.76 20.64H26.88C26.88 24.0862 24.0862 26.88 20.64 26.88V29.76ZM29.76 20.64V12.96H26.88V20.64H29.76ZM2.88 12.96V9.12H0V12.96H2.88ZM2.88 9.12C2.88 5.67373 5.67373 2.88 9.12 2.88V0C4.08317 0 0 4.08317 0 9.12H2.88ZM9.12 2.88H14.88V0H9.12V2.88ZM29.76 12.96V9.12H26.88V12.96H29.76ZM29.76 9.12C29.76 4.08317 25.6769 0 20.64 0V2.88C24.0862 2.88 26.88 5.67373 26.88 9.12H29.76ZM20.64 0H14.88V2.88H20.64V0Z"
      fill={color} //"#345DAE"
      // stroke={color}
    />
  </svg>
);

export default DashboardIcon;
