import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getProjectData, getProjectOverview } from "selectors/projectSelector";

const generateData = (projectTimeline, projectData) => {
	const projectStartDate = projectData.plannedStartDate;

	return projectTimeline.map((item) => {
		const getStartDate = (date = projectStartDate) => {
			return new Date(date || projectStartDate).getTime();
		};
		const getEndDate = (date) => {
			const jobEndDate = new Date(projectStartDate); // Example date

			// Add 1 day using setDate
			jobEndDate.setDate(jobEndDate.getDate() + 2);

			if (date) {
				return new Date(date).getTime();
			}
			return new Date(jobEndDate).getTime();
		};
		return {
			x: item.activity,
			y: [getStartDate(item.startDate), getEndDate(item.endDate)],
		};
	});
};

const TimelineChart = () => {
	const overviewData = useSelector(getProjectOverview);
	const projectData = useSelector(getProjectData);
	const projectTimeline = overviewData.projectTimeline || [];

	const series = [
		{
			data: generateData(projectTimeline, projectData),
		},
	];

	const options = {
		chart: {
			height: 400,
			type: "rangeBar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			animations: {
				enabled: true,
				easing: "easeinout",
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 10,
				distributed: true,
				dataLabels: {
					hideOverflowingLabels: false,
				},
				barHeight: "50%", // Reduced from 80% to create vertical gaps
				rangeBarGroupRows: true, // Group bars with gaps
			},
		},
		states: {
			hover: {
				filter: {
					type: "darken",
					value: 0.85,
				},
			},
		},
		dataLabels: {
			enabled: true,
			formatter: function (val, opts) {
				return opts.w.globals.labels[opts.dataPointIndex];
			},
			style: {
				colors: ["#fff"],
				fontSize: "12px",
			},
			offsetX: 5,
		},
		xaxis: {
			type: "datetime",
			labels: {
				// formatter: function (value, timestamp, opts) {
				// 	const date = new Date(timestamp);
				// 	console.log(
				// 		"Check the value, ",
				// 		value,
				// 		"timestamp",
				// 		timestamp,
				// 		"opts",
				// 		opts,
				// 		date.toLocaleDateString("en-US", {
				// 			month: "short",
				// 			day: "numeric",
				// 			year: "numeric",
				// 		})
				// 	);
				// Always show date in MMM DD, YYYY format
				// 	return date.toLocaleDateString("en-US", {
				// 		month: "short",
				// 		day: "numeric",
				// 		year: "numeric",
				// 	});
				// },
				// format: "dd MMM yy",
				style: {
					fontSize: "12px",
				},
				// Show all ticks including min and max
				showDuplicates: false,
				datetimeUTC: false,
			},
			min: new Date(projectData.plannedStartDate).getTime(),
			max: new Date(projectData.plannedEndDate).getTime(),
			tickAmount: undefined,
			axisBorder: {
				show: true,
				color: "#E5E7EB",
			},
			axisTicks: {
				show: true,
				color: "#E5E7EB",
			},
			tooltip: {
				enabled: false,
			},
			tickPlacement: "on",
			overwriteCategories: [
				new Date(projectData.plannedStartDate).getTime(),
				new Date(projectData.plannedEndDate).getTime(),
			],
		},
		yaxis: {
			show: false,
			labels: {
				style: {
					fontSize: "12px",
				},
			},
		},
		grid: {
			show: true,
			borderColor: "#E5E7EB",
			strokeDashArray: 0,
			position: "back",
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: false,
				},
			},
			padding: {
				top: 10,
				right: 20,
				bottom: 10,
				left: 10,
			},
		},
		colors: ["#345DAE"],
		legend: {
			show: false,
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				const data = w.config.series[seriesIndex].data[dataPointIndex];
				const startDate = new Date(data.y[0]).toLocaleDateString("en-US", {
					month: "short",
					year: "numeric",
					day: "numeric",
				});
				const endDate = new Date(data.y[1]).toLocaleDateString("en-US", {
					month: "short",
					year: "numeric",
					day: "numeric",
				});
				return `
          <div class="p-2">
            <div class="font-bold">${data.x}</div>
            <div class="text-sm">${startDate} - ${endDate}</div>
          </div>
        `;
			},
		},
		responsive: [
			{
				breakpoint: 768,
				options: {
					plotOptions: {
						bar: {
							barHeight: "40%", // Smaller height on mobile
						},
					},
					xaxis: {
						labels: {
							style: {
								fontSize: "10px",
							},
						},
					},
				},
			},
		],
	};

	return (
		<div className="w-full">
			<div style={{ height: "400px" }}>
				<ReactApexChart
					options={options}
					series={series}
					type="rangeBar"
					height="100%"
					width="100%"
				/>
			</div>
		</div>
	);
};

export default TimelineChart;
