import React, { useEffect } from "react";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import appleLogo from "assets/loginRegistration/appleLogo.png";
// import { activateUserSignIn } from "core/utils/appUtils";
import { Button } from "@mui/material";
import AppleSSOLogin from "react-apple-signin-auth";
import AppleSSOLogin1 from "react-apple-login";
import { app_url, server_url } from "constants/types";

export const AppleLogin = (props) => {
	// const { setModalOpen, setOpen } = props;

	// const auth = getAuth();
	// const provider = new GoogleAuthProvider();

	return (
		<>
			<div>
				<AppleSignIn />
			</div>
		</>
	);
};

export const AppleSignIn = () => {
	return (
		<>
			<div className="social-logo">
				<AppleSSOLogin
					authOptions={{
						clientId: "com.buildercopilot.app", // Service ID from Apple Developer Console
						redirectURI: `${app_url}/applelogin`, // Redirect URI // Redirect URI registered with Apple
						// redirectURI: `https://localhost:3000/applelogin`, // Redirect URI // Redirect URI registered with Apple
						scope: "name email", // Request user's name and email
						// state: "state123", // Optional: Pass state value for CSRF protection
						usePopup: false, // Use popup for authentication
						responseType: "code id_token",
						responseMode: "form_post",
						scopes: ["name", "email"], // Request user info
					}}
					uiType="sign-in" // "sign-in" or "continue"
					className="full-width primaryButton hoverEffect apple-signin-button" // Custom CSS class
					style={{ background: "black", color: "white" }} // Custom style
					noDefaultStyle={false} // Use default Apple button style
					// onSuccess={handleAppleLoginSuccess} // Success callback
					// onError={handleAppleLoginError} // Error callback
				/>
			</div>
		</>
	);
};

export default AppleLogin;
