import React, { useCallback, useEffect, useState } from "react";
import { Close, Delete, EditOutlined, MoreVert } from "@mui/icons-material";
import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	MenuItem,
	MenuList,
	Popover,
	TextField,
	Typography,
} from "@mui/material";
import { fetchEstimatesAction } from "actions/estimationsActions";
import { CustomBreadCrumb } from "components/BreadCrumb";
import DataGridTable from "components/Table/DataGrid";
import HiddenOn from "components/Wrappers/HiddenOn";
import { useDispatch, useSelector } from "react-redux";
import { ModelDialog } from "components/Model";
import { CreateEstimateContent } from "./CreateEstimate";
import { getEstimatesList } from "selectors/estimatesSelectors";
import { useNavigate } from "react-router-dom";

export const EstimatesList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [createModel, setCreateModel] = useState(false);

	const [openModel, setOpenModel] = useState(false);
	const [modelType, setModelType] = useState();

	const { estimatesList, totalCount } = useSelector(getEstimatesList);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const fetchEstimations = useCallback(() => {
		dispatch(fetchEstimatesAction());
	}, [dispatch]);

	useEffect(() => {
		fetchEstimations();
	}, [fetchEstimations]);

	const columns = [
		{
			field: "estimateName",
			headerName: "Estimate Name",
			width: 150,
			renderCell: ({ value, row }) => {
				return (
					<Typography
						variant="body1"
						className="pointer underline-text link-text"
						onClick={() => {
							navigate(`/builder/estimations/${row.estimateId}`, {
								state: {
									estimateName: row.estimateName,
									estimateId: row.estimateId,
								},
							});
						}}
					>
						{value}
					</Typography>
				);
			},
		},
		// {
		// 	field: "propertyName",
		// 	headerName: "Property",
		// 	width: 150,
		// 	renderCell: ({ row }) => {
		// 		return (
		// 			<Typography varinat="body1">{row.property.propertyName}</Typography>
		// 		);
		// 	},
		// },
		{
			field: "propertyAddress",
			headerName: "Address",
			width: 200,
			renderCell: ({ row }) => {
				return <Typography varinat="body1">{row.property.address}</Typography>;
			},
		},
		{
			field: "description",
			headerName: "Notes",
			width: 150,
		},
		{
			field: "homeowner",
			headerName: "Homeowner",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<Typography varinat="body1">{row.property.homeOwnerName}</Typography>
				);
			},
		},
		{
			field: "contact",
			headerName: "Contact",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<Typography varinat="body1">
						{row.property.homeOwnerMobile}
					</Typography>
				);
			},
		},
		{
			field: "actions",
			headerName: "",
			align: "right",
			headerAlign: "right", // Align header text to right
			width: 350,
			renderCell: ({ row }) => {
				return (
					<Box display="flex" justifyContent="flex-end" gap={1} width="100%">
						<Typography
							variant="body1"
							className="primaryButton p-2"
							style={{ color: "white" }}
							onClick={() => {
								console.log("Checlk the click ");
								setModelType("createProject");
								setOpenModel(true);
							}}
						>
							Create Project
						</Typography>
						<Typography
							variant="body1"
							className="primaryButton p-2"
							style={{ color: "white", background: "#28a745" }}
							onClick={() => {
								setModelType("send");
								setOpenModel(true);
							}}
						>
							{" "}
							Send Estimate
						</Typography>
						<IconButton
							aria-describedby={
								Boolean(anchorEl) ? "estimates-more-action" : undefined
							}
							onClick={handleClick}
							size="small"
						>
							<MoreVert fontSize="small" />
						</IconButton>
						<Popover
							id={Boolean(anchorEl) ? "estimates-more-action" : undefined}
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => {
								setAnchorEl(null);
							}}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<MenuList>
								<MenuItem
									onClick={() => {
										navigate(`/builder/estimations/${row.estimateId}`, {
											state: {
												estimateName: row.estimateName,
												estimateId: row.estimateId,
											},
										});
									}}
								>
									Update Estimate
								</MenuItem>
								<MenuItem disabled>Delete Estimate</MenuItem>
							</MenuList>
						</Popover>
					</Box>
				);
			},
		},
	];

	return (
		<div style={{ background: "#DDDDDD" }}>
			<ModelDialog
				open={createModel}
				handleClose={() => {
					setCreateModel(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between text-center align-center align-items-center">
						<Typography className="h5">Create New Estimate</Typography>
						<Close
							style={{ cursor: "pointer", fontSize: "16px" }}
							onClick={() => setCreateModel(false)}
						/>
					</div>
				}
				fullWidth={true}
				dialogContent={
					<CreateEstimateContent
						// template={templateFields}
						setCreateModel={setCreateModel}
					/>
				}
			/>
			<ModelDialog
				open={openModel}
				handleClose={() => {
					setCreateModel(false);
				}}
				fullWidth={true}
				dialogContent={
					<RenderActionsContent
						modelType={modelType}
						handleClose={() => {
							setOpenModel(false);
							setModelType();
						}}
					/>
				}
			/>
			<div className="estimates-list-container  p-2">
				<div className=" d-flex justify-content-between">
					<div>
						<Typography className="h4 m-2 mt-3">Estimates</Typography>
						<CustomBreadCrumb breadCrumbItems={["estimates"]} />
					</div>
					<button
						className="create-estimate-button primaryButton m-2"
						style={{ width: "auto" }}
						onClick={() => {
							setCreateModel(true);
						}}
					>
						Create New Estimate
					</button>
				</div>
				<div>
					<HiddenOn isHidden={false}>
						<Typography className="total-count-text mt-3 mb-3">
							Displaying {`0`}
							of {0} Estimates
						</Typography>
						<Divider className="divider" />

						<div data-testid={"properties-select-div "} className="d-flex">
							<FormGroup className="ms-3" aria-disabled>
								<FormControlLabel
									disabled
									disableTypography
									control={<Checkbox />}
									label="Select All"
								/>
							</FormGroup>
							<IconButton disabled={true} onClick={() => {}}>
								<Delete
									style={{
										fontSize: "20px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Delete</strong>
								</Typography>
							</IconButton>
							<IconButton
								disabled={true}
								// onClick={() =>
								// 	navigate(`/property/edit/${selectedRecord.propertyId}`)
								// }
							>
								<EditOutlined
									style={{
										fontSize: "20px",
										marginTop: "5px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Edit</strong>
								</Typography>
							</IconButton>
						</div>
					</HiddenOn>
				</div>
				<div className="esitmates-list-table-sec">
					<DataGridTable
						columns={columns}
						data={estimatesList}
						showToolbar={false}
						defineRowId={(row) => row.estimateId}
						checkboxSelection={false}
						pagination={false}
					/>
				</div>
			</div>
		</div>
	);
};

export const RenderActionsContent = ({ handleClose, modelType }) => {
	const [emailId, setEmailId] = useState("");

	console.log("Check in render type ", modelType);

	if (modelType === "send") {
		return (
			<>
				<div>
					<Typography className="h4">
						Please enter email address to send this estimate copy
					</Typography>
					<div className="d-flex mt-3 align-center">
						<Typography className="col-3 h6 me-3">Email Address:</Typography>
						<TextField
							className="form-field"
							onChange={(e) => {
								setEmailId(e.target.value);
							}}
						/>
					</div>
					<div className="d-flex mt-3 justify-content-center">
						<button
							className="primaryButton"
							style={{ background: "red", color: "white", width: "6rem" }}
							onClick={() => handleClose()}
						>
							Cancel
						</button>
						<button
							className="primaryButton disabled"
							style={{ background: "#28a745", color: "white" }}
							onClick={() => {
								console.log("Email ID ", emailId);
							}}
							disabled
						>
							Send Estimation
						</button>
					</div>
					<Typography
						variant="subtitle1"
						style={{ color: "red", fontSize: "14px" }}
					>
						This Functionality is currently under development, will be enabled
						Soon...
					</Typography>
				</div>
			</>
		);
	}
	if (modelType === "createProject") {
		return (
			<>
				<div>
					<Typography className={"h3"}>
						Do you want to create a Project and related Jobs with this
						Estimation ?
					</Typography>
					<div className="d-flex justify-content-center mt-4">
						<button
							className="primaryButton"
							style={{ background: "red", width: "6rem", color: "white" }}
							onClick={() => handleClose()}
						>
							Cancel
						</button>
						<button
							className="primaryButton"
							style={{ background: "#28a745", color: "white" }}
							onClick={() => {}}
						>
							Create Project
						</button>
					</div>
				</div>
			</>
		);
	}
	return <></>;
};

export default EstimatesList;
