import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  IconButton,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  FileUploadOutlined,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone"; // Import Dropzone
import { fetchDocumentTypes } from "actions/documentsActions";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentTypes } from "selectors/documentsSelectors";

const FileUpload = ({
  handleFileUpload,
  setFilesState = () => {},
  setFileLabelsState = () => {},
  setDocumentTypeState = () => {},
  instantUpload = false,
  showUploadCancelButtons = false,
  onUploadButtonClick = () => {},
}) => {
  const [files, setFiles] = useState([]);
  const [fileLabels, setFileLabels] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [previewFile, setPreviewFile] = useState(null); // State for file preview
  const dispatch = useDispatch();
  const documentTypes = useSelector(getDocumentTypes);

  //   const documentTypes = [
  //     "Agreement",
  //     "Invoice",
  //     "Receipt",
  //     "Quotation",
  //     "Purchase Order",
  //     "Delivery Order",
  //     "Bill of Lading",
  //     "Shipping Document",
  //     "Insurance Document",
  //     "Customs Document",
  //     "Certificate of Origin",
  //     "Other",
  //   ]; // Define document types

  const allowedFileTypes = [
    "application/pdf", // PDF files
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word (.docx)
    "application/vnd.ms-powerpoint", // PowerPoint (.ppt)
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PowerPoint (.pptx)
    "application/vnd.ms-excel", // Excel (.xls)
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Excel (.xlsx)
    "text/plain", // Plain text (.txt)
    "text/csv", // CSV files
    "image/png", // PNG images
    "image/jpeg", // JPEG images
    "application/zip", // ZIP files
    "message/rfc822", // Email files (.eml)
  ];

  const updateFilesState = (state) => {
    setFiles(state);
    setFilesState(state);
  };
  const updateLabelsState = (state) => {
    setFileLabels(state);
    setFileLabelsState(state);
  };

  // Handle file selection from both the button and drag-and-drop
  const handleFileSelection = (selectedFiles) => {
    const newFiles = selectedFiles.filter(
      (file) =>
        file.size < 10 * 1024 * 1024 && // Ensure file size < 10MB
        allowedFileTypes.includes(file.type)
    );

    if (newFiles.length !== selectedFiles.length) {
      alert("Some files were rejected due to size or unsupported format.");
    }

    const newUploadStatus = {};
    newFiles.forEach((file, index) => {
      newUploadStatus[index] = "uploading"; // Initially mark file as uploading
      //simulateUpload(file, fileIndex); // Start upload simulation
      //handleFileUpload(file);
    });

    updateFilesState((prevFiles) => [...newFiles, ...prevFiles]);

    setUploadStatus((prevStatus) => ({ ...newUploadStatus, ...prevStatus }));
  };

  // Handle label change
  const handleLabelChange = (event, index) => {
    const { value } = event.target;
    updateLabelsState((prevLabels) => ({
      ...prevLabels,
      [index]: value,
    }));
    // setFileLabels();
  };

  const removeFile = (index) => {
    updateLabelsState((prevLabels) => {
      const newLabels = { ...prevLabels };
      delete newLabels[index];
      return newLabels;
    });
    const newState = files.filter((_, i) => i !== index);
    updateFilesState(newState);
  };

  const handleUploadFile = (index) => {
    if (instantUpload) {
      handleFileUpload(files[index], fileLabels[index]);
    }
    removeFile(index);
  };

  const onCancel = () => {
    updateFilesState([]);
    updateLabelsState({});
  };
  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, []);

  // Dropzone configuration for drag-and-drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => handleFileSelection(acceptedFiles),
    accept: allowedFileTypes.join(","),
    maxSize: 10 * 1024 * 1024, // Max file size of 10MB
  });

  const onUploadSuccessCallback = () => {
    onCancel();
  };

  return (
    <Box sx={{ margin: "0 auto", padding: 2 }}>
      <div {...getRootProps()} style={{ marginBottom: "16px" }}>
        <Paper
          elevation={isDragActive ? 6 : 2}
          sx={{
            padding: 3,
            border: isDragActive ? "2px dashed #3f51b5" : "2px dashed #ccc",
            textAlign: "center",
            backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="h6" color="primary">
              Drop the files here...
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ fontSize: "16px", color: "#8B8B8B" }}
            >
              Drop Files to upload <FileUploadOutlined />
              <div>
                (PDF, DOC, DOCX, XLS, XLSX, JPG, PNG, or GIF) Max File Size:
                10MB
              </div>
            </Typography>
          )}
        </Paper>
      </div>

      <br />

      {files.length > 0 && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid item xs={12} md={8} key={index}>
                <Box display="flex" alignItems="center">
                  <Box width="40%">
                    <Typography noWrap>{file.name}</Typography>
                  </Box>
                  <Box width="40%" mx={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Document Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(event) =>
                          setDocumentTypeState(event.target.value)
                        }
                      >
                        {documentTypes.map((type) => {
                          return (
                            <MenuItem value={type.documentTypeId}>
                              {type.documentType}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width="40%" mx={2}>
                    <TextField
                      placeholder="Enter FileName"
                      className="register-form"
                      variant="outlined"
                      size="small"
                      value={fileLabels[index] || ""}
                      onChange={(event) => handleLabelChange(event, index)}
                      fullWidth
                    />
                  </Box>
                  {instantUpload && (
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() => handleUploadFile(index)}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: "14px", color: "blue" }}
                        />
                      </IconButton>
                    </Box>
                  )}
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => removeFile(index)}
                    >
                      <DeleteIcon
                        style={{ fontSize: "14px", color: "black" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {showUploadCancelButtons && files.length > 0 && (
        <div className="upload-files-bnt-container d-flex justify-content-center mt-3">
          <button className="outlinedButton m-2" onClick={() => onCancel()}>
            Cancel
          </button>
          <button
            className="primaryButton m-2"
            onClick={() => onUploadButtonClick(onUploadSuccessCallback)}
          >
            Upload
          </button>
        </div>
      )}
    </Box>
  );
};

export default FileUpload;
