import React, { useEffect, useState } from "react";
import {
	Box,
	TextField,
	Switch,
	FormControlLabel,
	Button,
	Grid,
	CircularProgress,
	Snackbar,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
	fetchAppConfig,
	updateAppConfig,
} from "../../../actions/appConfigActions";
import { selectAppConfig } from "../../../selectors/appConfigSelectors";

const AppConfigUI = () => {
	const dispatch = useDispatch();
	const appConfig = useSelector(selectAppConfig);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setLoading(true);
		dispatch(fetchAppConfig()).finally(() => setLoading(false));
	}, [dispatch]);

	const validationSchema = Yup.object({
		dateFormat: Yup.string().required("Date Format is required"),
		prodUrlUi: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter correct url!"
			)
			.required("Prod URL (UI) is required"),
		devUrlUi: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter correct url!"
			)
			.required("Dev URL (UI) is required"),
		prodUrlAppl: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter correct url!"
			)
			.required("Prod URL (Application) is required"),
		devUrlAppl: Yup.string()
			.matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				"Enter correct url!"
			)
			.required("Dev URL (Application) is required"),
		startOfWeek: Yup.string().required("Start of Week is required"),
	});

	const handleSave = async (values) => {
		setLoading(true);
		try {
			await dispatch(updateAppConfig(values));
			setSuccess(true);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box sx={{ p: 4 }} className="p-4">
			{loading ? (
				<CircularProgress />
			) : (
				appConfig && (
					<Formik
						initialValues={appConfig}
						validationSchema={validationSchema}
						onSubmit={handleSave}
						validateOnChange={false}
						validateOnBlur={false}
						enableReinitialize
					>
						{({
							values,
							handleChange,
							handleSubmit,
							errors,
							touched,
							setFieldTouched,
							isSubmitting,
						}) => (
							<Form onSubmit={handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Field
											name="dateFormat"
											as={TextField}
											fullWidth
											label="Date Format"
											value={values.dateFormat || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("dateFormat", true, false); // Mark field as touched
											}}
											helperText={
												touched.dateFormat && errors.dateFormat
													? errors.dateFormat
													: ""
											}
											error={Boolean(touched.dateFormat && errors.dateFormat)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="prodUrlUi"
											as={TextField}
											fullWidth
											label="Prod URL (UI)"
											value={values.prodUrlUi || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("prodUrlUi", true, false); // Mark field as touched
											}}
											helperText={
												touched.prodUrlUi && errors.prodUrlUi
													? errors.prodUrlUi
													: ""
											}
											error={Boolean(touched.prodUrlUi && errors.prodUrlUi)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="devUrlUi"
											as={TextField}
											fullWidth
											label="Dev URL (UI)"
											value={values.devUrlUi || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("devUrlUi", true, false); // Mark field as touched
											}}
											helperText={
												touched.devUrlUi && errors.devUrlUi
													? errors.devUrlUi
													: ""
											}
											error={Boolean(touched.devUrlUi && errors.devUrlUi)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="prodUrlAppl"
											as={TextField}
											fullWidth
											label="Prod URL (Application)"
											value={values.prodUrlAppl || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("prodUrlAppl", true, false); // Mark field as touched
											}}
											helperText={
												touched.prodUrlAppl && errors.prodUrlAppl
													? errors.prodUrlAppl
													: ""
											}
											error={Boolean(touched.prodUrlAppl && errors.prodUrlAppl)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="devUrlAppl"
											as={TextField}
											fullWidth
											label="Dev URL (Application)"
											value={values.devUrlAppl || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("devUrlAppl", true, false); // Mark field as touched
											}}
											helperText={
												touched.devUrlAppl && errors.devUrlAppl
													? errors.devUrlAppl
													: ""
											}
											error={Boolean(touched.devUrlAppl && errors.devUrlAppl)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Field
											name="startOfWeek"
											as={TextField}
											fullWidth
											label="Start of Week"
											value={values.startOfWeek || ""}
											onChange={(e) => {
												handleChange(e);
												setFieldTouched("startOfWeek", true, false); // Mark field as touched
											}}
											helperText={
												touched.startOfWeek && errors.startOfWeek
													? errors.startOfWeek
													: ""
											}
											error={Boolean(touched.startOfWeek && errors.startOfWeek)}
										/>
									</Grid>
									<Grid item xs={6}>
										<FormControlLabel
											control={
												<Switch
													name="voiceAssistantEnabled"
													checked={values.voiceAssistantEnabled || false}
													onChange={handleChange}
												/>
											}
											label="Voice Assistant Enabled"
										/>
									</Grid>

									<Grid item xs={12}>
										<Button variant="contained" color="primary" type="submit">
											Save Changes
										</Button>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				)
			)}
			<Snackbar
				open={success}
				autoHideDuration={3000}
				onClose={() => setSuccess(false)}
				message="Configuration updated successfully!"
			/>
		</Box>
	);
};

export default AppConfigUI;
