import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.estimates;

export const getEstimatesList = createSelector(selectState, (estimates) => ({
	estimatesList: get(estimates, "estimatesList", []),
	totalCount: get(estimates, "totalCount", 0),
}));
export const getTempaltesList = createSelector(selectState, (estimates) =>
	get(estimates, "templatesList", [])
);

export const getTemplateFields = createSelector(selectState, (estimates) =>
	get(estimates, "templateFields", [])
);
export const getTemplateLoading = createSelector(selectState, (estimates) =>
	get(estimates, "templateLoading", [])
);
