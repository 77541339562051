export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_ERROR = "GET_CONNECTIONS_ERROR";

export const CONNECT_COMPANY = "CONNECT_COMPANY";
export const CONNECT_COMPANY_SUCCESS = "CONNECT_COMPANY_SUCCESS";
export const CONNECT_COMPANY_ERROR = "CONNECT_COMPANY_ERROR";

export const FETCH_CONNECTION_STATUS = "FETCH_CONNECTION_STATUS";
export const FETCH_CONNECTION_STATUS_SUCCESS =
	"FETCH_CONNECTION_STATUS_SUCCESS";
export const FETCH_CONNECTION_STATUS_ERROR = "FETCH_CONNECTION_STATUS_ERROR";

export const GET_CONNECTED_SUPPLIERS = "GET_CONNECTED_SUPPLIERS";
export const GET_CONNECTED_SUPPLIERS_SUCCESS =
	"GET_CONNECTED_SUPPLIERS_SUCCESS";
export const GET_CONNECTED_SUPPLIERS_ERROR = "GET_CONNECTED_SUPPLIERS_ERROR";

export const GET_MARKET_SECTORS = "GET_MARKET_SECTORS";
export const GET_MARKET_SECTORS_SUCCESS = "GET_MARKET_SECTORS_SUCCESS";
export const GET_MARKET_SECTORS_ERROR = "GET_MARKET_SECTORS_ERROR";

export const GET_COMPANY_TYPES = "GET_COMPANY_TYPES";
export const GET_COMPANY_TYPES_SUCCESS = "GET_COMPANY_TYPES_SUCCESS";
export const GET_COMPANY_TYPES_ERROR = "GET_COMPANY_TYPES_ERROR";

export const GET_COMPANY_DETAILS_BY_ID = "GET_COMPANY_DETAILS_BY_ID";
export const GET_COMPANY_DETAILS_BY_ID_SUCCESS =
	"GET_COMPANY_DETAILS_BY_ID_SUCCESS";
export const GET_COMPANY_DETAILS_BY_ID_ERROR =
	"GET_COMPANY_DETAILS_BY_ID_ERROR";
