import React, { useEffect } from "react";

import { InputAdornment, Typography } from "@mui/material";
import { phoneRegExp } from "constants/appConstants";
import { getText } from "core/utils/getContentText";
import { statesList } from "core/utils/statesList";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as yup from "yup";
import { useFormik } from "formik";
import { addNewUser, fetchInviteDetailsByGuid } from "actions/userActions";
import { get, omit } from "lodash";
import FormInputField from "components/TextFields/FormInputField";
import { CopilotFooter, CopilotHeader } from "components/Header";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import { getGuidDetails } from "selectors/userSelector";

export const InviteUsers = ({ userDetails = {} }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const { guidDetails } = useSelector(getGuidDetails);

	useEffect(() => {
		dispatch(fetchInviteDetailsByGuid({ InvGuid: params.GUID }));
	}, [params.GUID, dispatch]);

	const { validationSchema } = addUserForm;
	const initialState = addUserForm.defaultState(userDetails);

	const formik = useFormik({
		initialValues: { ...initialState, referenceId: params.GUID },
		validationSchema: validationSchema,
		onSubmit: () => {
			if (formik.values.password === formik.values.confirmPassword) {
				const extractedFields = omit(formik.values, ["confirmPassword"]);
				dispatch(
					addNewUser(
						{
							...extractedFields,
							InvGuid: params.GUID,
							userType: "user",
							//  role: "user"
						},
						() => {
							navigate("/dashboard");
						}
					)
				);
			}
		},
	});
	return (
		<>
			<CopilotHeader />
			<div className="pb-5 pt-0 registration-form user-invite-form">
				<div className="invite-form-header mt-3 text-center">
					<Typography className="h1 mt-2">Register Your Profile</Typography>
				</div>
				<div className="container ">
					<div id="create-project" className="mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div id="container row">
								<div id="section-1 mt-3">
									<div className="d-flex">
										<div className="form-fields">
											<div className="container row justify-content-between">
												{addUserFields.map((field) => {
													const extraInputProps = get(
														field,
														"extraInputProps",
														{}
													);

													const extraProps =
														typeof extraInputProps === "function"
															? extraInputProps(formik)
															: extraInputProps;

													return (
														<FormInputField
															field={field}
															formik={formik}
															value={formik.values[field.key]}
															key={field.key}
															{...extraProps}
														/>
													);
												})}
												<p
													style={{
														margin: "1rem",
														color: "red",
														fontSize: "12px",
														textAlign: "center",
													}}
												>
													{formik.values.password &&
													formik.values.confirmPassword &&
													formik.values.password !==
														formik.values.confirmPassword
														? "Password doesn't match with confirm password"
														: ""}
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<div className="d-flex justify-content-center align-self-center">
										<button
											type="submit"
											className={`primaryButton m-3 full-width`}
										>
											{"Register"}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<CopilotFooter />
		</>
	);
};

const addUserValidationSchema = yup.object({
	firstName: yup.string("Enter First Name").required("First Name is required"),
	lastName: yup.string("Enter Last Name").required("Last Name is required"),
	// address1: yup.string("Enter Address").required("Address is required"),
	// city: yup.string("Enter city").required("city is required"),
	// state: yup.string("Enter state").required("state is required"),
	// zipCode: yup.string("Enter ZipCode").required("ZipCode is required"),
	password: yup.string("Enter password").required("Password is required"),
	confirmPassword: yup
		.string("Confirm your password")
		.required("Confirm Password is required"),
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	mobileNumber: yup
		.string("Enter your Mobile")
		.required("Mobile is required")
		.matches(phoneRegExp, "Phone number is not valid"),
});

const getLabel = (key) => getText(`registrationForm.user.${key}`);

const addUserFields = [
	{
		key: "referenceId",
		label: getLabel("referenceId"),
		type: "text",
		required: true,
		width: "100%",
		disabled: true,
	},
	{
		key: "firstName",
		label: getLabel("firstName"),
		type: "text",
		required: true,
		width: "50%",
	},
	{
		key: "lastName",
		label: getLabel("lastName"),
		type: "text",
		required: true,
		width: "50%",
	},

	// {
	// 	key: "address1",
	// 	label: getLabel("address1"),
	// 	type: "text",
	// 	width: "50%",
	// 	required: true,
	// },
	// {
	// 	key: "address2",
	// 	label: getLabel("address2"),
	// 	type: "text",
	// 	width: "50%",
	// },
	// {
	// 	key: "city",
	// 	label: getLabel("city"),
	// 	type: "select",
	// 	required: true,
	// 	width: "33%",
	// },
	// {
	// 	key: "state",
	// 	label: getLabel("state"),
	// 	type: "select",
	// 	required: true,
	// 	width: "33%",
	// 	select: true,
	// 	options: statesList,
	// },
	// {
	// 	key: "zipCode",
	// 	label: getLabel("zipCode"),
	// 	type: "text",
	// 	required: true,
	// 	width: "33%",
	// },
	{
		key: "email",
		label: getLabel("email"),
		type: "text",
		required: true,
		width: "50%",
	},
	{
		id: "mobileNumber",
		key: "mobileNumber",
		label: getLabel("mobile"),
		placeholder: "(xxx) xxx-xxxx",
		type: "phone",
		required: true,
		width: "50%",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},
	{
		key: "landPhone",
		label: "landline",
		placeholder: "(xxx) xxx-xxxx",
		type: "phone",
		width: "50%",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},
	{
		key: "password",
		label: getLabel("password"),
		type: "password",
		required: true,
		width: "50%",
	},
	{
		key: "confirmPassword",
		label: getLabel("confirmPassword"),
		type: "password",
		required: true,
		width: "50%",
	},
];

const addUserForm = {
	validationSchema: addUserValidationSchema,
	defaultState: (defaultState) => {
		console.log("Check default State : ", defaultState);
		if (defaultState) {
			return defaultState;
		}
		return Object.fromEntries(
			Object.keys(addUserValidationSchema.fields).map((key) => [key, ""])
		);
	},
};

export default InviteUsers;
