import axios from "axios";
import {
  SETTINGS_GET_COMPANIES_LOADING,
  SETTINGS_GET_COMPANIES,
  SETTINGS_STATUS_ERROR,
  SETTINGS_ADD_COMPANY,
  SETTINGS_ADD_COMPANY_ERROR,
  SETTINGS_ADD_COMPANY_SUCCESS,
  SETTINGS_DELETE_COMPANY_SUCCESS,
  SETTINGS_COMPANIES_CLEAR_SNACKBAR,
} from "../constants/types";
import queryString from "query-string";
import { omit } from "lodash";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchMarketCompanies = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_COMPANIES_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page + 1,
        PageSize: params.pageSize,
      };
      const queryParams = queryString.stringify(newParams);

      axios
        .get(`${server_url}/mktCompany?${queryParams}`)
        .then((response) => {
          dispatch({
            type: SETTINGS_GET_COMPANIES,
            payload: {
              companiesList: response.data.items,
              totalCount: response.data.totalCount,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_STATUS_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all companies data.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateCompany = ({
  formData,
  onSuccess = () => {},
  payload,
}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_ADD_COMPANY,
        payload: {
          loading: true,
        },
      });

      //formData.jobId ? "PUT" : "POST",
      let data = {
        ...formData,
        logo: formData.logo1 ? formData.logo1 : formData.logo,
      };
      data = omit(data, "logo1");
      request({
        method: formData.mktCompanyId ? "PUT" : "POST",
        url: `${server_url}/mktCompany${
          formData.mktCompanyId ? `/${formData.mktCompanyId}` : ""
        }`,
        body: {
          ...data,
        },
      })
        .then((response) => {
          dispatch({
            type: SETTINGS_ADD_COMPANY_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "Company Added Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_ADD_COMPANY_ERROR,
            payload: {
              text: "Error occured during adding Company data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const companyDelete = ({
  companyId,
  pageModel,
  onSuccess = () => {},
}) => {
  console.log("Check the companyDelete Action", companyId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_COMPANIES_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/mktCompany/${companyId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchMarketCompanies({ ...pageModel }));
          onSuccess();

          dispatch({
            type: SETTINGS_DELETE_COMPANY_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "Company Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_STATUS_ERROR,
            payload: {
              text: "Error occured during deleteing Company data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const marketCompanyClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTINGS_COMPANIES_CLEAR_SNACKBAR,
    });
  };
};
