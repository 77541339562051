import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";

export const fetchDashboardData = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_DASHBOARD_DATA,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_url}/property/chartData`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_DASHBOARD_DATA_SUCCESS,
						payload: response.data,
					});
					// dispatch(ProjectGetAll());
					resolve();
				})
				.catch((e) => {
					// console.log("Dahsboard data Error Response : ", e);
					dispatch({
						type: ActionConstants.ADD_NEW_PROJECT_ERROR,
						payload: {
							status: "error",
							message: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const globalSearchAction = (searchText) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_GLOBAL_SEARCH,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_url}/generic/GenSearch?searchText=${searchText}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_GLOBAL_SEARCH_SUCCESS,
						payload: response.data,
					});
					// dispatch(ProjectGetAll());
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_GLOBAL_SEARCH_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const alertNotificationsAction = (payload) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.RECEIVE_ALERT_NOTIFICATIONS,
				payload,
			});
			resolve();
		});
	};
};

export const loadAppConfig = (email = "") => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.APP_CONFIG,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/AppConfig`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.APP_CONFIG_SUCCESS,
						payload: response.data[0],
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					dispatch({
						type: ActionConstants.APP_CONFIG_ERROR,
						payload: {
							status: "error",
							message: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const startAppLoadAction = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.APP_LOADING_START,
				payload: {
					loading: true,
				},
			});
			resolve();
		});
	};
};

export const stopAppLoadAction = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.APP_LOADING_STOP,
				payload: {
					loading: true,
				},
			});
			resolve();
		});
	};
};

export const createAlertAction = (payload, onSuccess = () => {}) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch(startAppLoadAction());
			dispatch({
				type: ActionConstants.CREATE_ALERT,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/alerts`,
				body: payload,
				// body: {
				// 	message: undefined,
				// 	user: JSON.stringify(payload),
				// },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.CREATE_ALERT_SUCCESS,
						payload: response.data[0],
					});
					onSuccess({
						status: "success",
						message: "Alert sent to all Users in Builder copilot",
						loading: false,
					});
					dispatch(stopAppLoadAction());
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.CREATE_ALERT_ERROR,
						payload: {
							status: "error",
							message: "Alert cannot be created at this moment",
							loading: false,
						},
					});
					onSuccess({
						status: "error",
						message: "Alert cannot be created at this moment",
						loading: false,
					});
					dispatch(stopAppLoadAction());
					resolve();
				});
		});
	};
};

export const fetchUserCompanyDetails = (companyId, params) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch(startAppLoadAction());
			dispatch({
				type: ActionConstants.USER_COMPANY_DETAILS,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_url}/company/${companyId}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.USER_COMPANY_DETAILS_SUCCESS,
						payload: response.data,
					});
					dispatch(stopAppLoadAction());
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.USER_COMPANY_DETAILS_ERROR,
						payload: {
							status: "error",
							message:
								"Unable to fetch company details at the moment. Please try again later.",
							loading: false,
						},
					});
					dispatch(stopAppLoadAction());
					resolve();
				});
		});
	};
};

export const showAppApiAlert = (payload = {}) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SHOW_APP_API_ALERT,
				payload,
			});
			resolve();
		});
	};
};
