import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companiesSelector } from "selectors/companiesSelectors";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { getCompaniesAction } from "actions/companiesActions";
import { get } from "lodash";
import Tooltip from "components/Tooltip/Tooltip";
import { useNavigate } from "react-router-dom";

export const CompaniesList = () => {
	const dispatch = useDispatch();
	const { companiesList, totalCount } = useSelector(companiesSelector) || [];
	const navigate = useNavigate();

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		dispatch(
			getCompaniesAction({ pageSize: rowsPerPage, pageNumber: page + 1 })
		);
	}, [dispatch, page, rowsPerPage]);

	const columns = [
		{
			field: "companyName",
			headerName: "Company Name",
			width: 200,
			formatter: (value, company) => (
				<Tooltip title={"Click to view company"} placement="top">
					<Typography
						className="h5 link-text"
						onClick={() => {
							navigate(`/companies/viewCompany/${company.companyId}`);
						}}
					>
						{value}
					</Typography>
				</Tooltip>
			),
		},
		{
			field: "companyProfile",
			headerName: "Type",
			width: 200,
			formatter: (value) => (
				<Typography>{value === "contractor" ? "Builder" : value}</Typography>
			),
		},
		{
			field: "companyType",
			headerName: "Sector",
			width: 200,
		},
		{
			field: "marketSectors",
			headerName: "Status",
			width: 200,
		},
		{
			field: "suppliersAndServices",
			headerName: "Status",
			width: 200,
		},
	];

	return (
		<div className="p-2">
			<div>
				<Grid container className="justify-content-center">
					<TextField
						className="form-field ml-3"
						variant="outlined"
						disabled
						style={{ width: "75%", marginLeft: "1rem" }}
						placeholder={"Search by Company Name/Type/Location"}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									style={{ cursor: "pointer" }}
									onClick={() => {
										console.log("Search clicked");
									}}
								>
									<Search style={{ fontSize: "2rem" }} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</div>
			<div className="projects-content-section">
				<TableContainer
					// sx={{ maxHeight: 440 }}
					className="mt-5"
					component={Paper}
				>
					<Table
						stickyHeader
						aria-label="sticky table"
						className="cstm-datagrid-table"
					>
						<TableHead
							className="cstm-datagrid-table-header"
							style={{ background: "lightgray !important" }}
						>
							<TableRow className="cstm-datagrid-table-row">
								{columns.map((column) => (
									<TableCell
										key={column.field}
										align={column.align}
										style={{
											minWidth: column.minWidth,
											color: "#0B2240",
											// color: "white",
											backgroundColor: "#C4D1EB",
										}}
										className={`cstm-col-header`}
									>
										{column.headerName}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{companiesList
								// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.code}
											className={`cstm-data-row`}
										>
											{columns.map((column) => {
												const company = get(row, "company", {});
												const value = company[column.field];
												var renderCell = value;
												if (column.formatter) {
													renderCell = column.formatter(value, company, row);
												}
												if (column.renderCell) {
													renderCell = column.renderCell(value, company, row);
												}
												return (
													<TableCell
														key={column.field}
														align={column.align}
														className="cstm-datagrid-data-col"
													>
														{renderCell}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={totalCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div>
		</div>
	);
};

export default CompaniesList;
