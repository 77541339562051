import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateJob, jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import {
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	Typography,
} from "@mui/material";
import AddEditJob from "pages/job/AddNewJob";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import { jobListcolumns } from "pages/job/addJobUtils";
import NoResultsFound from "components/NoResults/NoResultsFound";
import { ViewJob } from "routes/loadables";
import AppRoleContext from "context/AppRoleContext";
import { ModelDialog } from "components/Model";
import { CloseOutlined, Delete, EditOutlined } from "@mui/icons-material";
import JobAssignment from "./JobAssignment";
import HiddenOn from "components/Wrappers/HiddenOn";
import { isJobManager } from "core/utils/roleUtils";
import ExpandableDataGrid from "components/Table/ExpandableDataGrid";
import JobTasks from "../tasks/JobTasks";
import { useParams } from "react-router-dom";

export const ProjectJobsList = ({
	projectId,
	selectedJob,
	setSelectedJob,
	projectData,
}) => {
	const dispatch = useDispatch();
	const appRoleDetails = useContext(AppRoleContext);
	const params = useParams();

	const projectUserRole = get(projectData, "projUserRole", {});

	const { jobsList, totalCount } = useSelector(getJobsList);

	const [addNewJob, setAddNewJob] = useState(false);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	// eslint-disable-next-line no-unused-vars
	const [editJob, setEditJob] = useState(false);
	const [addTask, setAddTask] = useState(false);

	const [viewJob, setViewJob] = useState(null);

	const [userManagement, setUserManagement] = useState(false);
	const [jobActionsContext, setJobActionsContext] = useState(null);

	useEffect(() => {
		dispatch(
			jobGetAll({
				...pageModel,
				pageNumber: pageModel.page + 1,
				projectId: projectId,
			})
		);
	}, [dispatch, pageModel, projectId]);

	useEffect(() => {
		if (params.jobId) {
			if (jobsList && jobsList.length > 0) {
				const selectedJob = jobsList.find((job) => {
					console.log("Check the Job in find: ", job);
					return job.jobId === parseInt(params.jobId);
				});
				console.log(
					"Check the Selected Job",
					selectedJob,
					jobsList,
					params.jobId
				);
				setViewJob(selectedJob);
			}
		}
	}, [params.jobId, jobsList]);

	if (addTask) {
		return (
			<AddEditTask
				onCancel={() => setAddTask(false)}
				parentIds={{ jobId: selectedJob.id, projectId }}
				jobId={selectedJob.id}
				projectId={projectId}
			/>
		);
	}

	if (viewJob && viewJob.jobId) {
		return (
			<ViewJob
				selectedJob={viewJob}
				jobId={viewJob.jobId}
				projectId={projectId}
				onJobCancel={() => {
					setViewJob(null);
				}}
			/>
		);
	}

	return (
		<div className="jobs-page">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography className="h4 ">{"Assign Job"}</Typography>
						<IconButton
							onClick={() => {
								setUserManagement(false);
								setSelectedJob({});
							}}
						>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<>
						<JobAssignment
							jobRefId={jobActionsContext}
							projectId={projectId}
							jobId={get(selectedJob, "data.jobId", null)}
							jobData={get(selectedJob, "data", {})}
							defaultPayload={{ ...pageModel, projectId: projectId }}
							handleClose={() => {
								setUserManagement(false);
								setSelectedJob({});
							}}
						/>
						{/* <JobTeamList projectRefId={jobActionsContext} title={" "} /> */}
					</>
				}
			/>
			{addNewJob ? (
				<AddEditJob
					onCancel={() => setAddNewJob(false)}
					jobId={get(selectedJob, "data.jobId", null)}
					selectedJob={selectedJob}
					projectId={{ projectId }}
					title={get(selectedJob, "data.jobName", null)}
					propData={{ ...pageModel, projectId: projectId }}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div
							className="d-flex justify-content-between ms-3 me-3 mt-4"
							data-testid="jobs-title-header"
						>
							<Typography className="h1">Jobs</Typography>
							<HiddenOn
								isHidden={!isJobManager(appRoleDetails, projectUserRole)}
							>
								{!addNewJob && (
									<div className="">
										<div className="d-flex justify-content-end ">
											<button
												className="primaryButton addProjectButton"
												style={{ width: "8rem" }}
												onClick={() => {
													setAddNewJob(!addNewJob);
													setSelectedJob({});
												}}
											>
												{"+ New Job"}
											</button>
										</div>
									</div>
								)}
							</HiddenOn>
						</div>
					</div>
					<HiddenOn isHidden={totalCount === 0 || jobsList.length === 0}>
						<Typography className="total-count-text m-3">
							Displaying{" "}
							{`${pageModel.pageSize * pageModel.page + 1} - ${Math.min(
								pageModel.pageSize * (pageModel.page + 1),
								totalCount
							)}`}{" "}
							of {totalCount}
						</Typography>
						<Divider className="divider" />

						<div data-testid={"jobs-list-select-div ms-2"} className="d-flex">
							<FormGroup className="ms-3" aria-disabled>
								<FormControlLabel
									disabled
									disableTypography
									control={<Checkbox />}
									label="Select All"
								/>
							</FormGroup>
							<IconButton disabled={true} onClick={() => {}}>
								<Delete
									style={{
										fontSize: "20px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Delete</strong>
								</Typography>
							</IconButton>
							<IconButton
								disabled={true}
								// onClick={() =>
								// 	navigate(`/property/edit/${selectedRecord.propertyId}`)
								// }
							>
								<EditOutlined
									style={{
										fontSize: "20px",
										marginTop: "5px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Edit</strong>
								</Typography>
							</IconButton>
						</div>
					</HiddenOn>
					<div data-testid="jobs-list-content-section" className=" mt-3 p-2">
						{totalCount > 0 ? (
							<ExpandableDataGrid
								totalCount={totalCount}
								data={jobsList}
								defineRowId={(row) => row.jobId}
								onPageChange={(pageModel) => {
									setPageModel(pageModel);
								}}
								// hasExpandbleContent={(row) => get(row, "tasks", []).length > 0}
								columns={jobListcolumns({
									setViewJob,
									setEditJob,
									setAddTask,
									setAddNewJob,
									setSelectedJob,
									onJobDelete: (jobId) => {
										dispatch(jobDelete({ jobId, projectId }));
									},
									appRoleDetails,
									projectData,
									setUserManagement,
									setJobActionsContext,
									updateStatus: (status, job) => {
										dispatch(
											addUpdateJob({
												formData: { ...job, status },
												payload: { ...pageModel, projectId },
											})
										);
									},
								})}
								{...pageModel}
								expandableContent={({ row }) => {
									const tasksList =
										get(row, "taskListResponse.tasks", []) || [];
									return (
										<>
											<JobTasks
												tasksData={tasksList}
												job={row}
												jobId={row.jobId}
												fromTable={true}
											/>
											<div></div>
										</>
									);
								}}
								disableSelect={true}
							/>
						) : (
							<NoResultsFound message="No jobs have been created." />
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ProjectJobsList;
