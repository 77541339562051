import React, { useState, useEffect, useContext } from "react";
import AddEditJob from "./AddNewJob";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateJob, jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import { IconButton, Typography } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";

import { useNavigate } from "react-router-dom";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import { jobListcolumns } from "./addJobUtils";
import NoResultsFound from "components/NoResults/NoResultsFound";
import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAppRole, roleConfigKeys } from "core/utils/roleUtils";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";
import { getPropertyData } from "selectors/propertySelectors";
import { getProjectData } from "selectors/projectSelector";
import ExpandableDataGrid from "components/Table/ExpandableDataGrid";

export const Jobs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { jobsList, totalCount } = useSelector(getJobsList);

	const [addNewJob, setAddNewJob] = useState(false);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editJob, setEditJob] = useState(false);
	const [addTask, setAddTask] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const [userManagement, setUserManagement] = useState(false);
	const [jobActionsContext, setJobActionsContext] = useState(null);

	const propertyData = useSelector(getPropertyData);
	const projectData = useSelector(getProjectData);

	const [viewJob, setViewJob] = useState(null);
	
	const appRoleDetails = useContext(AppRoleContext);

	useEffect(() => {
		const projectId = get(projectData, "projectId", null);
		const propertyId = get(propertyData, "propertyId", null);

		const jobsQueryParams = {
			propertyId,
			projectId,
		};

		if (!propertyId) {
			delete jobsQueryParams.propertyId;
		}

		if (!projectId) {
			delete jobsQueryParams.projectId;
		}

		if (projectId) {
			delete jobsQueryParams.propertyId;
		}

		dispatch(jobGetAll({ ...pageModel, ...jobsQueryParams }));
	}, [dispatch, pageModel, projectData, propertyData]);

	if (addTask) {
		return (
			<AddEditTask
				onCancel={() => setAddTask(false)}
				parentId={{ jobId: selectedJob.id }}
			/>
		);
	}

	return (
		<div className="jobs-page">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography className="h4 mb-4">{"Assign Job"}</Typography>
						<IconButton
							onClick={() => {
								setUserManagement(false);
							}}
						>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<>
						{/* <ManageJobUsers
							jobRefId={jobActionsContext}
							projectId={projectId}
						/> */}
						<JobTeamList projectRefId={jobActionsContext} title={" "} />
					</>
				}
			/>
			{addNewJob ? (
				<AddEditJob
					onCancel={() => setAddNewJob(false)}
					jobId={editJob}
					selectedJob={selectedJob}
					title={get(selectedJob, "data.projectName", null)}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div>
							<Typography className="h1">Jobs</Typography>
							{/* <BreadCrumb /> */}
						</div>
					</div>
					<div className="projects-content-section">
					{totalCount > 0 ? (
							<ExpandableDataGrid
								totalCount={totalCount}
								data={jobsList}
								defineRowId={(row) => row.jobId}
								onPageChange={(pageModel) => {
									setPageModel(pageModel);
								}}
								// hasExpandbleContent={(row) => get(row, "tasks", []).length > 0}
								columns={jobListcolumns({
									setViewJob,
									setEditJob,
									setAddTask,
									setAddNewJob,
									setSelectedJob,
									onJobDelete: (jobId) => {
										dispatch(jobDelete({ jobId, projectId: projectData?.projectId }));
									},
									appRoleDetails,
									projectData,
									setUserManagement,
									setJobActionsContext,
									updateStatus: (status, job) => {
										dispatch(
											addUpdateJob({
												formData: { ...job, status },
												payload: { ...pageModel, projectId: projectData?.projectId },
											})
										);
									},
								})}
								{...pageModel}
								expandableContent={({ row }) => {
									const tasksList =
										get(row, "taskListResponse.tasks", []) || [];
									
								}}
								disableSelect={true}
							/>
						) : (
							<NoResultsFound message="No jobs have been created." />
						)}
					</div>
				</>
			)}

			<HiddenOn
				isHidden={!hasAppRole(roleConfigKeys.CREATE_JOB, appRoleDetails)}
			>
				{!addNewJob && (
					<div className="page-footer  fixed-footer align-content-center">
						<div className="d-flex justify-content-end ">
							<button
								className="primaryButton addProjectButton"
								style={{ width: "8rem" }}
								onClick={() => {
									setAddNewJob(!addNewJob);
								}}
							>
								{"+ Add Job"}
							</button>
						</div>
					</div>
				)}
			</HiddenOn>
		</div>
	);
};

export default Jobs;
