import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import NoResultsFound from "components/NoResults/NoResultsFound";
// Mock user data

export const BillingInformation = () => {
	return (
		<div className="align-content-center" style={{ height: "100%" }}>
			<NoResultsFound headerType="h4" message={"No Data Available Currently"} />
		</div>
	);
	<Box className="space-y-4">
		<Typography variant="h6" className="mb-4">
			Billing History
		</Typography>
		{[
			{ date: "2024-12-01", amount: "$9.99", status: "Paid" },
			{ date: "2024-11-01", amount: "$9.99", status: "Paid" },
			{ date: "2024-10-01", amount: "$9.99", status: "Paid" },
		].map((invoice, index) => (
			<Card key={index} className="border border-gray-200">
				<CardContent className="flex justify-between items-center">
					<Box>
						<Typography variant="subtitle1">
							Invoice #{2024001 + index}
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{invoice.date}
						</Typography>
					</Box>
					<Box className="text-right">
						<Typography variant="h6">{invoice.amount}</Typography>
						<Typography
							variant="body2"
							className="text-green-600 bg-green-50 px-2 py-1 rounded"
						>
							{invoice.status}
						</Typography>
					</Box>
				</CardContent>
			</Card>
		))}
	</Box>;
};
export default BillingInformation;
