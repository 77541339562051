import axios from "axios";
import {
  PROJECT_GET_WARRANTIES_LOADING,
  PROJECT_GET_WARRANTIES,
  PROJECT_WARRANTY_STATUS_ERROR,
  PROJECT_ADD_WARRANTY,
  PROJECT_ADD_WARRANTY_ERROR,
  PROJECT_ADD_WARRANTY_SUCCESS,
  PROJECT_DELETE_WARRANTY_SUCCESS,
  PROJECT_WARRANTY_CLEAR_SNACKBAR,
} from "../constants/types";
import queryString from "query-string";
import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchWarranties = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_WARRANTIES_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PropertyId: params.PropertyId,
        ProjectId: params.ProjectId,
        PageNumber: params.page + 1,
        PageSize: params.pageSize,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/warranty?${queryParams}`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: PROJECT_GET_WARRANTIES,
            payload: {
              warranties: response.data.items,
              totalCount: response.data.totalCount,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_WARRANTY_STATUS_ERROR,
            payload: {
              status: "error",
              message: "Error occured during getting all warranties.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateWarranty = ({
  formData,
  onSuccess,
  payload,
  updateFiles = true,
  CloseAddView = () => {},
}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_ADD_WARRANTY,
        payload: {
          loading: true,
        },
      });

      //formData.jobId ? "PUT" : "POST",
      let data = {
        ...formData,
      };
      // data = omit(data, "logo1");
      request({
        method: formData.warrantyId ? "PUT" : "POST",
        url: `${server_url}/warranty${
          formData.warrantyId ? `/${formData.warrantyId}` : ""
        }`,
        body: {
          ...data,
        },
      })
        .then(async (response) => {
          //onSuccess();

          // dispatch({
          //   type: PROJECT_ADD_WARRANTY_SUCCESS,
          //   payload: {
          //     response: response.data,
          //     status: "success",
          //     message: "Warranty Added Successfully",
          //     loading: false,
          //     showSnackbar: true,
          //   },
          // });

          if (updateFiles) {
            const { receipts1, productPhotos1, PropertyId, ProjectId } =
              formData;
            try {
              dispatch(
                bulkUploadFiles(
                  [receipts1, productPhotos1],
                  [
                    "rcpt_" + receipts1.name,
                    "prodImages_" + productPhotos1.name,
                  ],
                  3,
                  formData?.warrantyId || response.data.warrantyId,
                  "Warranty",
                  onSuccess,
                  CloseAddView
                )
              );

              dispatch(
                fetchWarranties({
                  PropertyId,
                  ProjectId,
                  pageSize: 10,
                  pageNumber: 1,
                })
              );

              resolve();
            } catch (e) {
              CloseAddView(e);
              console.log("Error", e);
            }
          } else {
            onSuccess();
            dispatch({
              type: PROJECT_ADD_WARRANTY_SUCCESS,
              payload: {
                response: response.data,
                status: "success",
                message: "Warranty Added Successfully",
                loading: false,
                showSnackbar: true,
              },
            });
          }
        })
        .catch((e) => {
          CloseAddView();
          dispatch({
            type: PROJECT_ADD_WARRANTY_ERROR,
            payload: {
              message: "Error occured during adding Warranty data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const warrantyDelete = ({
  warrantyId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the warrantyDelete Action", warrantyId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_WARRANTIES_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/warranty/${warrantyId}`,
        method: "DELETE",
      })
        .then((response) => {
          if (showSnackbar) {
            dispatch({
              type: PROJECT_DELETE_WARRANTY_SUCCESS,
              payload: {
                response: response.data,
                status: "success",
                message: "Warranty Deleted Successfully",
                loading: false,
                showSnackbar: true,
              },
            });
          }
          //dispatch(fetchWarranties({ ...pageModel }));
          onSuccess();

          resolve();
        })
        .catch((e) => {
          if (showSnackbar) {
            dispatch({
              type: PROJECT_WARRANTY_STATUS_ERROR,
              payload: {
                message: "Error occured during deleteing Warranty data.",
                status: "error",
                loading: false,
                showSnackbar: true,
              },
            });
          }
          resolve();
        });
    });
  };
};

export const warrantyClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PROJECT_WARRANTY_CLEAR_SNACKBAR,
    });
  };
};
