import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.fileManager || {};

export const getProperties = createSelector(selectState, (properties) => ({
  propertyList: get(properties, "propertyList", null),
  totalCount: get(properties, "totalCount", 0),
  loading: get(properties, "loading", false),
  showSnackbar: get(properties, "showSnackbar", false),
  status: get(properties, "status", "success"),
  statusText: get(properties, "text", ""),
  jobs: get(properties, "jobs", []),
  jobs_loading: get(properties, "jobs_loading", false),
  hasMore: get(properties, "hasMore", false),
}));
