import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import axios from "axios";
import {
  PROPERTY_LOADING,
  PROPERTY_GET_ALL_SUCCESS,
  PROPERTY_ERROR,
  PROPERTY_CLEAR_SNACKBAR,
} from "../constants/types";
import queryString from "query-string";
import { getListOfPastYears } from "core/utils/appUtils";
import { bulkUploadFiles } from "./documentsActions";

export const addNewProperty = (formData, filesData, onSuccess, navigate) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ActionConstants.ADD_NEW_PROPERTY,
        payload: {
          loading: true,
        },
      });
      request({
        method: formData.propertyId ? "PUT" : "POST",
        url: `${server_url}/property${
          formData.propertyId ? `/${formData.propertyId}` : ""
        }`,
        body: {
          ...formData,
        },
      })
        .then((response) => {
          dispatch({
            type: ActionConstants.ADD_NEW_PROPERTY_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              message: "Property Added Successfully",
              loading: false,
            },
          });
          if (!formData.propertyId) {
            console.log("Check before Navigateing response", response);
            navigate(`/properties/${response.data.propertyId}`, {
              state: {
                propertyName: response.data.propertyName,
                propertyId: response.data.propertyId,
              },
            });
          }

          const { filesState, fileLabelsState, documentTypeState } = filesData;

          dispatch(
            bulkUploadFiles(
              filesState,
              fileLabelsState,
              documentTypeState,
              formData.propertyId || response.data.propertyId,
              "Property",
              onSuccess(response.data)
            )
          );

          if (formData.propertyId) {
            dispatch(getPropertyById(formData.propertyId));
          } else {
            dispatch(propertyGetAll({ pageNumber: 1, pageSize: 15 }));
          }
          resolve();
        })
        .catch((e) => {
          console.log("Error Response : ", e);
          const errorResponse = get(e, "response", {});
          dispatch({
            type: ActionConstants.ADD_NEW_PROPERTY_ERROR,
            payload: {
              status: "error",
              message:
                errorResponse.status === 401
                  ? "Bad Credentials"
                  : "Something Went Wrong",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const propertyGetAll = (params) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROPERTY_LOADING,
        payload: {
          loading: true,
        },
      });
      const queryParams = queryString.stringify(params);
      request({
        method: "GET",
        url: `${server_url}/property?${queryParams}`,
      })
        .then((response) => {
          dispatch({
            type: PROPERTY_GET_ALL_SUCCESS,
            payload: {
              propertyList: response.data.items,
              totalCount: response.data.totalCount,
              loading: false,
              hasMore: response.data.items.length > 0 ? true : false,
              status: "success",
              text: "Get All PROPERTY data successfully.",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROPERTY_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting PROPERTY data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const getPropertiesFor2Years = (params) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ActionConstants.GET_PAST_PROPERTIES,
        payload: {
          loading: true,
        },
      });
      const queryParams = queryString.stringify(params);
      const past2YearsList = getListOfPastYears(2, false);
      Promise.allSettled(
        past2YearsList.map((year) =>
          request({
            method: "GET",
            url: `${server_url}/property?year=${year}&${queryParams}`,
          }).then((response) => ({ year, response }))
        )
      )
        .then((responses) => {
          const successfulResponses = responses
            .filter((result) => result.status === "fulfilled")
            .map((result) => ({
              year: result.value.year,
              data: result.value.response.data,
            }));

          const failedResponses = responses
            .map((result, index) => ({
              ...result,
              year: past2YearsList[index],
            })) // Attach year before filtering
            .filter((result) => result.status === "rejected") // Now filter
            .map((result) => ({
              year: result.year, // Use the attached year
              data: [], // Failed requests have no data
            }));
          const responsePayload = [...successfulResponses, ...failedResponses];

          dispatch({
            type: ActionConstants.GET_PAST_PROPERTIES_SUCCESS,
            payload: {
              pastProperties: responsePayload,
              loading: false,
              status: "success",
              text: "Get All PROPERTY data successfully.",
            },
          });
        })
        .catch((e) => {
          console.log("Check the past properties error :", e);
          dispatch({
            type: ActionConstants.GET_PAST_PROPERTIES_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting PROPERTY data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const getPropertyById = (propertyId) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ActionConstants.PROPERTY_BY_ID_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        method: "GET",
        url: `${server_url}/property/${propertyId}`,
      })
        .then((response) => {
          dispatch({
            type: ActionConstants.PROPERTY_BY_ID_SUCCESS,
            payload: {
              propertyData: response.data,
              loading: false,
              status: "success",
              text: "Get All PROPERTY data successfully.",
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: ActionConstants.PROPERTY_BY_ID_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting PROPERTY data.",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const setPropertyData = (propertyData) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: ActionConstants.PROPERTY_BY_ID_SUCCESS,
        payload: {
          propertyData,
          loading: false,
          status: "success",
          text: "Set Property data successfully.",
        },
      });

      resolve();
    });
  };
};

export const propertyDelete = (propertyId, onSuccess) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROPERTY_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        method: "DELETE",
        url: `${server_url}/property/${propertyId}`,
      })
        .then((response) => {
          // dispatch(propertyGetAll());
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((e) => {
          dispatch({
            type: PROPERTY_ERROR,
            payload: {
              text: "Error occured during deleteing PROPERTY data.",
              status: "error",
              loading: false,
            },
          });
          resolve();
        });
    });
  };
};

export const propertyAddOrUpdate = (property, state) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROPERTY_LOADING,
        payload: {
          loading: true,
        },
      });
      if (state === "add") {
        axios
          .post(`${server_url}/property`, property)
          .then((response) => {
            axios
              .get(`${server_url}/property`)
              .then((response) => {
                dispatch({
                  type: PROPERTY_GET_ALL_SUCCESS,
                  payload: {
                    propertyList: response.data,
                    loading: false,
                    status: "success",
                    text: "Add PROPERTY data successfully.",
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PROPERTY_ERROR,
                  payload: {
                    text: "Error occured during getting PROPERTY data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PROPERTY_ERROR,
              payload: {
                text: "Error occured during adding PROPERTY data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      } else if (state === "edit") {
        axios
          .put(`${server_url}/property`, property)
          .then((response) => {
            axios
              .get(`${server_url}/property`)
              .then((response) => {
                dispatch({
                  type: PROPERTY_GET_ALL_SUCCESS,
                  payload: {
                    propertyList: response.data,
                    loading: false,
                    status: "success",
                    text: "Update PROPERTY data successfully.",
                  },
                });
                resolve();
              })
              .catch((e) => {
                dispatch({
                  type: PROPERTY_ERROR,
                  payload: {
                    text: "Error occured during getting PROPERTY data.",
                    status: "error",
                    loading: false,
                  },
                });
                resolve();
              });
          })
          .catch((e) => {
            dispatch({
              type: PROPERTY_ERROR,
              payload: {
                text: "Error occured during updating PROPERTY data.",
                status: "error",
                loading: false,
              },
            });
            resolve();
          });
      }
    });
  };
};

export const propertyClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PROPERTY_CLEAR_SNACKBAR,
    });
  };
};
