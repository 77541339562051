import React, { useEffect, useRef, useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";

import {
  Box,
  IconButton,
  Avatar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import { TextField, Paper, InputAdornment } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import "./ChatAssistant.css";

import voiceAssistantImg from "assets/app/voice-assistant.png";
import keyboardImg from "assets/app/keyboard.png";

import { useVoiceAssistantState } from "context/VoiceAssistantContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MOBILE_MAX_WIDTH_768,
  voiceAssistantPath,
  MOBILE_MAX_LISTED_RESULT,
} from "constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { getProperties, getPropertyData } from "selectors/propertySelectors";
import { getProjectData } from "selectors/projectSelector";
import { getUserProfileDetails } from "selectors/appSelector";

import {
  addNewProperty,
  propertyGetAll,
  setPropertyData,
} from "actions/propertyActions";
import DataGridTable from "components/Table/DataGrid";
import {
  checkMicrophonePermissions,
  fetchBuilderAiCommandQuestions,
  setBuilderAiCommandQuestionsInitialData,
} from "actions/appBuilderAiActions";
import {
  INITIAL_FLOW_STATE,
  PROJECT_STEPS_ENUM,
  PROPERTY_STEPS,
  PROPERTY_STEPS_ENUM,
} from "./VoiceAssistantCommandExecutionFlow";

const columns = (props) => [
  {
    field: "projectName",
    headerName: "Name",
    sortable: false,
    width: 150,
    resizable: false,
    renderCell: (rowData) => {
      return (
        <Tooltip title={rowData.formattedValue}>
          <p
            className="link-text"
            style={{ height: "100%", alignContent: "center" }}
            onClick={() => {
              const selectedProperty = props.propertiesList.find(
                (property) => property.propertyId === rowData.row.propertyId
              );

              props.setPropertyData(selectedProperty);
            }}
          >
            {rowData.row.propertyName}
          </p>
        </Tooltip>
      );
    },
  },
  {
    field: "property",
    headerName: "Address",
    resizable: false,
    width: 200,
    renderCell: (rowData) => {
      return (
        <p style={{ height: "100%", alignContent: "center" }}>
          {rowData.row.address}
        </p>
      );
    },
  },
];

let chatMessagesHistory = [
  {
    sender: "bot",
    text: "How may I be of help to you?",
    timestamp: Date.now(),
    messageType: "text",
  },
];

const showArrowBack = false;

const VoiceAssistantLayout = () => {
  const chatBodyRef = useRef(null);

  const {
    VoiceAssistantState,
    setVoiceAssistantState,
    voiceAssistantEnabled,
    voiceAssistantConfig,
    VoiceAssistantFlowState,
    setVoiceAssistantFlowState,
    voiceAssistantMicrophoneAccess,
  } = useVoiceAssistantState();

  const [isTyping, setIsTyping] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null);

  const [selectedTab, updateTab] = useState("overview");

  const MAX_AWAIT_TIME_ELAPSED_SECONDS = 5;
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  console.log("VoiceAssistantLayout");

  const dispatch = useDispatch();

  const { propertiesList, totalCount } = useSelector(getProperties);
  const appRoleDetails = useSelector(getUserProfileDetails);

  const propertyData = useSelector(getPropertyData);
  console.log("propertyData", propertyData);

  const projectData = useSelector(getProjectData);

  const handleUserTyping = () => {
    setIsTyping(true);
    const timeout = setTimeout(() => setIsTyping(false), 2000);
    return () => clearTimeout(timeout);
  };

  const handleInputSend = () => {
    if (inputMessage.trim()) {
      console.log("Message sent:", inputMessage);
      handleSend("user", inputMessage);

      setInputMessage("");
    }
  };

  const handleSend = async (
    sender,
    text,
    clickEnabled = false,
    messageType = "text",
    data = [],
    takeAction = true
  ) => {
    const regexContainsCreate = /create/gi;
    const regexContainsProject = /project/gi;
    const regexContainsProperty = /property/gi;

    if (!text.trim()) {
      return;
    }

    const foundCreateString = text.match(regexContainsCreate);
    const foundProjectString = text.match(regexContainsProject);
    const foundPropertyString = text.match(regexContainsProperty);

    chatMessagesHistory.push({
      sender,
      text,
      timestamp: Date.now(),
      clickEnabled,
      messageType,
      data,
    });

    setChatMessages([...chatMessagesHistory]);

    if (!takeAction) {
      await delay(1000);
      return;
    }

    console.log("VoiceAssistantFlowState", VoiceAssistantFlowState);

    if (
      VoiceAssistantFlowState.commandId &&
      !VoiceAssistantFlowState.satisfiedRequirements
    ) {
      const {
        currentStep: { stepName, stepCount, fieldName },
        nextStep,
      } = VoiceAssistantFlowState;

      if (sender === "user") {
        handleSend("bot", `${stepName} received: ${text}`);
        await delay(1000);

        if (nextStep?.question) {
          handleSend(
            "bot",
            `Provide ${nextStep.question} for the ${VoiceAssistantFlowState.entityName}:`
          );
          await delay(1000);
        }

        const isSameCurrentCountAndQuestionIndex =
          stepCount > voiceAssistantConfig.questions.length;

        const satisfiedRequirements =
          stepCount >= voiceAssistantConfig.questions.length ||
          nextStep === null;

        console.log(
          "isSameCurrentCountAndQuestionIndex:satisfiedRequirements",
          isSameCurrentCountAndQuestionIndex,
          satisfiedRequirements
        );

        const fieldToCapture = {};

        fieldToCapture[fieldName] = text;

        setVoiceAssistantFlowState({
          ...VoiceAssistantFlowState,
          currentStep: {
            stepName: nextStep?.question,
            stepCount: stepCount + 1,
            fieldName: nextStep?.attributeName,
          },
          satisfiedRequirements,
          nextStep: !isSameCurrentCountAndQuestionIndex
            ? voiceAssistantConfig.questions[stepCount + 1]
            : null,
          isWaitingForInput: !isSameCurrentCountAndQuestionIndex,
          dataCollected: {
            ...VoiceAssistantFlowState.dataCollected,
            ...fieldToCapture,
          },
        });

        return;
      }
    }

    if (
      !VoiceAssistantFlowState.commandId &&
      sender === "user" &&
      foundCreateString?.length > 0 &&
      (foundProjectString?.length > 0 || foundPropertyString?.length > 0)
    ) {
      let currentCommand = {};
      let currentFlow = {};
      let entityName = "";

      if (foundPropertyString?.length > 0) {
        currentCommand = voiceAssistantConfig?.commands.find(
          (command) => command.commandName.toLowerCase() === "create property"
        );

        entityName = "property";
        currentFlow.name = PROPERTY_STEPS_ENUM.VALIDATE_PROPERTY;
      }

      if (foundProjectString?.length > 0) {
        currentCommand = voiceAssistantConfig?.commands.find(
          (command) => command.commandName.toLowerCase() === "create project"
        );

        entityName = "project";
        currentFlow.name = PROJECT_STEPS_ENUM.VALIDATE_PROJECT;
      }

      console.log("currentCommand", currentCommand);

      currentFlow.steps = PROPERTY_STEPS[currentFlow.name];

      const name = currentCommand.commandName.toLowerCase();

      let dependencyContext = {
        customer: currentCommand.customerDependency,
        property: currentCommand.propertyDependency,
        project: currentCommand.projectDependency,
        job: currentCommand.jobDependency,
      };

      setVoiceAssistantFlowState({
        commandId: currentCommand.commandId,
        command: name,
        entityName,
        currentStep: {
          stepName: null,
          stepCount: 0,
          fieldName: null,
        },
        satisfiedRequirements: false,
        isWaitingForInput: false,
        requirements: [],
        dependencies: dependencyContext,
        nextStep: null,
        commandData: currentCommand,
      });

      handleSend("bot", `Command received: ${name}`);

      dispatch(fetchBuilderAiCommandQuestions(currentCommand.commandId));
    }
  };

  const keyPress = (e) => {
    handleUserTyping();

    if (e.keyCode === 13) {
      handleInputSend();
    }
  };

  const [message, setMessage] = useState("");

  let commands = [
    {
      command: "reset",
      callback: () => {
        setMessage(null);
        resetTranscript();
        handleVoiceCommand(false);
        dispatch(setBuilderAiCommandQuestionsInitialData());
        setVoiceAssistantFlowState(INITIAL_FLOW_STATE);
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });
      },
    },
    {
      command: "shut up",
      callback: () => setMessage("I wasn't talking."),
    },
    {
      command: "Stop",
      callback: () => {
        setMessage(null);
        resetTranscript();
        handleVoiceCommand(false);
        dispatch(setBuilderAiCommandQuestionsInitialData());
        setVoiceAssistantFlowState(INITIAL_FLOW_STATE);
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });
      },
    },
    {
      command: "Cancel",
      callback: () => {
        setMessage(null);
        resetTranscript();
        handleVoiceCommand(false);
        dispatch(setBuilderAiCommandQuestionsInitialData());
        setVoiceAssistantFlowState(INITIAL_FLOW_STATE);
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });
      },
    },
    {
      command: "Hello",
      callback: () => setMessage("Hi there!"),
    },
    {
      command: "Create Contact",
      callback: () => {
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });

        handleSend("bot", "Command received: Create Contact");
        setMessage("Creating New Customer");
        // dependencies
        // check if customer is present in the system
      },
    },
    {
      command: "Create job",
      callback: () => {
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });

        handleSend("bot", "Command received: Create job");
        // dependencies
        // check if property is present in the system
        // check if project is present in the system
        checkDependencies("Create job", { property: true, project: true });
      },
    },
    {
      command: "check if customer is present in the system",
      callback: () => {
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });

        setMessage("Checking in the system");
        speak("Checking in the system");
      },
    },
  ];

  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [typewriterText, setTypewriterText] = useState("");

  const [lastCalled, setLastCalled] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const intervalRef = useRef(null);

  for (const command of voiceAssistantConfig?.commands) {
    const name = command.commandName.toLowerCase();

    let entityName = "";

    if (name.includes("property")) {
      entityName = "property";
    }

    if (name.includes("project")) {
      entityName = "project";
    }

    let dependencyContext = {
      customer: command.customerDependency,
      property: command.propertyDependency,
      project: command.projectDependency,
      job: command.jobDependency,
    };

    commands.push({
      command: name,
      callback: () => {
        handleVoiceCommand(false);
        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });

        setVoiceAssistantFlowState({
          commandId: command.commandId,
          command: name,
          entityName,
          currentStep: {
            stepName: null,
            stepCount: 0,
            fieldName: null,
          },
          satisfiedRequirements: false,
          isWaitingForInput: false,
          requirements: [],
          dependencies: dependencyContext,
          nextStep: null,
          commandData: command,
        });

        setTypewriterText("");
        resetTranscript();

        handleSend("user", finalTranscript);

        handleSend("bot", `Command received: ${name}`);

        dispatch(fetchBuilderAiCommandQuestions(command.commandId));
      },
    });
  }

  const {
    finalTranscript,
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });

  const handleVoiceCommand = (startListening) => {
    if (startListening) {
      SpeechRecognition.startListening({
        language: "en-US",
        continuous: true,
      });
    } else {
      SpeechRecognition.stopListening();
    }
  };

  // typewriterEffect()
  useEffect(() => {
    let currentIndex = -1;
    setTypewriterText("");

    const typewriterEffect = () => {
      handleUserTyping();
      console.log("typewriterEffect", finalTranscript);

      if (currentIndex < finalTranscript.length) {
        setTypewriterText((prev) => {
          setLastCalled(Date.now());

          console.log("prev", prev);
          console.log(
            "finalTranscript[currentIndex]",
            finalTranscript[currentIndex]
          );

          if (
            typeof prev !== "undefined" &&
            typeof finalTranscript[currentIndex] !== "undefined"
          ) {
            return prev + finalTranscript[currentIndex];
          }

          if (typeof prev !== "undefined") {
            return prev;
          }

          if (typeof finalTranscript[currentIndex] !== "undefined") {
            return finalTranscript[currentIndex];
          }
        });

        currentIndex++;
        setTimeout(typewriterEffect, 70);
      }
    };

    typewriterEffect();
  }, [finalTranscript]);

  // voice recognition
  useEffect(() => {
    if (lastCalled !== null) {
      // Clear any existing interval
      if (intervalRef.current) clearInterval(intervalRef.current);

      // Start a new interval to update elapsed time
      intervalRef.current = setInterval(() => {
        handleUserTyping();

        const timeElapsed = Math.floor((Date.now() - lastCalled) / 1000);

        setTimeElapsed(timeElapsed);

        if (timeElapsed >= MAX_AWAIT_TIME_ELAPSED_SECONDS) {
          handleVoiceCommand(false);
          setVoiceAssistantState({
            ...VoiceAssistantState,
            isListening: false,
          });

          console.log("timeElapsed:finalTranscript", finalTranscript);

          if (
            VoiceAssistantFlowState.command !==
            finalTranscript.toLocaleLowerCase()
          ) {
            handleSend("user", finalTranscript);
          }

          setTypewriterText("");
          resetTranscript();

          setTimeout(() => {
            handleVoiceCommand(false);
            clearInterval(intervalRef.current);
          }, 500);
        }
      }, 1000);

      // Cleanup interval on unmount
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [lastCalled]);

  // VoiceAssistantFlowState
  useEffect(() => {
    console.log(
      "VoiceAssistantLayout:useEffect:VoiceAssistantFlowState",
      VoiceAssistantFlowState
    );

    console.log(
      "VoiceAssistantLayout:useEffect:voiceAssistantConfig",
      voiceAssistantConfig.questions
    );

    if (VoiceAssistantFlowState.satisfiedRequirements) {
      setVoiceAssistantFlowState(INITIAL_FLOW_STATE);
      dispatch(setBuilderAiCommandQuestionsInitialData());

      dispatch(
        addNewProperty(
          { ...VoiceAssistantFlowState.dataCollected, companyId: appRoleDetails.companyId },
          { filesState: null, fileLabelsState: null },
          (payload) => {
            console.log(
              `${VoiceAssistantFlowState?.entityName} Added Successfully`,
              payload
            );
            handleSend(
              "bot",
              `${VoiceAssistantFlowState?.entityName} added successfully`
            );
          },
          navigate
        )
      );

      return;
    }

    if (
      VoiceAssistantFlowState.command &&
      VoiceAssistantFlowState.commandId &&
      // VoiceAssistantFlowState?.currentStep?.stepName &&
      !VoiceAssistantFlowState.isWaitingForInput &&
      voiceAssistantConfig.questions.length > 0
    ) {
      checkDependencies(
        VoiceAssistantFlowState.command,
        VoiceAssistantFlowState.commandId,
        VoiceAssistantFlowState.dependencies
      );

      return;
    }

    if (
      !voiceAssistantConfig.isFetchingQuestions &&
      VoiceAssistantFlowState.commandId &&
      voiceAssistantConfig.questions.length === 0
    ) {
      handleSend(
        "bot",
        "No questions available for this command",
        false,
        "text",
        [],
        false
      );

      dispatch(setBuilderAiCommandQuestionsInitialData());
      setVoiceAssistantFlowState(INITIAL_FLOW_STATE);

      return;
    }
  }, [
    VoiceAssistantFlowState,
    dispatch,
    navigate,
    setVoiceAssistantFlowState,
    voiceAssistantConfig.isFetchingQuestions,
    voiceAssistantConfig.questions,
    voiceAssistantConfig.questions.length,
  ]);

  // voiceAssistantMicrophoneAccess
  useEffect(() => {
    console.log(
      "VoiceAssistantLayout:useEffect:voiceAssistantMicrophoneAccess",
      voiceAssistantMicrophoneAccess
    );

    // if (!voiceAssistantMicrophoneAccess) {
    //   dispatch(checkMicrophonePermissions());
    // }

    console.log("VoiceAssistantState:", VoiceAssistantState);

    if (VoiceAssistantState.isListening) {
      handleVoiceCommand(true);
      handleUserTyping();
      handleKeyboardClick(false);
      setShowInput(false);
    } else {
      handleVoiceCommand(false);
    }
  }, [
    VoiceAssistantState,
    VoiceAssistantState.isListening,
    dispatch,
    voiceAssistantMicrophoneAccess,
  ]);

  // useEffect(() => {
  //   if (
  //     !voiceAssistantEnabled ||
  //     (!isMobile && pathname.includes(voiceAssistantPath))
  //   ) {
  //     navigate(-1);
  //   }
  // }, [isMobile, navigate, pathname, voiceAssistantEnabled]);

  const [isAutoScroll, setIsAutoScroll] = useState(true);

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
      setIsAutoScroll(scrollTop + clientHeight >= scrollHeight - 10);
    }
  };

  // chatMessages - isAutoScroll
  useEffect(() => {
    if (isAutoScroll && chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // showInput - inputRef for keyboard
  useEffect(() => {
    console.log("showInput", showInput);
    if (showInput) {
      inputRef.current?.focus();
      handleVoiceCommand(false);
      setVoiceAssistantState({
        ...VoiceAssistantState,
        isListening: false,
      });
    }
  }, [showInput]);

  // onDestroy - setVoiceAssistantState:false
  useEffect(() => {
    return () => {
      handleVoiceCommand(false);

      setVoiceAssistantFlowState(INITIAL_FLOW_STATE);
      dispatch(setBuilderAiCommandQuestionsInitialData());

      setVoiceAssistantState({
        ...VoiceAssistantState,
        isListening: false,
      });
    };
  }, []);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const speak = (text) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = text;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(msg);
  };

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const checkDependencies = async (
    command,
    commandId = null,
    dependencies = {}
  ) => {
    console.log("Checking dependencies for command:", command);

    if (command.toLowerCase() === "create property") {
      await delay(1000);
      handleSend("bot", `Provide more details to create a property:`);
      await delay(1000);

      let currentFlow = {};
      currentFlow.name = PROPERTY_STEPS_ENUM.PROPERTY_NAME;
      currentFlow.steps = PROPERTY_STEPS[currentFlow.name];

      const [firstQuestion, secondQuestion] = voiceAssistantConfig.questions;

      handleSend(
        "bot",
        `Provide ${firstQuestion?.question} for the ${VoiceAssistantFlowState.entityName}:`
      );
      await delay(1000);

      setVoiceAssistantFlowState({
        ...VoiceAssistantFlowState,
        commandId,
        command,
        entityName: "property", // TODO update based on endpoint matches
        currentStep: {
          stepName: firstQuestion?.question,
          stepCount: 1,
          fieldName: firstQuestion?.attributeName,
        },
        satisfiedRequirements: secondQuestion?.question ? false : true,
        isWaitingForInput: true,
        requirements: [],
        dependencies: dependencies,
        nextStep: secondQuestion,
      });

      return;
    }

    if (dependencies?.project) {
      console.log("propertyData", !propertyData, !propertyData?.propertyId);
      if (!propertyData?.propertyId) {
        handleSend("bot", "Property is not present in the system");
        await delay(1000);
        handleSend("bot", "Select a property first:");
        await delay(1000);

        if (!propertiesList.length) {
          //call list project api
          dispatch(
            propertyGetAll({
              pageNumber: 1,
              pageSize: 5,
            })
          );
        } else {
          // list the properties
          handleSend("bot", "propertiesList", false, "table", propertiesList);
        }
      } else {
        handleSend("bot", `Selected property: ${propertyData?.propertyName}`);
        await delay(1000);
        handleSend("bot", `Provide more details to create a project:`);
        await delay(1000);

        const [firstQuestion, secondQuestion] = voiceAssistantConfig.questions;

        handleSend(
          "bot",
          `Provide ${firstQuestion?.question} for the ${VoiceAssistantFlowState.entityName}:`
        );
        await delay(1000);

        setVoiceAssistantFlowState({
          ...VoiceAssistantFlowState,
          commandId,
          command,
          entityName: "project", // TODO update based on endpoint matches
          currentStep: {
            stepName: firstQuestion?.question,
            stepCount: 1,
            fieldName: firstQuestion?.attributeName,
          },
          satisfiedRequirements: secondQuestion?.question ? false : true,
          isWaitingForInput: true,
          requirements: [],
          dependencies: dependencies,
          nextStep: secondQuestion,
        });

        return;
      }
    }
  };

  const handleClickOnMessage = async (message) => {
    console.log("Message clicked:", message);

    // TODO: create constants for commands execution
    if (message?.type === VoiceAssistantFlowState.command) {
      await delay(1000);
      handleSend("user", `selected: ${message.text}`);
      await delay(1000);
      await delay(1000);

      checkDependencies(message?.type, VoiceAssistantFlowState.dependencies);
    }
  };

  const handleKeyboardClick = () => {
    setShowInput((prev) => !prev);
  };

  return (
    <div>
      {showArrowBack && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: 2,
          }}
        >
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      {/* Main Content */}
      {chatMessages?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* Profile Avatar */}
          <Avatar
            src={voiceAssistantImg}
            alt="Helper"
            sx={{ width: 80, height: 80, mb: 2 }}
          />
          <Typography variant="h6" fontWeight="bold">
            How may I be of help to you?
          </Typography>

          {
            <>
              <Typography variant="subtitle2" sx={{ mt: 2, color: "gray" }}>
                QUICK SUGGESTIONS
              </Typography>

              {/* Quick Suggestion Buttons */}
              <Stack spacing={2} sx={{ mt: 2, width: "100%", maxWidth: 300 }}>
                <Button disabled variant="outlined" fullWidth>
                  Send an estimate
                </Button>
                <Button 
                onClick={async () => {
                  handleSend("user", "Search for a property");
                  await delay(1000);
                  handleSend("bot", "here is a list of the recent properties", false, "text", [], false);
                  await delay(500);
                  handleSend("bot", "properties", false, "table", propertiesList);
                }}
                variant="outlined" fullWidth>
                  Search for a property
                </Button>
                <Button 
                onClick={() => {
                  handleSend("user", "Create a New Build project");
                }}
                variant="outlined" fullWidth>
                  Create a New Build project
                </Button>
              </Stack>
            </>
          }
        </Box>
      )}

      {(chatMessages?.length > 0 || !isMobile) && (
        <div
          className="chat-container"
          key={`chat-container-${chatMessages.length}`}
        >
          {/* Chat Body */}
          <div
            className="chat-body"
            key={`chat-body-${chatMessages.length}`}
            ref={chatBodyRef}
            // style={{ overflowY: "auto", maxHeight: "700px" }}
            style={{ maxWidth: "400px" , maxHeight: "700px" }}
            onScroll={handleScroll}
          >
            {chatMessages.map((message, index) => (
              <>
                {message.messageType === "text" && (
                  <div
                    key={`chat-message-${index}`}
                    className={`chat-message ${
                      message.sender === "bot"
                        ? "chat-message-bot"
                        : "chat-message-user"
                    }`}
                    onClick={
                      message.clickEnabled
                        ? () => handleClickOnMessage(message)
                        : null
                    }
                  >
                    <Paper className="chat-bubble" key={`chat-bubble-${index}`}>
                      <span
                        className="chat-message-text"
                        key={`chat-message-text-${index}`}
                      >
                        {message.text}
                      </span>
                      <span
                        className="chat-message-timestamp"
                        key={`chat-message-timestamp-${index}`}
                      >
                        {new Date(message.timestamp).toLocaleString()}
                      </span>
                    </Paper>
                  </div>
                )}

                {message.messageType === "table" && (
                  <div key={`chat-message-${index}`} className="chat-message">
                    <DataGridTable
                      key={index}
                      // getRowHeight={() => "auto"}
                      getRowHeight={() => 50}
                      columns={columns({
                        navigate: navigate,
                        propertiesList: propertiesList,
                        setPropertyData,
                      })}
                      data={message.data}
                      defineRowId={(row) => {
                        return row.propertyId;
                      }}
                      pagination={totalCount > 10}
                      page
                      totalCount={totalCount}
                      onDeleteSelection={() => {}}
                      showToolbar={false}
                      onRowClick={(row) => {
                        console.log("row clicked", row);
                        const selectedProperty = propertiesList.find(
                          (property) => property.propertyId === row.id
                        );
                        console.log(selectedProperty);
                        dispatch(setPropertyData(selectedProperty));

                        handleClickOnMessage({
                          text: selectedProperty.propertyName,
                          type: VoiceAssistantFlowState.command,
                        });
                      }}
                      onPaginationChange={() => {}}
                      isLoading={() => {}}
                      onSelect={(selected) => {
                        console.log("selected clicked", selected);
                      }}
                      selectedRows={() => {}}
                    />
                  </div>
                )}
              </>
            ))}
          </div>

          {/* Typing Indicator */}
          <div
            className={`chat-typing-indicator ${isMobile ? "mobile" : ""} ${
              isTyping ? "visible" : ""
            }`}
            key="typing-indicator"
          >
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
            <div className="typing-dot"></div>
          </div>
        </div>
      )}

      {/* Floating Keyboard Button */}
      {isMobile && (
        <IconButton
          className="floating-keyboard-button"
          onClick={handleKeyboardClick}
        >
          <img
            src={keyboardImg}
            alt="Keyboard Input"
            className="keyboard-icon"
          />
        </IconButton>
      )}

      {/* Input Section */}
      {(!isMobile || showInput) && (
        <div className={`chat-input ${isMobile ? "mobile": ""}`}>
          <TextField
            variant="outlined"
            placeholder="Type your message here"
            className={`chat-input-field ${isMobile ? "mobile" : ""}`}
            value={inputMessage}
            fullWidth={!isMobile}
            onKeyDown={keyPress}
            onChange={(e) => setInputMessage(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleInputSend}>
                    <SendIcon className="chat-send-icon" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <div >
              {VoiceAssistantState.isListening && <ListeningAnimation />}
            </div> */}
          {!isMobile && (
            <IconButton
              className="chat-voice-button"
              onClick={() => {
                setVoiceAssistantState({
                  ...VoiceAssistantState,
                  isListening: !VoiceAssistantState.isListening,
                });
              }}
            >
              <img
                src={voiceAssistantImg}
                alt="Voice Assistant"
                className="chat-voice-icon"
              />
            </IconButton>
          )}
        </div>
      )}

      {chatMessages?.length === 0 && listening && (
        <>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {timeElapsed}: {typewriterText}
          </Typography>
        </>
      )}

      {/* Listening Icon at the Bottom */}
      {/* {!listening && chatMessages?.length === 0 && ( */}
      {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 4,
          }}
        > */}

      {/* <Typography variant="caption" sx={{ color: 'gray' }}>
            {listening ? displayedText : 'Click to start listening'}
          </Typography>

          {listening && (
            <>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {typewriterText}
              </Typography>
            </>
          )}
        </Box> */}
      {/* )} */}

      {/* {chatMessages?.length === 0 && listening && message === '' && (
      )} */}
    </div>
  );
};

export default VoiceAssistantLayout;
