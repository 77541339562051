import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.marketPlaceCompanies;

export const getMarketPlaceCompanies = createSelector(
  selectState,
  (companies) => ({
    companies: get(companies, "companiesList", []),
    totalCount: get(companies, "totalCount", 1),
    loading: get(companies, "loading", false),
    showSnackbar: get(companies, "showSnackbar", false),
    status: get(companies, "status", "success"),
    statusText: get(companies, "text", ""),
  })
);

// export const marketPlaceLoadingSelector = createSelector(selectState, (app) =>
//   get(app, "loading", false)
// );

// export const marketPlaceSnackBarSelector = createSelector(selectState, (app) =>
//   get(app, "showSnackbar", false)
// );
