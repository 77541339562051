import React from "react";
import { Box, Typography, Card } from "@mui/material";

export const MySubscriptions = ({ userData }) => {
	const disabledProps = {
		disabled: true,
		style: { backgroundColor: "gray" },
	};

	return (
		<Box className="space-y-6">
			<div className="d-flex mt-5">
				<Typography className="body1 mb-2">Current Plan - </Typography>
				<Typography className="h5 mb-4 ms-3" color="primary">
					{userData.subscription}
				</Typography>
			</div>
			<Typography className="mb-2">
				Next billing date: {userData.nextBilling}
			</Typography>
			<button
				variant="outlined"
				className="primaryButton disabled"
				color="primary"
				{...disabledProps}
			>
				Upgrade Plan
			</button>

			<div className="col-12 mt-3 p-2">
				<Typography className="h4 mb-4">Available Plans</Typography>
				<Box className="pe-4">
					{["Basic", "Pro", "Enterprise"].map((plan) => {
						const buttonProps =
							userData.subscription === `${plan} Plan` ? {} : disabledProps;

						return (
							<Card
								key={plan}
								className="p-4 border border-gray-200 m-2 col-12"
							>
								<Typography className="h4">{plan}</Typography>
								<Typography variant="body2" className="mb-2">
									{plan === "Basic"
										? "Free"
										: plan === "Pro"
										? "$9.99/mo"
										: "Custom pricing"}
								</Typography>
								<button
									className={
										userData.subscription === `${plan} Plan`
											? "secondaryButton"
											: "primaryButton"
									}
									{...buttonProps}
								>
									{userData.subscription === `${plan} Plan`
										? "Current Plan"
										: "Select Plan"}
								</button>
							</Card>
						);
					})}
				</Box>
			</div>
		</Box>
	);
};

export default MySubscriptions;
