import axios from "axios";
import {
  PROJECT_GET_WARRANTIES_LOADING,
  PROJECT_GET_WARRANTIES,
  PROJECT_WARRANTY_STATUS_ERROR,
  PROJECT_ADD_WARRANTY,
  PROJECT_ADD_WARRANTY_ERROR,
  PROJECT_ADD_WARRANTY_SUCCESS,
  PROJECT_DELETE_WARRANTY_SUCCESS,
  PROJECT_WARRANTY_CLEAR_SNACKBAR,
  PROJECT_GET_ADS,
  PROJECT_ADS_STATUS_ERROR,
  PROJECT_GET_ADS_LOADING,
  PROJECT_ADD_ADS,
  PROJECT_ADD_ADS_SUCCESS,
  PROJECT_ADD_ADS_ERROR,
  PROJECT_DELETE_ADS_SUCCESS,
  PROJECT_ADS_CLEAR_SNACKBAR,
} from "../constants/types";
import queryString from "query-string";
import { omit } from "lodash";

// import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchAds = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_ADS_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page + 1,
        PageSize: params.pageSize,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/Advertise?${queryParams}`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: PROJECT_GET_ADS,
            payload: {
              ads: response.data.items,
              totalCount: response.data.totalCount,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADS_STATUS_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all warranties.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateAdvertise = ({ formData, onSuccess, payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_ADD_ADS,
        payload: {
          loading: true,
        },
      });

      //formData.jobId ? "PUT" : "POST",
      let data = {
        ...formData,
        advertiseImage: formData.logo1
          ? formData.logo1
          : formData.advertiseImage,
      };
      data = omit(data, "logo1");
      request({
        method: formData.advertiseId ? "PUT" : "POST",
        url: `${server_url}/Advertise${
          formData.advertiseId ? `/${formData.advertiseId}` : ""
        }`,
        body: {
          ...data,
        },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: PROJECT_ADD_ADS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "Ads Added Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADD_ADS_ERROR,
            payload: {
              text: "Error occured during adding Ads data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const adsDelete = ({
  advertiseId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the adsDelete Action", advertiseId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: PROJECT_GET_ADS_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/Advertise/${advertiseId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchAds({ ...pageModel }));
          onSuccess();

          dispatch({
            type: PROJECT_DELETE_ADS_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "Ads Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: PROJECT_ADS_STATUS_ERROR,
            payload: {
              text: "Error occured during deleteing ads data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const adsClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: PROJECT_ADS_CLEAR_SNACKBAR,
    });
  };
};
