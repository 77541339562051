import {
	CancelOutlined,
	CheckOutlined,
	Delete,
	Edit,
} from "@mui/icons-material";
import { IconButton, MenuItem, TextField, Typography } from "@mui/material";
import {
	deleteProjUserRole,
	fetchProjUserRoles,
	updateProjUserRoles,
} from "actions/userActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getProjectRolesList,
	getProjectUserRolesList,
} from "selectors/userSelector";

export const ProjectTeamList = ({ projectRefId, title = null }) => {
	const dispatch = useDispatch();

	const { projectUsersRolesList } = useSelector(getProjectUserRolesList);
	const { projectRolesList } = useSelector(getProjectRolesList);

	const [selectedUser, setSelectedUser] = React.useState(null);

	useEffect(() => {
		dispatch(fetchProjUserRoles(projectRefId));
	}, [dispatch, projectRefId]);

	return (
		<div>
			<div className="assigned-users-list mt-4">
				<Typography className="h4">
					{title || "Assigned Users For Project"}
				</Typography>
				<table className="mt-3">
					<thead>
						<tr>
							<th>User Name</th>
							<th>Email</th>
							<th>Contact</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{projectUsersRolesList.length > 0 &&
							projectUsersRolesList.map((userRole) => {
								const user = userRole.user;
								return (
									<tr>
										<td>{`${user.firstName} ${user.lastName}`}</td>
										<td>{userRole.user.email}</td>
										<td>{userRole.user.mobileNumber}</td>
										<td>
											<div>
												{selectedUser &&
												selectedUser.purId === userRole.purId ? (
													<div className="d-flex">
														<TextField
															className={`form-field ${
																selectedUser ? "" : "disabled"
															}`}
															select
															style={{ width: "70%" }}
															value={selectedUser.projrole.projRoleId}
															disabled={!selectedUser}
															onChange={(event) => {
																setSelectedUser({
																	...selectedUser,
																	projrole: {
																		...selectedUser.projrole,
																		projRoleId: event.target.value,
																	},
																});
															}}
														>
															<MenuItem value={"Select Role"} disabled>
																Select Role
															</MenuItem>
															{projectRolesList.map((role) => {
																return (
																	<MenuItem value={role.projRoleId}>
																		<Typography>{role.projRoleName}</Typography>
																	</MenuItem>
																);
															})}
														</TextField>

														<IconButton
															onClick={() => {
																dispatch(
																	updateProjUserRoles({
																		purId: selectedUser.purId,
																		projectId: projectRefId,
																		userId: selectedUser.user.userId,
																		projRoleId:
																			selectedUser.projrole.projRoleId,
																	})
																);
																setSelectedUser(null);
															}}
														>
															<CheckOutlined style={{ fontSize: "1rem" }} />
														</IconButton>
														<IconButton onClick={() => setSelectedUser(null)}>
															<CancelOutlined style={{ fontSize: "1rem" }} />
														</IconButton>
													</div>
												) : (
													<>
														{userRole.projrole.projRoleName}

														<span>
															<IconButton
																onClick={() => setSelectedUser(userRole)}
															>
																<Edit style={{ fontSize: "1rem" }} />
															</IconButton>
														</span>
														<span>
															<IconButton
																onClick={() => {
																	dispatch(
																		deleteProjUserRole(
																			userRole.purId,
																			projectRefId
																		)
																	);
																}}
															>
																<Delete style={{ fontSize: "1rem" }} />
															</IconButton>
														</span>
													</>
												)}
											</div>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ProjectTeamList;
