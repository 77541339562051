import { createSelector } from "reselect";
import get from "lodash/get";
import { getUsersList } from "./userSelector";

const selectState = (state) => state.task;

export const getTasks = createSelector(selectState, (task) => ({
	tasksList: get(task, "taskList", []),
	totalCount: get(task, "totalCount", 0),
}));

export const getTaskData = createSelector(selectState, (task) =>
	get(task, "taskData", [])
);

export const getTaskdynamicOptions = createSelector(
	[selectState, getUsersList],
	(task, usersList) => {
		return {
			taskStatus: get(task, "taskStatus", []).map(
				(status) => status.statusName
			),
			taskStatusId: get(task, "taskStatus", []).map((option) => ({
				key: option?.taskStatusId,
				text: option?.statusName,
			})),
			assignedTo: usersList.map((user) => ({
				key: user?.userName,
				text: user?.userName,
			})),
		};
	}
);
