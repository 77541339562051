import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";

export const getCompaniesAction = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_COMPANIES,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/company?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_COMPANIES_SUCCESS,
						payload: {
							companiesList: response.data.items,
							totalCount: response.data.totalCount,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_COMPANIES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const getCompanyByIdAction = (companyId, params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.APP_LOADING_START,
			});
			dispatch({
				type: ActionConstants.GET_COMPANY_DETAILS_BY_ID,
				payload: {
					loading: true,
				},
			});

			request({
				method: "GET",
				url: `${server_url}/company/${companyId}${query ? `?${query}` : ""}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_COMPANY_DETAILS_BY_ID_SUCCESS,
						payload: response.data,
					});
					dispatch({
						type: ActionConstants.APP_LOADING_STOP,
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.GET_COMPANY_DETAILS_BY_ID_ERROR,
						payload: {
							status: "error",
							message:
								"Unable to fetch company details at the moment. Please try again later.",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.APP_LOADING_STOP,
					});
					resolve();
				});
		});
	};
};

export const getConnectionsAction = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_CONNECTIONS,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/company?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_CONNECTIONS_SUCCESS,
						payload: {
							companiesList: response.data.items,
							totalCount: response.data.totalCount,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_CONNECTIONS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const addNewConnection = (
	payload,
	onSuccess = () => {},
	parentParams
) => {
	console.log("addNewConnection Payload : ", payload);
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.CONNECT_COMPANY,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/connection`,
				body: payload,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.CONNECT_COMPANY_SUCCESS,
						payload,
					});
					dispatch(getConnectionsAction(parentParams));
					onSuccess({
						type: "success",
						message: "Connection Request sent, user will me notified",
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.CONNECT_COMPANY_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					onSuccess({
						type: "error",
						message:
							"Error in sending Connection Request. Pleas try again some time",
					});

					resolve();
				});
		});
	};
};

export const updateConnectionStatus = (
	payload,
	connectionId,
	onSuccess = () => {},
	parentParams
) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.CONNECT_COMPANY,
				payload: {
					loading: true,
				},
			});
			request({
				method: "PUT",
				url: `${server_url}/connection/${connectionId}`,
				body: payload,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.CONNECT_COMPANY_SUCCESS,
					});
					dispatch(getConnectionsAction(parentParams));
					onSuccess({
						type: "success",
						message:
							payload.connectionStatusId === 6
								? "Connection Added successfully"
								: "Connection Rejected, User will be intimated",
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.CONNECT_COMPANY_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					onSuccess({
						type: "error",
						message:
							"Error in updating Connection Status. Please try again some time",
					});
					resolve();
				});
		});
	};
};

export const fetchSuppliersList = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_CONNECTED_SUPPLIERS,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/company?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_CONNECTED_SUPPLIERS_SUCCESS,
						payload: {
							companiesList: response.data.items,
							totalCount: response.data.totalCount,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_CONNECTED_SUPPLIERS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchMarketSectorsAction = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_MARKET_SECTORS,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/marketSectors?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_MARKET_SECTORS_SUCCESS,
						payload: {
							marketSectors: response.data,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_MARKET_SECTORS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchCompanyTypesAction = (params) => {
	return (dispatch, _) => {
		const query = queryString.stringify(params);
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_COMPANY_TYPES,
				payload: {
					loading: true,
				},
			});
			request({ method: "GET", url: `${server_url}/companyTypes?${query}` })
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_COMPANY_TYPES_SUCCESS,
						payload: {
							companyTypesList: response.data,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_COMPANY_TYPES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
