import React, { useContext, useEffect, useState } from "react";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Hidden,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPastProperties, getProperties } from "selectors/propertySelectors";
import {
	getPropertiesFor2Years,
	propertyDelete,
	propertyGetAll,
} from "actions/propertyActions";
import get from "lodash/get";
import HiddenOn from "components/Wrappers/HiddenOn";
import {
	CheckCircleOutline,
	Close,
	Delete,
	DeleteOutline,
	EditOutlined,
	ExpandMore,
	FilterList,
	KeyboardArrowDown,
	KeyboardArrowUp,
	RadioButtonUnchecked,
} from "@mui/icons-material";
import "./properties.scss";
import "styles/styleVariables.scss";
import { hasAdminRole, isAssignee, isCreator } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import PopoverMenu from "components/Popover/Popover";
import {
	getListOfPastYears,
	getMomentDate,
	projectStatusOptions,
} from "core/utils/appUtils";
import BurgerMenu from "components/Menu/BurgerMenu";

export const PropertiesFolderView = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const appRoleDetails = useContext(AppRoleContext);

	const { propertiesList, totalCount } = useSelector(getProperties);
	const pastProperties = useSelector(getPastProperties);
	const [pageModel, setPageModel] = useState({ pageNumber: 1, pageSize: 15 });
	const [addNewProperty, setAddNewProperty] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [pastPropertiesPageModel, setPastRecordsPageModel] = useState(
		getListOfPastYears(2, false).reduce((acc, year) => {
			return {
				...acc,
				[year]: { pageNumber: 1, pageSize: 15 },
			};
		}, {})
	);
	const [selectedRecord, setSelectedRecord] = useState({});

	const [filterState, setFilterState] = useState({
		status: [],
		year: [],
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		dispatch(
			propertyGetAll({
				pageNumber: 1,
				pageSize: pageModel.pageSize * pageModel.pageNumber,
				Year: new Date().getFullYear(),
			})
		);
	}, [dispatch, pageModel]);

	useEffect(() => {
		if (pastProperties.length === 0) {
			dispatch(getPropertiesFor2Years());
		}
	}, [dispatch, pastProperties]);

	const badgeContent = filterState.status.length + filterState.year.length;

	return (
		<>
			<div className="mt-4 d-flex justify-content-between">
				<Typography className="h3 ms-3">Properties </Typography>

				{/* // removed Class page-footer fixed-footer */}
				<HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
					<div className="align-content-center">
						<div className="d-flex justify-content-end ">
							<button
								className="primaryButton" //addProjectButton
								style={{ width: "auto" }}
								onClick={() => {
									setAddNewProperty(!addNewProperty);
									navigate("/property/addNew");
								}}
							>
								{"+ Add Property"}
							</button>
						</div>
					</div>
				</HiddenOn>
			</div>
			{/* <BreadCrumb /> */}
			<div>
				<div className="d-flex justify-content-between">
					<Typography variant="body1" className="mt-4 ms-3 h5">
						Recent Properties
					</Typography>
					<div
						className="pointer d-flex mt-4 me-3"
						onClick={handleClick}
						style={{ fontSize: "16px", fontWeight: "700", color: "#345DAE" }}
					>
						<Typography
							variant="body1"
							className="h6 me-2"
							style={{
								fontSize: "16px",
								fontFamily: "Barlow",
								fontWeight: "700",
							}}
						>
							Filters
						</Typography>
						<Badge
							style={{ fontSize: "16px", marginTop: "0px" }}
							badgeContent={badgeContent > 0 ? badgeContent : ""}
							color="primary"
							invisible={badgeContent === 0}
						>
							<FilterList style={{ fontSize: "1.5rem" }} />
						</Badge>
					</div>
					<PopoverMenu
						id={Boolean(anchorEl) ? "filter-menu-popover" : undefined}
						open={Boolean(anchorEl)}
						handleClose={handleClose}
						anchorEl={anchorEl}
						contentWidth={"15rem"}
						content={
							<FilterMenu
								pageModel={pageModel}
								handleClose={handleClose}
								filterState={filterState}
								setFilterState={setFilterState}
							/>
						}
					/>
				</div>
				<HiddenOn isHidden={totalCount === 0 || propertiesList.length === 0}>
					<div
						data-testid={"properties-select-div "}
						className="d-flex properties-list-container"
					>
						<FormGroup className="ms-3" aria-disabled>
							<FormControlLabel
								disabled
								disableTypography
								control={<Checkbox />}
								label="Select All"
							/>
						</FormGroup>
						<IconButton
							disabled={!selectedRecord.propertyId || true}
							onClick={() => {
								dispatch(
									propertyDelete(selectedRecord.propertyId, () => {
										dispatch(
											propertyGetAll({
												pageNumber: 1,
												pageSize: pageModel.pageSize * pageModel.pageNumber,
											})
										);
										dispatch(getPropertiesFor2Years());
									})
								);
								setSelectedRecord({});
							}}
						>
							<Delete
								style={{
									fontSize: "20px",
									color: selectedRecord.propertyId ? "black" : "lightgray",
								}}
							/>
						</IconButton>
						<IconButton
							disabled={!selectedRecord.propertyId || true}
							onClick={() =>
								navigate(`/property/edit/${selectedRecord.propertyId}`)
							}
						>
							<EditOutlined
								style={{
									fontSize: "20px",
									marginTop: "5px",
									color: selectedRecord.propertyId ? "black" : "lightgray",
								}}
							/>
						</IconButton>
					</div>
				</HiddenOn>
			</div>
			<Divider className="divider" />
			<div
				className=" properties-list-container"
				data-testid="dashboard-container"
				style={{ height: "calc(100vh - 288px)", overflow: "auto" }}
			>
				<Divider className="divider" />
				<HiddenOn
					isHidden={totalCount === 0 || propertiesList.length === 0}
					fallback={
						<Typography className="h5" align="center">
							No Properties Found
						</Typography>
					}
				>
					<div className="mt-4" data-testid="recent-properties-container">
						<div className="properties-list-row d-flex flex-wrap">
							<RenderFoldersList
								propertiesList={propertiesList}
								selectedRecord={selectedRecord}
								setSelectedRecord={setSelectedRecord}
								pageModel={pageModel}
							/>
						</div>
						<div className="text-center">
							<HiddenOn
								isHidden={
									totalCount === 0 ||
									propertiesList.length === 0 ||
									totalCount <= 15
								}
							>
								{pageModel.pageSize * pageModel.pageNumber < totalCount ? (
									<button
										className="secondaryButton"
										onClick={() => {
											setPageModel((prevState) => ({
												...prevState,
												pageNumber: pageModel.pageNumber + 1,
											}));
										}}
									>
										Load More <KeyboardArrowDown fontSize="1rem" />
									</button>
								) : (
									<button
										className="secondaryButton"
										onClick={() => {
											setPageModel((prevState) => ({
												...prevState,
												pageNumber: 1,
											}));
										}}
									>
										Show Less <KeyboardArrowUp fontSize="1rem" />
									</button>
								)}
							</HiddenOn>
						</div>
					</div>
				</HiddenOn>
				{/* New Accordion Login Need to added. */}
				{pastProperties.map((yearObject, index) => {
					const data = get(yearObject, "data", {});

					return yearObject.year && data.items?.length > 0 ? (
						<div
							className="mt-5"
							key={`pastProperty-${yearObject.year}-container-${index}`}
						>
							<Accordion
								key={`accordion-${yearObject.year}-container-${index}`}
								className={"properties-list-container"}
							>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
								>
									<Typography className="h4">{yearObject.year}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<div className="properties-list-row d-flex flex-wrap">
										<RenderFoldersList
											propertiesList={data.items || []}
											selectedRecord={selectedRecord}
											setSelectedRecord={setSelectedRecord}
											pageModel={pastPropertiesPageModel[yearObject.year]}
										/>
									</div>
									<div className="text-center">
										<HiddenOn
											isHidden={
												(data.totalCount && data.totalCount === 0) ||
												data.items?.length === 0 ||
												data.totalCount <= 15
											}
										>
											{pastPropertiesPageModel[yearObject.year].pageSize *
												pastPropertiesPageModel[yearObject.year].pageNumber <
											data.totalCount ? (
												<button
													className="secondaryButton"
													onClick={() => {
														setPastRecordsPageModel((prevState) => ({
															...prevState,
															[yearObject.year]: {
																...pastPropertiesPageModel[yearObject.year],
																pageNumber:
																	pastPropertiesPageModel[yearObject.year]
																		.pageNumber + 1,
															},
														}));
														dispatch(
															getPropertiesFor2Years(
																pastPropertiesPageModel[yearObject.year]
															)
														);
													}}
												>
													Load More <KeyboardArrowDown fontSize="1rem" />
												</button>
											) : (
												<button
													className="secondaryButton"
													onClick={() => {
														setPastRecordsPageModel((prevState) => ({
															...prevState,
															[yearObject.year]: {
																...pastPropertiesPageModel[yearObject.year],
																pageNumber: 1,
															},
														}));
														dispatch(
															getPropertiesFor2Years(
																pastPropertiesPageModel[yearObject.year]
															)
														);
													}}
												>
													Show Less <KeyboardArrowUp fontSize="1rem" />
												</button>
											)}
										</HiddenOn>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					) : null;
				})}
			</div>
		</>
	);
};

export const RenderFoldersList = ({
	propertiesList,
	setSelectedRecord = () => {},
	selectedRecord = {},
	pageModel,
}) => {
	return (
		<>
			{" "}
			{propertiesList.map((property, i) => {
				return (
					<>
						<div
							key={`property-folder-${property.propertyId}-index${i}`}
							className={`folder-icon ${
								isAssignee(property.accessType) ? "commercial" : "residential"
							} col-11`}
						>
							<RenderFolder
								property={property}
								setSelectedRecord={setSelectedRecord}
								selectedRecord={selectedRecord}
								pageModel={pageModel}
							/>
						</div>
					</>
				);
			})}
		</>
	);
};

export const RenderFolder = ({
	property,
	setSelectedRecord,
	selectedRecord,
	pageModel,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<div className="fol-header d-flex justify-content-between">
				<div style={{ margin: "0.5rem" }}>
					<Typography
						variant="boyd1"
						className="body1 pointer"
						style={{ fontWeight: 600, fontSize: "12px" }}
						data-testid="property-type"
						onClick={() =>
							navigate(`/properties/${property.propertyId}`, {
								state: {
									propertyName: property?.propertyName,
									propertyId: property.propertyId,
								},
							})
						}
					>
						{property.propertyType ||
							get(
								property,
								"projectHeaderList.projectHeaders[0].projectType",
								"New Construction"
							)}
					</Typography>
				</div>

				<div>
					<StatusIndicator
						status={isCreator(property.accessType) ? "active" : "scrutiny"}
						width={"18px"}
						height={"18px"}
						style={{
							marginLeft: "3rem",
							top: "1rem",
							position: "relative",
						}}
					/>
				</div>
			</div>
			<div className="fol-content">
				<Tooltip title={property.address}>
					<Typography
						variant="body1"
						className="p-1 property-name-fol pointer"
						style={{ fontSize: "16px", fontWeight: 700, color: "#0B2240" }}
						data-testid="property-name"
						onClick={() =>
							navigate(`/properties/${property.propertyId}`, {
								state: {
									propertyName: property?.propertyName,
									propertyId: property.propertyId,
								},
							})
						}
					>
						{property.address}
					</Typography>
				</Tooltip>
				{/* <Tooltip title={property.homeOwnerName || ""}> */}
				<Hidden smDown>
					<Typography
						variant="body1"
						className="m-2 mt-2 mb-0"
						style={{ fontSize: "14px", fontWeight: 500, color: "#0B2240" }}
					>
						{property.homeOwnerName || ""}
					</Typography>
				</Hidden>
				{/* </Tooltip> */}
				<Typography
					variant="body1"
					className="m-2 mt-md-0 mb-0 mt-xs-3"
					style={{ fontSize: "14px", fontWeight: 400, color: "#0B2240" }}
				>
					{`Latest End Date: `}{" "}
					<span style={{ fontWeight: 700 }}>
						{getMomentDate(property.plannedEndDate, "ll")}
					</span>
				</Typography>
			</div>

			<div className="fol-footer p-2 d-flex justify-content-end flex-direction-column">
				{/* <PercentageProgressBar percentage={40} label={null} /> */}
				<Typography
					variant="body1"
					className="m-2 mt-0 mb-0 body1"
					style={{ fontSize: "14px", bottom: "0.75rem", position: "relative" }}
				>
					<Hidden smDown>Total</Hidden>
					{` Projects: `}{" "}
					<strong>
						{get(property, "projectHeaderList.projectHeaders", []).length}
					</strong>
				</Typography>
				{
					<HiddenOn isHidden={isAssignee(property.accessType)}>
						<div className="footer-actions-button mt-sm-1">
							<BurgerMenu>
								<MenuItem
									onClick={() => {
										navigate(`/property/edit/${property.propertyId}`);
									}}
								>
									<EditOutlined />
									<Typography className="body1">Edit</Typography>
								</MenuItem>
								<MenuItem
									onClick={() => {
										dispatch(
											propertyDelete(property.propertyId, () => {
												dispatch(
													propertyGetAll({
														pageNumber: 1,
														pageSize: pageModel.pageSize * pageModel.pageNumber,
													})
												);
												dispatch(getPropertiesFor2Years());
											})
										);
									}}
								>
									<DeleteOutline />{" "}
									<Typography className="body1">Delete</Typography>
								</MenuItem>
							</BurgerMenu>
						</div>
						<FormGroup style={{ width: "2rem" }} className="mt-sm-1">
							<FormControlLabel
								disabled={
									true ||
									(selectedRecord.propertyId &&
										property?.propertyId !== selectedRecord?.propertyId)
								}
								control={
									<Checkbox
										icon={<RadioButtonUnchecked />}
										checkedIcon={<CheckCircleOutline />}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedRecord(property);
											} else {
												setSelectedRecord({});
											}
										}}
									/>
								}
								label=""
							/>
						</FormGroup>
					</HiddenOn>
				}
			</div>
		</>
	);
};

export const FilterMenu = ({
	pageModel,
	handleClose,
	filterState,
	setFilterState,
}) => {
	const dispatch = useDispatch();

	const handleChange = (type, value, hasValue) => {
		let existingValues = get(filterState, type);
		if (hasValue) {
			existingValues = existingValues.filter((item) => item !== value);
		} else {
			if (!existingValues.includes(value)) {
				existingValues.push(value);
			}
		}
		setFilterState((prevState) => ({
			...prevState,
			[type]: existingValues,
		}));
		dispatch(
			propertyGetAll({ ...pageModel, ...filterState, [type]: existingValues })
		);
	};

	return (
		<div>
			<div className="d-flex justify-content-between m-2">
				<Typography
					variant="body1"
					style={{ color: "#0B2240", fontWeight: "700" }}
				>
					<strong>Filters</strong>
				</Typography>

				<IconButton onClick={handleClose}>
					<Close style={{ fontSize: "14px" }} />
				</IconButton>
			</div>
			<Typography
				variant="body1"
				className="m-2 mt-3 mb-2"
				style={{ color: "#0B2240", fontWeight: "700" }}
			>
				<strong>Projects</strong>
			</Typography>
			<Divider className="divider" />
			<div className="mt-3 mb-3">
				{projectStatusOptions.map((type, index) => (
					<div className="d-flex m-2">
						<Typography variant="body1" className="col-8">
							{type}
						</Typography>
						<input
							checked={filterState.status.includes(type)}
							value={filterState.status.includes(type)}
							type="checkbox"
							className="col-4 pointer filter-checkbox"
							onChange={(e) => {
								handleChange("status", type, !e.target.checked);
							}}
						/>
					</div>
				))}
			</div>
			<Typography
				variant="body1"
				className="m-2 mt-3 mb-2"
				style={{ color: "#0B2240", fontWeight: "700" }}
			>
				<strong>Year</strong>
			</Typography>
			<Divider className="divider" />
			<div className="d-flex flex-wrap justify-content-center">
				{getListOfPastYears().map((year) => (
					<button
						className={`${
							filterState.year.includes(year)
								? "primaryButton"
								: "outlinedButton"
						} m-2`}
						style={{ width: "5rem" }}
						onClick={() => {
							handleChange("year", year, filterState.year.includes(year));
						}}
					>
						{year}
					</button>
				))}
			</div>
			<div className="mt-2 mb-2 text-center align-center">
				<button
					className={"outlinedButton "}
					style={{ width: "auto" }}
					onClick={() => {
						setFilterState({
							year: [],
							status: [],
						});
						dispatch(
							propertyGetAll({
								...pageModel,
							})
						);
					}}
				>
					Clear All Filters
				</button>
			</div>
		</div>
	);
};

export default PropertiesFolderView;
