import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
		maxWidth: "100vw",
		overflowX: "hidden",
	},
	content: {
		flexGrow: 1,
		paddingTop: () => theme.spacing(3),
		backgroundColor: "#f2f2f2",
		width: (isExternalAppRoute) => `calc(100vw - 240px)`,
		minHeight: "100vh",
		overflow: "auto",
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(1),
			width: "100vw", // Adjust width for small screens
		},
	},
	contentShift: {
		width: `calc(100vw - ${240 + theme.spacing(6)})`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	fakeToolbar: (isExternalAppRoute) => ({
		...theme.mixins.toolbar,
		[theme.breakpoints.down("md")]: {
			minHeight: theme.spacing(8),
		},
		[theme.breakpoints.down("sm")]: {
			minHeight: theme.spacing(5),
		},
	}),
}));
