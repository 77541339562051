import { CopilotFooter, CopilotHeader } from "components/Header";
import React from "react";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render shows the fallback UI
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// Log error details to the console or an error reporting service
		console.error("ErrorBoundary caught an error", error, errorInfo);
		this.setState({ errorInfo });
	}

	render() {
		if (this.state.hasError) {
			// Fallback UI to display when an error occurs
			return (
				<>
					<CopilotHeader />

					<div style={{ padding: "20px", textAlign: "center" }}>
						<h2>Something went wrong.</h2>
						<p>
							We're sorry for the inconvenience. Please try refreshing the page.
						</p>
						<button onClick={() => window.location.reload()}>Reload</button>
						{process.env.NODE_ENV === "development" && (
							<details style={{ marginTop: "20px", whiteSpace: "pre-wrap" }}>
								<summary>Details</summary>
								{this.state.error && this.state.error.toString()}
								<br />
								{this.state.errorInfo?.componentStack}
							</details>
						)}
					</div>
					<CopilotFooter fixed />
				</>
			);
		}

		// Render children if no error
		return this.props.children;
	}
}

export default ErrorBoundary;
