export const emailRegex =
	/^([A-Za-z0-9](\.|_)?)+@[A-Za-z0-9]+(\.[A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;

export const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

export const phoneRegExp1 =
	/^\+?\d{1,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const DATE_FORMAT = "MM-DD-YYYY";

export const getConnType = {
	newConnections: "Notconnected",
	pendingConnections: "waiting",
	connected: "connected",
};

export const APP_ACTION_CONSTANTS = {
	GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
	GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
	GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR",
	LOADING_APP: "LOADING_APP",
	LOADING_APP_SUCCESS: "LOADING_APP_SUCCESS",
	LOADING_APP_ERROR: "LOADING_APP_ERROR",
	LOGIN_EXTERNAL: "LOGIN_EXTERNAL",
	LOGIN_EXTERNAL_SUCCESS: "LOGIN_EXTERNAL_SUCCESS",
	LOGIN_EXTERNAL_ERROR: "LOGIN_EXTERNAL_ERROR",
	GET_GLOBAL_SEARCH: "GET_GLOBAL_SEARCH",
	GET_GLOBAL_SEARCH_SUCCESS: "GET_GLOBAL_SEARCH_SUCCESS",
	GET_GLOBAL_SEARCH_ERROR: "GET_GLOBAL_SEARCH_ERROR",
	RECEIVE_ALERT_NOTIFICATIONS: "RECEIVE_ALERT_NOTIFICATIONS",
	APP_CONFIG: "APP_CONFIG",
	APP_CONFIG_SUCCESS: "APP_CONFIG_SUCCESS",
	APP_CONFIG_ERROR: "APP_CONFIG_ERROR",
	APP_LOADING_START: "APP_LOADING_START",
	APP_LOADING_STOP: "APP_LOADING_STOP",
	CREATE_ALERT: "CREATE_ALERT",
	CREATE_ALERT_SUCCESS: "CREATE_ALERT_SUCCESS",
	CREATE_ALERT_ERROR: "CREATE_ALERT_ERROR",
	USER_COMPANY_DETAILS: "USER_COMPANY_DETAILS",
	USER_COMPANY_DETAILS_SUCCESS: "USER_COMPANY_DETAILS_SUCCESS",
	USER_COMPANY_DETAILS_ERROR: "USER_COMPANY_DETAILS_ERROR",
	SHOW_APP_API_ALERT: "SHOW_APP_API_ALERT",
};

export const MOBILE_MAX_WIDTH_768 = "(max-width:787px)";

export const SIGNOUT_TEXT = "Signout";

export const voiceAssistantPath = "voiceAssistant";

export const GUID_ERROR_MESSAGE = "Verification Link is not Valid or expired";

export const MOBILE_MAX_LISTED_RESULT = 5;
