import React from "react";

const JobsIcon = ({ color = "#345DAE" }) => (
  <svg
    width="27"
    height="29"
    viewBox="0 0 27 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8453 2.8293H26.5856V5.90821H13.8453V2.8293ZM13.8453 13.6055H26.5856V16.6844H13.8453V13.6055ZM1.10503 21.3027H9.06772V29H1.10503V21.3027ZM3.49383 23.6119V26.6908H6.67891V23.6119H3.49383ZM13.8453 24.3816H26.5856V27.4605H13.8453V24.3816ZM8.90468 0L10.7981 1.40793L5.12226 8.54058L0 4.20801L1.57304 2.47017L4.7806 5.18159L8.90468 0ZM8.90468 10.7762L10.7981 12.1841L5.12226 19.3167L0 14.9842L1.57304 13.2463L4.7806 15.9578L8.90468 10.7762Z"
      fill={color}
    />
  </svg>
);

export default JobsIcon;
