import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const ModelDialog = ({
	open = false,
	dialogTitle = "",
	dialogContent = <></>,
	handleClose = () => {},
	dialogClasses = {},
	...modelProps
}) => {
	return (
		<div className={dialogClasses.dialogContainer}>
			<Dialog
				className={dialogClasses.dialog}
				open={open}
				onClose={handleClose}
				// zIndex={1230}
				// sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
				{...modelProps}
			>
				{dialogTitle && (
					<DialogTitle className={dialogClasses.dialogTitle}>
						{dialogTitle}
					</DialogTitle>
				)}

				<DialogContent className={dialogClasses.dialogContent}>
					{dialogContent}
				</DialogContent>
			</Dialog>
		</div>
	);
};
