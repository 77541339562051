import {
	Icon,
	InputAdornment,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { getText } from "core/utils/getContentText";
import React from "react";
import visa from "assets/payment/visa.png";
// import mastercard from "assets/payment/mastercard.png";
// import americanExpress from "assets/payment/americanExpress.png";
// import jcb from "assets/payment/jcb.png";
// import discover from "assets/payment/discover.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { paymentInformationAction } from "actions/login_registrationActions";
import AddCardIcon from "@mui/icons-material/AddCard";
import keys from "lodash/keys";

const getExpiryDate = (days) => {
	const today = new Date();
	const newDate = new Date(today);
	newDate.setDate(newDate.getDate() + days);
	return newDate.toISOString().split("T")[0];
};

const plansList = [
	{
		key: "30",
		days: "30",
		label: "Monthly",
		price: "50",
		discountedPrice: "30",
		discountPercentage: "40%",
		description: `This is a 30-day subscription plan available for a standard price of $50. Enjoy a discounted rate of $30 for this period. Your subscription will last for 30 days from the date of purchase and will automatically renew on ${getExpiryDate(
			30
		)} if not canceled.`,
	},
	{
		key: "180",
		days: "180",
		label: "Semi-Annual",
		price: "300",
		discountedPrice: "165",
		discountPercentage: "45%",
		description: `This is a 180-day (6-month) subscription plan available for a standard price of $300. Take advantage of a discounted rate of $165 for this plan, giving you significant savings over the monthly option. Your subscription will last for 180 days from the date of purchase and will renew on ${getExpiryDate(
			180
		)} if not canceled.`,
	},
	{
		key: "365",
		days: "365",
		label: "Annual",
		price: "600",
		discountedPrice: "300",
		discountPercentage: "50%",
		description: `This is a 365-day (1-year) subscription plan available for a standard price of $600. With a discounted rate of $360, this plan offers the best value for long-term access, saving you even more over the monthly and semi-annual options. Your subscription will last for a full year from the date of purchase and will renew on ${getExpiryDate(
			365
		)} if not canceled.`,
	},
];

export const PaymentInformation = ({ setShowConfirmationPage }) => {
	const dispatch = useDispatch();
	const [planSelected, setPlanSelected] = React.useState(plansList[0]);
	// const paymentIcons = { visa, mastercard, americanExpress, jcb, discover };
	const paymentIcons = { visa };

	const formik = useFormik({
		initialValues: {
			cardNumber: "",
			expiryDate: "",
			cvv: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipCode: "",
		},
		validationSchema: yup.object({
			cardNumber: yup
				.string("Enter Card Number")
				.transform((value) => (value ? value.replace(/\D/g, "") : value))
				.matches(/^\d{15,16}$/, "Enter 15 or 16 digits")
				.required("Card Number is required"),

			expiryDate: yup
				.string("Enter Expiry Date")
				.matches(
					/^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
					"Enter a valid expiry date (MM/YY)"
				)
				.required("Expiry Date is required"),

			cvv: yup
				.string("Enter CVV")
				.matches(/^\d{3,4}$/, "Enter 15 or 16 digits")
				.required("CVV is required"),
			address1: yup.string("Enter Address").required("Address is required"),
			address2: yup.string("Enter Address"),
			city: yup.string("Enter City").required("City is required"),
			state: yup.string("Enter State").required("State is required"),
			zipCode: yup.string("Enter Zip Code").required("Zip Code is required"),
		}),
		onSubmit: () => {
			dispatch(
				paymentInformationAction(
					{
						...formik.values,
						cardNumber: "4000 0000 0000 0002",
						cardType: "VISA",
						crdcvv: formik.values.cvv,
						price: parseInt(planSelected.discountedPrice),
						days: planSelected.days || planSelected.key,
						plan: planSelected.label,
					},
					() => {
						setShowConfirmationPage(true);
					}
				)
			);
		},
	});

	const shouldDisable = () => {
		const mandatoryFields = [
			"cardNumber",
			"expiryDate",
			"cvv",
			"address1",
			"city",
			"state",
			"zipCode",
		];
		const shouldDisableButton = mandatoryFields.some((field) => {
			return Object.keys(formik.errors).includes(field);
		});
		const hasMandatoryValues = mandatoryFields.every(
			(field) => formik.values[field]
		);

		return shouldDisableButton || !hasMandatoryValues;
	};

	const handleCardNumberChange = (event) => {
		const { name, value } = event.target;
		let formattedCardNumber = value;

		formattedCardNumber = formattedCardNumber.replace(/\D/g, "");

		if (formattedCardNumber.length > 0) {
			formattedCardNumber = formattedCardNumber
				.match(new RegExp(".{1,4}", "g"))
				.join(" ");
		}

		formik.setFieldValue(name, formattedCardNumber);
	};

	const handleExpiryDateChange = (event) => {
		const { name, value } = event.target;
		let formattedCardNumber = value;

		// Add "/" after the second character (MM)
		if (formattedCardNumber.length === 1 && formattedCardNumber.includes("/")) {
			formattedCardNumber = "";
		}
		if (
			formattedCardNumber.length === 2 &&
			!formattedCardNumber.includes("/")
		) {
			formattedCardNumber += "/";
		}

		formik.setFieldValue(name, formattedCardNumber);
	};

	return (
		<div>
			<div id="plan-selection" className="mt-3 mb-3">
				<Typography variant="body1">
					<strong>Subscription plan</strong>
				</Typography>
				<TextField value={planSelected.key} select className="form-field">
					{plansList.map((option) => {
						return (
							<MenuItem
								key={option.key}
								value={option.key}
								onClick={() => setPlanSelected(option)}
							>
								<Typography>
									{option.label}
									<span
										className="ms-2"
										style={{
											textDecoration: "line-through",
											fontWeight: "600",
										}}
									>
										(${option.price})
									</span>
									<span
										className="ms-2"
										style={{ fontWeight: "600", color: "green" }}
									>
										${option.discountedPrice}
									</span>
									<span
										className="ms-2"
										style={{ fontSize: "12px", color: "green" }}
									>
										({option.discountPercentage} Off)
									</span>
								</Typography>
							</MenuItem>
						);
					})}
				</TextField>
				<span className="mt-4" style={{ fontSize: "10px" }}>
					<strong>{planSelected.description}</strong>
				</span>
			</div>
			<div className="payment-header">
				<Typography variant="h4" className="h2">
					{getText("paymentInformation.freeTrailHeading")}
				</Typography>
				<Typography variant="body1">
					{getText("paymentInformation.freeTrailMessage")}
				</Typography>
			</div>
			<div className="payment-header-information mt-4">
				<Typography variant="h2" className="h4">
					{getText("paymentInformation.paymentDetails")}
				</Typography>
				<Typography variant="body1">
					{getText("paymentInformation.paymentDetailsInfo")}
				</Typography>
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className="payment-form mt-4 align-center">
					<div className="card-number">
						<label>
							<b>{getText("paymentInformation.cardNumber")}</b>
						</label>
						<TextField
							key={"cardNumber"}
							id={"cardNumber"}
							name={"cardNumber"}
							value={formik.values["cardNumber"]}
							onChange={handleCardNumberChange}
							inputProps={{ maxLength: 19 }}
							onBlur={formik.handleBlur}
							error={
								formik.touched["cardNumber"] &&
								Boolean(formik.errors["cardNumber"])
							}
							helperText={
								formik.touched["cardNumber"] && formik.errors["cardNumber"]
							}
							className="fullWidth registration-formField mt-4"
							sx={{ m: 1, width: "25ch" }}
							placeholder="Enter Card Number"
							InputProps={{
								endAdornment: Object.keys(paymentIcons).map((key) => {
									return (
										<InputAdornment position="start">
											<Icon style={{ width: "auto" }}>
												{/* <img src={paymentIcons[key]} alt={key} /> */}
												<AddCardIcon />
											</Icon>
										</InputAdornment>
									);
								}),
							}}
							variant="outlined"
						/>
					</div>
					<div className="mt-4 expiry-info d-flex justify-content-between">
						<div className="col-4 col-xs-12 expiry-date">
							<label>
								<b>{getText("paymentInformation.expiryDate")}</b>
							</label>
							<TextField
								className="registration-formField mt-4"
								placeholder="MM/YY"
								variant="outlined"
								key={"expiryDate"}
								id={"expiryDate"}
								name={"expiryDate"}
								inputProps={{ maxLength: 5 }}
								value={formik.values["expiryDate"]}
								onChange={handleExpiryDateChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched["expiryDate"] &&
									Boolean(formik.errors["expiryDate"])
								}
								helperText={
									formik.touched["expiryDate"] && formik.errors["expiryDate"]
								}
							/>
						</div>
						<div className="col-4 col-xs-12 cvv">
							<label>
								<b>{getText("paymentInformation.cvv")}</b>
							</label>
							<TextField
								className="registration-formField mt-4"
								type="password"
								placeholder="CVV"
								variant="outlined"
								inputProps={{ maxLength: 3 }}
								key={"cvv"}
								id={"cvv"}
								name={"cvv"}
								value={formik.values["cvv"]}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched["cvv"] && Boolean(formik.errors["cvv"])}
								helperText={formik.touched["cvv"] && formik.errors["cvv"]}
							/>
						</div>
					</div>
					<div className="mt-4 billing-info">
						<h3>{getText("paymentInformation.billingAddress")}</h3>
						<div className="address-1 mt-4">
							<label>
								<b>
									{getText("paymentInformation.address1")}
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
								</b>
							</label>
							<TextField
								className="registration-formField mt-4"
								placeholder="Street address"
								variant="outlined"
								key={"address1"}
								id={"address1"}
								name={"address1"}
								value={formik.values["address1"]}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched["address1"] &&
									Boolean(formik.errors["address1"])
								}
								helperText={
									formik.touched["address1"] && formik.errors["address1"]
								}
							/>
						</div>
						<div className="address-2 mt-4">
							<label>
								<b>{getText("paymentInformation.address2")}</b>
							</label>
							<TextField
								className="registration-formField mt-4"
								placeholder="Appartmet, Building Name etc."
								variant="outlined"
								key={"address2"}
								id={"address2"}
								name={"address2"}
								value={formik.values["address2"]}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched["address2"] &&
									Boolean(formik.errors["address2"])
								}
								helperText={
									formik.touched["address2"] && formik.errors["address2"]
								}
							/>
						</div>
						<div className="address-state d-flex justify-content-between mt-4">
							<div className="col-3 col-xs-8">
								<label>
									<b>
										{getText("paymentInformation.city")}
										<sup style={{ color: "red", fontSize: "1em", top: 0 }}>
											*
										</sup>
									</b>
								</label>
								<TextField
									className="registration-formField mt-4"
									placeholder="City"
									variant="outlined"
									key={"city"}
									id={"city"}
									name={"city"}
									value={formik.values["city"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched["city"] && Boolean(formik.errors["city"])
									}
									helperText={formik.touched["city"] && formik.errors["city"]}
								/>
							</div>
							<div className="col-3 col-xs-8">
								<label>
									<b>
										{getText("paymentInformation.state")}
										<sup style={{ color: "red", fontSize: "1em", top: 0 }}>
											*
										</sup>
									</b>
								</label>
								<TextField
									className="registration-formField mt-4"
									placeholder="State"
									variant="outlined"
									key={"state"}
									id={"state"}
									name={"state"}
									value={formik.values["state"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched["state"] && Boolean(formik.errors["state"])
									}
									helperText={formik.touched["state"] && formik.errors["state"]}
								/>
							</div>
							<div className="col-3 col-xs-8">
								<label>
									<b>
										{getText("paymentInformation.zipCode")}
										<sup style={{ color: "red", fontSize: "1em", top: 0 }}>
											*
										</sup>
									</b>
								</label>
								<TextField
									className="registration-formField mt-4"
									placeholder="Zip Code"
									variant="outlined"
									key={"zipCode"}
									id={"zipCode"}
									name={"zipCode"}
									value={formik.values["zipCode"]}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched["zipCode"] &&
										Boolean(formik.errors["zipCode"])
									}
									helperText={
										formik.touched["zipCode"] && formik.errors["zipCode"]
									}
								/>
							</div>
						</div>
						<div className="form-footer mt-5 d-flex justify-content-between mb-2">
							<div className="col-xs-12 mt-3">
								<Typography variant="body1" className="body1 payment-terms">
									{getText("paymentInformation.cancelText")}
								</Typography>
								<Typography variant="body1" className="body1 payment-terms">
									{getText("paymentInformation.acceptText")}
									<span className="link-text ml-1">
										{getText("paymentInformation.serviceTerms")}
									</span>
								</Typography>
							</div>
							<div className="col-3 col-xs-12 mt-3 float-right">
								<button
									type={"submit"}
									className={`primaryButton ${
										shouldDisable() ? "disabledButton" : ""
									}`}
									disabled={shouldDisable()}
									style={{ width: "100%" }}
								>
									{"Complete>"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default PaymentInformation;
