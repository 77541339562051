import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.warranty;

export const getWarranties = createSelector(selectState, (warranties) => ({
  warranties: get(warranties, "warranties", []),
  totalCount: get(warranties, "totalCount", 0),
  loading: get(warranties, "loading", false),
  showSnackbar: get(warranties, "showSnackbar", false),
  status: get(warranties, "status", "success"),
  statusText: get(warranties, "message", ""),
}));

// export const marketPlaceLoadingSelector = createSelector(selectState, (app) =>
//   get(app, "loading", false)
// );

// export const marketPlaceSnackBarSelector = createSelector(selectState, (app) =>
//   get(app, "showSnackbar", false)
// );
