import request from "config/api";
import { server_url } from "../constants/types";
export const fetchAppConfig = () => async (dispatch) => {
  try {
    request({
      method: "GET",
      url: `${server_url}/appconfig`,
    }).then((data) => {
      dispatch({ type: "FETCH_CONFIG_SUCCESS", payload: data.data[0] });
    });
  } catch (error) {
    console.error("Failed to fetch app config", error);
  }
};

export const updateAppConfig = (config) => async (dispatch) => {
  try {
    request({
      method: "PUT",
      url: `${server_url}/appconfig/${config.configId}`,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(config),
    }).then((data) => {
      console.log("Updated >>", data);
      dispatch({ type: "UPDATE_CONFIG", payload: config });
    });
  } catch (error) {
    console.error("Failed to update app config", error);
  }
};
