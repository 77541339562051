import {
	JOB_LOADING,
	JOB_GET_ALL_SUCCESS,
	JOB_ERROR,
	JOB_CLEAR_SNACKBAR,
} from "../constants/types";
import { ActionConstants } from "constants";

const initialState = {
	jobList: [],
	jobUsersList: [],
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
	jobStatus: [],
	jobAssigneeList: [],
};

const jobReducer = (state = initialState, action) => {
	switch (action.type) {
		case JOB_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case JOB_LOADING:
			return {
				...state,
				loading: true,
			};
		case JOB_ERROR:
			return {
				...state,
				jobList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case JOB_GET_ALL_SUCCESS:
			return {
				...state,
				jobList: action.payload.jobList,
				totalCount: action.payload.totalCount,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case ActionConstants.GET_JOB_BY_ID_SUCCESS:
			return {
				...state,
				jobData: action.payload.response,
			};
		case ActionConstants.GET_JOB_BY_ID_ERROR:
			return {
				...state,
			};
		case ActionConstants.UPDATE_JOB_USERS_ROLES_SUCCESS:
			return {
				...state,
				jobUsersList: action.payload.response,
			};
		case ActionConstants.GET_JOB_USERS_ROLES_SUCCESS:
			return {
				...state,
				jobUsersList: action.payload.response,
			};
		case ActionConstants.GET_JOB_STATUS_SUCCESS:
			return {
				...state,
				jobStatus: action.payload.response,
			};
		case ActionConstants.GET_JOB_TYPE_SUCCESS:
			return {
				...state,
				jobType: action.payload.response,
			};
		case ActionConstants.GET_JOB_ASSIGNEE_LIST_SUCCESS:
			return {
				...state,
				jobAssigneeList: action.payload.response,
			};

		default:
			return state || {};
	}
};

export default jobReducer;
