import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Hidden,
	useMediaQuery,
	Drawer,
	Box,
	Stack,
	Button,
	Avatar,
	TextField,
	InputAdornment,
	Paper,
} from "@mui/material";
import logo from "assets/app/logo_n.png";
import voiceAssistantImg from "assets/app/voice-assistant.png";

import {
	Menu as MenuIcon,
	NotificationsNone as NotificationsIcon,
	Person as AccountIcon,
	// Search as SearchIcon,
	ArrowBack as ArrowBackIcon,
	SettingsOutlined,
} from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";

import classNames from "classnames";
import "../VoiceAssistantLayout/ChatAssistant.css";
import VoiceAssistantLayout from "../VoiceAssistantLayout/VoiceAssistantLayout";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";

// context
import {
	useLayoutState,
	useLayoutDispatch,
	toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalProjectSelector from "./GlobalProjectSelector";
import "./index.scss";

import { MOBILE_MAX_WIDTH_768 } from "constants/appConstants";
import ChatAssistant from "components/ChatAssistant/ChatAssistant";
import { useVoiceAssistantState } from "context/VoiceAssistantContext";
import WavesListeningAnimation from "components/VoiceAssistantLayout/WavesListeningAnimation/WavesListeningAnimation";

const notifications = [];

export default function Header({ userProfile }) {
	var classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	// global
	var layoutState = useLayoutState();
	var layoutDispatch = useLayoutDispatch();
	var userDispatch = useUserDispatch();

	// local
	var [notificationsMenu, setNotificationsMenu] = useState(null);
	var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
	var [profileMenu, setProfileMenu] = useState(null);
	var [isSearchOpen, setSearchOpen] = useState(false);

	const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

	// State to control drawer
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [chatMessages, setChatMessages] = useState([]);
	const [inputMessage, setInputMessage] = useState("");

		const { VoiceAssistantState, setVoiceAssistantState, voiceAssistantEnabled } =
			useVoiceAssistantState();
	
	const handleLogoClick = () => {};
	const handleNotificationsClick = () => {};

	return (
		<AppBar position="fixed" className={classes.appBar}>
			{isMobile ? (
				<AppBar position="fixed" style={{ top: 0, left: 0 }}>
					<Toolbar
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: "#ffffff",
							color: "#000000",
						}}
					>
						{/* Logo */}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={handleLogoClick}
						>
							<img
								src={logo}
								alt="logo_n"
								style={{
									filter: "invert(50%)",
									height: "65px",
									marginRight: "10px",
								}}
							/>
						</div>

						{/* Notification */}
						<div>
							<IconButton
								onClick={handleNotificationsClick}
								arial-label="Notifications"
								sx={{
									color: "gray",
								}}
							>
								<Badge badgeContent={0} color="error">
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<IconButton
								onClick={() => navigate("/app/profile")}
								arial-label="Profile"
								sx={{
									color: "gray",
								}}
							>
								<Badge badgeContent={0} color="error">
									<AccountIcon />
								</Badge>
							</IconButton>
						</div>
					</Toolbar>
				</AppBar>
			) : (
				<Toolbar className={classes.toolbar}>
					<IconButton
						color="inherit"
						onClick={() => toggleSidebar(layoutDispatch)}
						className={classNames(
							classes.headerMenuButton,
							classes.headerMenuButtonCollapse
						)}
					>
						{layoutState.isSidebarOpened ? (
							<ArrowBackIcon
								classes={{
									root: classNames(
										classes.headerIcon,
										classes.headerIconCollapse
									),
								}}
							/>
						) : (
							<MenuIcon
								classes={{
									root: classNames(
										classes.headerIcon,
										classes.headerIconCollapse
									),
								}}
							/>
						)}
					</IconButton>
					<Hidden smDown>
						<IconButton>
							<img
								src={logo}
								alt="builder copilot"
								className="logo p-0"
								style={{ width: "7rem" }}
								onClick={() => navigate("/")}
							/>
						</IconButton>
					</Hidden>
					<div>
						{/* {!location.pathname.includes("/projects") && ( */}
						<GlobalProjectSelector />
						{/* )} */}
					</div>
					<div className={classes.grow} />
					{/* Commenting The Search Functionality, as it is not used anywere */}
					{/* <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div> */}
					{/* Commenting The Search Functionality, as it is not used anywere */}

					<Hidden smDown>
						<IconButton
							color="inherit"
							aria-haspopup="true"
							aria-controls="mail-menu"
							disabled={!voiceAssistantEnabled}
							onClick={() => setDrawerOpen(true)}
							className={classes.headerMenuButton}
						>
							<Badge
								badgeContent={
									isNotificationsUnread ? notifications.length : null
								}
								color="warning"
							>
								<img
									src={voiceAssistantImg}
									alt="Voice Assistant"
									className="logo p-0"
									style={{
										width: "2.5rem",
										filter: voiceAssistantEnabled ? "none" : "grayscale(100%)",
										cursor: voiceAssistantEnabled ? "pointer" : "not-allowed",
									}}
								/>
							</Badge>
						</IconButton>

						{/* <IconButton
							color="inherit"
							aria-haspopup="true"
							aria-controls="mail-menu"
							onClick={(e) => {
								setNotificationsMenu(e.currentTarget);
								setIsNotificationsUnread(false);
							}}
							className={classes.headerMenuButton}
						>
							<Badge
								badgeContent={
									isNotificationsUnread ? notifications.length : null
								}
								color="warning"
							>
								<SettingsOutlined classes={{ root: classes.headerIcon }} />
							</Badge>
						</IconButton> */}

						<IconButton
							aria-haspopup="true"
							color="inherit"
							className={classes.headerMenuButton}
							aria-controls="profile-menu"
							onClick={(e) => setProfileMenu(e.currentTarget)}
						>
							<AccountIcon classes={{ root: classes.headerIcon }} />
						</IconButton>

						<Menu
							id="notifications-menu"
							open={Boolean(notificationsMenu)}
							anchorEl={notificationsMenu}
							onClose={() => setNotificationsMenu(null)}
							className={classes.headerMenu}
							disableAutoFocusItem
						>
							{notifications.map((notification) => (
								<MenuItem
									key={notification.id}
									onClick={() => setNotificationsMenu(null)}
									className={classes.headerMenuItem}
								>
									<Notification {...notification} typographyVariant="inherit" />
								</MenuItem>
							))}
						</Menu>
						<Menu
							id="profile-menu"
							open={Boolean(profileMenu)}
							anchorEl={profileMenu}
							onClose={() => setProfileMenu(null)}
							className={classes.headerMenu}
							classes={{ paper: classes.profileMenu }}
							disableAutoFocusItem
						>
							<div className={classes.profileMenuUser}>
								<Typography variant="subtitle1" className="h6" weight="medium">
									{userProfile.email}
								</Typography>
								<Typography variant="body1" weight="medium">
									User Name:
									<strong>{` ${userProfile.firstName} ${userProfile.lastName}`}</strong>
								</Typography>
							</div>
							<MenuItem
								className={classNames(
									// classes.profileMenuItem,
									classes.headerMenuItem
								)}
								style={{ fontSize: "1", fontWeight: "700" }}
								onClick={() => {
									navigate("/app/profile");
									setProfileMenu(null);
								}}
							>
								<AccountIcon
									style={{ fontSize: "24px" }}
									className={classes.profileMenuIcon}
								/>{" "}
								Profile
							</MenuItem>
							{/* <MenuItem
                className={classNames(
                  classes.profileMenuItem,
                  classes.headerMenuItem
                )}
              >
                <AccountIcon className={classes.profileMenuIcon} /> Tasks
              </MenuItem> */}
							<div
								className={classes.profileMenuUser}
								onClick={() => {
									console.log("On SignOut");
									localStorage.removeItem("token");
									navigate("/login");
								}}
							>
								<Typography className={classes.profileMenuLink} color="primary">
									Sign Out
								</Typography>
							</div>
						</Menu>
					</Hidden>
				</Toolbar>
			)}

			{/* Drawer for Side Sheet */}
			<Drawer
				anchor="right"
				open={isDrawerOpen && !isMobile}
				onClose={() => setDrawerOpen(false)} // Close the drawer
			>
				<div
					style={{
						width: "400px",
						marginTop: "75px",
						padding: "20px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
						height: "100vh",
						position: "relative",
					}}
				>
					{/* Close button */}
					<IconButton
						style={{ alignSelf: "flex-end" }}
						onClick={() => setDrawerOpen(false)}
					>
						<CloseIcon />
					</IconButton>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<VoiceAssistantLayout />
					</Box>
				</div>
			</Drawer>
		</AppBar>
	);
}
