import { INITIAL_FLOW_STATE } from "components/VoiceAssistantLayout/VoiceAssistantCommandExecutionFlow";
import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { getAppConfig, getAppConfigVoiceAssistantConfig, getVoiceAssistantMicrophoneAccess } from "selectors/appSelector";

const VoiceAssistantStateContext = createContext();

export const VoiceAssistantProvider = ({ children }) => {
  const [VoiceAssistantState, setVoiceAssistantState] = useState({
    isListening: false,
  });

  const [VoiceAssistantFlowState, setVoiceAssistantFlowState] = useState(INITIAL_FLOW_STATE);

  const { voiceAssistantEnabled } = useSelector(getAppConfig);
  const voiceAssistantConfig = useSelector(getAppConfigVoiceAssistantConfig);
  const voiceAssistantMicrophoneAccess = useSelector(getVoiceAssistantMicrophoneAccess);

  return (
    <VoiceAssistantStateContext.Provider
      value={{
        VoiceAssistantState,
        setVoiceAssistantState,
        voiceAssistantEnabled,
        voiceAssistantConfig,
        VoiceAssistantFlowState,
        setVoiceAssistantFlowState,
        voiceAssistantMicrophoneAccess,
      }}
    >
      {children}
    </VoiceAssistantStateContext.Provider>
  );
};

export const useVoiceAssistantState = () =>
  useContext(VoiceAssistantStateContext);
