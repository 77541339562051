import { TextField, Typography } from "@mui/material";
import { createAlertAction } from "actions/appActions";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import React from "react";
import { useDispatch } from "react-redux";

export const ManageAlerts = () => {
	const dispatch = useDispatch();
	const initialState = {
		alertType: "System Alert",
		isActive: "YES",
		source: "",
		alertTitle: "",
		alertMessage: "",
	};
	const [alertData, setAlertData] = React.useState(initialState);
	const [showSnackbar, setShowSnackbar] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState("");

	const alertFields = [
		{
			key: "source",
			label: "Alert Source",
		},
		{
			key: "alertTitle",
			label: "Alert Title",
		},
		{
			key: "alertMessage",
			label: "Alert Message",
			width: "100%",
			extraInputProps: {
				multiline: true,
				className: "form-field alert-message alert-message-details-textarea",
			},
		},
	];

	return (
		<div className="p-3">
			<SnackBarWithInfoAlert
				open={showSnackbar}
				alertMessage={snackbarMessage.message}
				alertSeverity={snackbarMessage.status}
				onClose={() => setShowSnackbar(false)}
			/>
			<Typography className="h5">Create New Alert for Users</Typography>
			<div>
				<div className="d-flex flex-wrap">
					{alertFields.map((field) => {
						const extraProps = field.extraInputProps || {};
						return (
							<div
								className={`alert-${field.key} m-2`}
								style={{ width: field.width || "47%" }}
							>
								<Typography className="h6 ">{field.label}</Typography>
								<TextField
									key={field.key}
									id={field.key}
									value={alertData[field.key]}
									variant="outlined"
									fullWidth
									className="form-field"
									onChange={(e) => {
										setAlertData((prevState) => {
											return {
												...prevState,
												[field.key]: e.target.value,
											};
										});
									}}
									{...extraProps}
								/>
							</div>
						);
					})}
				</div>
				<div className="d-flex justify-content-center mt-4">
					<button
						className="primaryButton col-10 full-width"
						onClick={() => {
							dispatch(
								createAlertAction(
									{
										...alertData,
										alertdateTime: new Date().toISOString(),
									},
									(payload) => {
										setShowSnackbar(true);
										setSnackbarMessage(payload);
										setAlertData(initialState);
									}
								)
							);
						}}
					>
						Create Alert
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageAlerts;
