import * as LOGIN_ACTIONS from "./login_registration";
import * as PROFILE_ACTIONS from "./profileConstants";
import * as COMPANY_ACTIONS from "./companiesConstants";
import * as PROPERTY_ACTIONS from "./propertyConstants";
import * as JOB_ACTIONS from "./jobConstants";
import * as PROJECT_ACTIONS from "./projectConstants";
import * as TASK_ACTIONS from "./taskConstants";
import * as USER_ACTIONS from "./userConstants";
import * as DOCUMENT_ACTIONS from "./documentsConstants";
import * as ESTIMATE_ACTIONS from "./estimatesConstants";

import { APP_ACTION_CONSTANTS } from "./appConstants";
import {APP_BUILDER_AI_ACTION_CONSTANTS} from "./appBuilderAiConstants";

export const ActionConstants = {
	...LOGIN_ACTIONS,
	...PROFILE_ACTIONS,
	...COMPANY_ACTIONS,
	...PROPERTY_ACTIONS,
	...JOB_ACTIONS,
	...PROJECT_ACTIONS,
	...TASK_ACTIONS,
	...USER_ACTIONS,
	...APP_ACTION_CONSTANTS,
	...DOCUMENT_ACTIONS,
	...ESTIMATE_ACTIONS,
	...APP_BUILDER_AI_ACTION_CONSTANTS,
};
