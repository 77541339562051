import {
	InputAdornment,
	Autocomplete,
	CircularProgress,
	TextField,
	IconButton,
} from "@mui/material";
import { globalSearchAction } from "actions/appActions";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGlobalSearch } from "selectors/appSelector";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export const GlobalProjectSelector = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const { searchResults, loadingResults } = useSelector(getGlobalSearch);
	const [searchText, setSearchText] = React.useState("");

	useEffect(() => {
		dispatch(globalSearchAction(" "));
	}, [dispatch]);

	const redirectTypes = {
		Project: "/projects/view",
		Property: "/properties/view",
		// Supplier: "",
		Job: "/jobs/view",
		Task: "/tasks/view",
	};

	return (
		<>
			<Autocomplete
				freeSolo
				value={selectedItem}
				onChange={(event, value) => {
					setSelectedItem(value);
					const optionPath = redirectTypes[value.objecttype];

					navigate(`${optionPath}/${value.id}`);
				}}
				style={{ width: "30rem" }}
				id="global-search-field"
				className="form-field header-project-selector"
				isOptionEqualToValue={(option, value) => option.id === value.id}
				filterOptions={(x) => x}
				getOptionLabel={(option) => option.name}
				options={searchResults}
				loading={loadingResults}
				placeholder="Search for Address, Property, Homeowner, Project & Job."
				onInputChange={(event, value) => {
					setSearchText(value);
					console.log("onInputChange ,", value);
					if (value.length >= 3) {
						dispatch(globalSearchAction(value));
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						placeholder="Search for Address, Properties, Jobs, Suppliers & People."
						InputLabelProps={{
							style: {
								fontFamily: "Barlow",
								fontWeight: 400,
								fontSize: "14px",
								letterSpacing: "-3%",
								color: "#8B8B8B",
								// Add any other CSS properties you want
							},
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon sx={{ fontSize: 30 }} />
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</>
	);
};

export default GlobalProjectSelector;
