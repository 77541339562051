import React, { useState } from "react";
import { Box, Typography, Grid, Paper, styled } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TableChartIcon from "@mui/icons-material/TableChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CheckCircleOutline, FeedOutlined } from "@mui/icons-material";
import { palette } from "themes";

const FileContainer = styled(Paper)(({ theme, selected }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 220,
  backgroundColor: selected ? palette.bluePrimary : theme.palette.grey[100],
  color: selected ? theme.palette.common.white : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  cursor: "pointer",
  transition: "background-color 0.3s, color 0.3s",
  "&:hover": {
    backgroundColor: selected
      ? theme.palette.primary.dark
      : theme.palette.grey[200],
  },
}));

const CheckIconContainer = styled(Box)(({ theme, selected }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  color: selected ? theme.palette.common.white : theme.palette.grey[400],
  transition: "color 0.3s",
}));

const FileIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const FileIcon = ({ type }) => {
  switch (type) {
    case "pdf":
    case "docx":
      return <FeedOutlined fontSize="large" />;
    case "xlsx":
      return <TableChartIcon fontSize="large" />;
    default:
      return <FeedOutlined fontSize="large" />;
  }
};

const FileGrid = ({ files }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  //   const files = [
  //     { name: "Construction Document 01.pdf", type: "pdf" },
  //     { name: "Approval Document.pdf", type: "pdf" },
  //     { name: "Items List.xlsx", type: "xlsx" },
  //     { name: "Rough Estimate.docx", type: "docx" },
  //   ];

  const handleFileClick = (fileName) => {
    setSelectedFile(fileName === selectedFile ? null : fileName);
  };

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        {files.map((file) => (
          <Grid item xs={3} key={file.fileName}>
            <Box
              onClick={() => handleFileClick(file.fileName)}
              textAlign="center"
            >
              {/* File Card */}
              <FileContainer selected={selectedFile === file.fileName}>
                {/* Checkbox Icon */}
                <CheckIconContainer selected={selectedFile === file.fileName}>
                  {selectedFile === file.fileName ? (
                    <CheckCircleOutline sx={{ fontSize: "21px" }} />
                  ) : null}
                </CheckIconContainer>

                {/* File Icon */}
                <FileIconContainer>
                  <FileIcon type={file.type} />
                </FileIconContainer>
              </FileContainer>

              {/* File Label */}
              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  wordBreak: "break-word",
                  color:
                    selectedFile === file.fileName ? "primary.main" : "inherit",
                }}
              >
                {file.fileName}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FileGrid;
