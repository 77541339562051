import { fetchProjectOverview } from "actions/projectActions";
import NoResultsFound from "components/NoResults/NoResultsFound";
import HiddenOn from "components/Wrappers/HiddenOn";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectOverview } from "selectors/projectSelector";
import ProjectTimelineChart from "./ProjectOverviewTimeline";
import ScheduledJobsList from "./ScheduledJobsList";

export const ProjectSchedule = ({ projectId, projectData }) => {
	const dispatch = useDispatch();

	const overviewData = useSelector(getProjectOverview);

	const projectOverviewData = {
		...overviewData,
		// ...localprojectOverviewData,
	};

	useEffect(() => {
		dispatch(fetchProjectOverview(projectId));
	}, [projectId, dispatch]);
	return (
		<>
			<div className="p-3">
				<div className="card h-100">
					<div className="card-body mt-4">
						<h3 className="card-title h6 mb-2 ms-0">Key Jobs Status</h3>
						<div>
							<HiddenOn
								isHidden={
									!projectOverviewData.projectTimeline ||
									projectOverviewData.projectTimeline.length <= 0
								}
								fallback={<NoResultsFound message="No Data Available" />}
							>
								<ProjectTimelineChart
									projectTimeline={projectOverviewData.projectTimeline || []}
								/>
							</HiddenOn>
						</div>
					</div>
				</div>
				<ScheduledJobsList
					projectId={projectId}
					projectData={projectData}
					projectOverviewData={projectOverviewData}
				/>
			</div>
		</>
	);
};

export default ProjectSchedule;
