import { ActionConstants } from "constants";

const initialState = {
	newConnections: [],
	connections: [],
	pendingConnections: [],
	unfilteredCompanies: [],
	loading: false,
	status: "success",
	text: "",
	showSnackbar: false,
	companiesList: [],
	totalCompaniesCount: 0,
	marketSectorsList: [],
	companyTypesList: [],
	companyDetails: {},
	companyDetailsError: {},
};

const companyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.GET_COMPANIES:
			return {
				...state,
				loading: true,
			};
		case ActionConstants.GET_COMPANIES_SUCCESS:
			return {
				...state,
				companiesList: action.payload.companiesList,
				totalCompaniesCount: action.payload.totalCount,
				loading: false,
			};
		case ActionConstants.GET_COMPANIES_ERROR:
			return {
				...state,
				loading: false,
			};
		case ActionConstants.GET_CONNECTIONS_SUCCESS:
			let companiesList = action.payload.companiesList;
			const availableConnections = companiesList
				.filter(
					(company) =>
						company.connStatus === null || company.connStatus === "rejected"
				)
				.map((x) => ({ ...x.company }));
			const connectedList = companiesList
				.filter((company) => company.connStatus === "connected")
				.map((x) => ({ ...x.company, connection: x?.connection }));
			const pendingList = companiesList
				.filter((company) => company.connStatus === "waiting")
				.map((x) => ({ ...x.company, connection: x?.connection }));
			return {
				...state,
				unfilteredCompanies: companiesList,
				newConnections: availableConnections,
				connections: connectedList,
				pendingConnections: pendingList,
				// totalCount: action.payload.totalCount,
			};
		case ActionConstants.GET_CONNECTED_SUPPLIERS:
			return {
				...state,
				connectedSuppliers: [],
				loadingSuppliers: true,
			};
		case ActionConstants.GET_CONNECTED_SUPPLIERS_SUCCESS:
			return {
				...state,
				connectedSuppliers: action.payload.companiesList,
				loadingSuppliers: false,
			};
		case ActionConstants.GET_CONNECTED_SUPPLIERS_ERROR:
			return {
				...state,
				loadingSuppliers: false,
			};
		case ActionConstants.GET_MARKET_SECTORS_SUCCESS:
			return {
				...state,
				marketSectorsList: action.payload.marketSectors,
			};
		case ActionConstants.GET_COMPANY_TYPES_SUCCESS:
			return {
				...state,
				companyTypesList: action.payload.companyTypes,
			};
		case ActionConstants.GET_COMPANY_DETAILS_BY_ID:
			return {
				...state,
				companyDetails: {},
			};
		case ActionConstants.GET_COMPANY_DETAILS_BY_ID_SUCCESS: {
			return {
				...state,
				companyDetails: action.payload,
			};
		}
		case ActionConstants.GET_COMPANY_DETAILS_BY_ID_ERROR: {
			return {
				...state,
				companyDetails: {},
				companyDetailsError: action.payload,
			};
		}
		default:
			return state || {};
	}
};

export default companyReducer;
