import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Paper, Divider } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AppRoleContext from "context/AppRoleContext";
import { getPropertyData } from "selectors/propertySelectors";

import { selectAllFiles } from "selectors/documentsSelectors";
import AddNewProperty from "./AddNewProperty";
import { convertCamelToNormal } from "core/utils/appUtils";
import { get } from "lodash";
import HiddenOn from "components/Wrappers/HiddenOn";
import {
	hasAdminRole,
	hasAppRole,
	isAssignee,
	roleConfigKeys,
} from "core/utils/roleUtils";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import moment from "moment";
import SiteLocationMap from "./SiteLocationMap";
import { getPropertyById } from "actions/propertyActions";

export const ViewPropertyDetails = () => {
	const appRoleDetails = useContext(AppRoleContext);
	const dispatch = useDispatch();
	const params = useParams();
	const propertyData = useSelector(getPropertyData);

	const allFiles = useSelector(selectAllFiles);

	const [editProperty, setEditProperty] = React.useState(false);

	const getPropertyDetails = useCallback(() => {
		dispatch(getPropertyById(params.propertyId));
	}, [dispatch, params.propertyId]);

	useEffect(() => {
		getPropertyDetails();
	}, [getPropertyDetails]);

	useEffect(() => {
		dispatch(fetchFiles("Property", params.propertyId));
	}, [dispatch, params.propertyId]);

	return editProperty ? (
		<AddNewProperty
			onCancel={() => {
				setEditProperty(false);
				// getProjectDetails();
			}}
			propertyId={propertyData.propertyId}
			selectedProperty={{ data: propertyData }}
			title={" "}
		/>
	) : (
		<>
			<div style={{ background: "#F2F2F2" }}>
				<div className="m-2 mt-0">
					<HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
						<div
							className="d-flex flex-wrap mb-3"
							style={{ width: "100%", justifyContent: "space-between" }}
						>
							<Typography
								className="h4 mt-4 mb-4 col-lg-7 col-md-6 col-12"
								style={{ fontWeight: "700  " }}
							>
								Property Details
							</Typography>
							<div className="mt-4 col-lg-5 col-md-6 col-12 d-flex justify-content-between">
								<Typography
									className="body1"
									style={{ fontSize: "14px", color: "#8B8B8B" }}
								>
									<div>Last modified</div>
									<div>
										<span>
											{propertyData.modifiedBy},{" "}
											{moment(propertyData.modifiedDate).calendar()}
										</span>
									</div>
								</Typography>
								<HiddenOn
									isHidden={
										!hasAppRole(roleConfigKeys.EDIT_PROPERTY, appRoleDetails) ||
										isAssignee(propertyData.accessType)
									}
								>
									<button
										className="primaryButton"
										style={{ width: "5rem" }}
										onClick={() => {
											setEditProperty(true);
										}}
									>
										<Edit style={{ fontSize: "16px" }} /> Edit
									</button>
								</HiddenOn>
							</div>
						</div>
					</HiddenOn>
				</div>
				<div className="mt-3 d-flex flex-wrap full-width">
					<div className="col-lg-7 col-md-6 col-12">
						<RenderPropertyFields propertyData={propertyData} />
					</div>
					<div className="col-lg-5 col-md-6 col-12">
						<Paper style={{ marginRight: "0.75rem" }}>
							<div id="property-contant-section">
								<div
									id="contact-fields"
									className="m-2 d-flex flex-wrap justify-content-between"
								>
									{[
										{ key: "homeOwnerName", text: "Homeowner Name" },
										{
											key: "homeOwnerMobile",
											text: "Contact Number",
											formatter: (data) => (
												<a href={`tel:+1${data}`}>{`+1 ${data}`}</a>
											),
										},
										{ key: "address", text: "Address" },
									].map(({ key, text, formatter }) => {
										return (
											<div className="m-1 col-6">
												{propertyData[key] && propertyData[key] !== "" && (
													<Typography
														className="body1"
														style={{
															fontSize: "12px",
															fontWeight: 500,
															color: "#0B2240",
														}}
													>
														{text || convertCamelToNormal(key)}
													</Typography>
												)}

												<Typography
													style={{
														fontSize: "12px",
														wordBreak: "all",
														fontWeight: 700,
														color: "#0B2240",
													}}
													className="body1"
												>
													{formatter
														? formatter(propertyData[key])
														: propertyData[key]}
												</Typography>
											</div>
										);
									})}
								</div>
								<Divider
									className="divider"
									style={{ width: "90%", justifySelf: "center" }}
								/>
								<div id="map-location" className="mt-2">
									<SiteLocationMap propertyData={propertyData} />
								</div>
							</div>
						</Paper>
						<div
							id="contact-fields"
							className="m-2 d-flex flex-wrap justify-content-between"
						>
							{["createdBy", "createdDate"].map((key) => {
								return (
									<div className="m-1 col-6">
										{propertyData[key] && propertyData[key] !== "" && (
											<Typography
												className="body1"
												style={{
													fontSize: "12px",
													fontWeight: 500,
													color: "#0B2240",
												}}
											>
												{convertCamelToNormal(key)}
											</Typography>
										)}

										<Typography
											style={{
												fontSize: "12px",
												wordBreak: "all",
												fontWeight: 700,
												color: "#0B2240",
											}}
											className="body1"
										>
											{propertyData[key]}
										</Typography>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				{/* <HiddenOn isHidden={!hasAdminRole(appRoleDetails)}> */}
				<div className="d-flex flex-wrap col-12 pt-5 mb-5">
					<div className={"col-12 "}>
						{/* Pass the files, labels, upload status, and existing files to FilesList */}
						<FilesList files={allFiles} fileLabels={{}} uploadStatus={{}} />

						{/* </div> */}
					</div>
				</div>
				{/* </HiddenOn> */}
			</div>
		</>
	);
};

export const RenderPropertyFields = ({ propertyData }) => {
	const displayedFields = [
		{
			key: "propertyName",
			label: "Property Name",
			dataPath: "propertyName",
		},
		{
			key: "plotArea",
			label: "Plot Area (Sq.ft)",
			dataPath: "plotArea",
			formatter: (data) => (data ? `${data} Sq.Ft` : "NA"),
		},
		{
			key: "preliminarySalesPrice",
			label: "Preliminary Sales Price",
			dataPath: "preliminarySalesPrice",
			formatter: (data) => (data ? `$ ${data}` : "NA"),
		},
		{
			key: "totalProjects",
			label: "Total Projects",
			dataPath: "projectHeaderList.projectHeaders",
			formatter: (data) => {
				return data.length;
			},
		},
		{
			key: "propertyNotes",
			label: "Property Notes",
			dataPath: "propertyNotes",
		},
	];

	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 "}>
				<div className="d-flex flex-wrap col-12">
					{displayedFields.map((field) => {
						return (
							<div className="m-2" style={{ width: field.width || "40%" }}>
								<Typography
									className="body1"
									style={{ fontWeight: 500, color: "#0B2240" }}
								>
									{field.label}
								</Typography>

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{field.formatter
										? field.formatter(get(propertyData, field.dataPath, "NA"))
										: get(propertyData, field.dataPath, "NA") || "NA"}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ViewPropertyDetails;
