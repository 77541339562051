import { ActionConstants } from "constants";

const initialState = {
	projectList: [],
	projectData: {},
	projectsLoading: false,
	status: "success",
	text: "",
	showSnackbar: false,
	projectTimelines: [],
	projectTimelinesLoading: false,
	projectOverview: {},
	projectStatus: [],
	projectSuppliers: { totalCount: 0, suppliersList: [] },
};

const propertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.ADD_NEW_PROJECT_SUCCESS:
			return {
				...state,
			};
		case ActionConstants.ADD_NEW_PROJECT_ERROR:
			return {
				...state,
			};
		case ActionConstants.GET_PROJECTS:
			return {
				...state,
				projectsLoading: true,
				projectList: [],
			};
		case ActionConstants.GET_PROJECTS_SUCCESS:
			return {
				...state,
				projectList: action.payload.response,
				totalCount: action.payload.totalCount,
				projectsLoading: false,
			};
		case ActionConstants.GET_PROJECTS_ERROR:
			return {
				...state,
				projectList: [],
				projectsLoading: false,
			};
		case ActionConstants.GET_PROJECT_BY_ID_SUCCESS:
			return {
				...state,
				projectData: action.payload.response,
			};
		case ActionConstants.GET_PROJECT_BY_ID_ERROR:
			return {
				...state,
			};
		case ActionConstants.GET_TIMELINES_BY_PROJECT_ID:
			return {
				...state,
				projectTimelines: action.payload.response,
				projectTimelinesLoading: true,
			};
		case ActionConstants.GET_TIMELINES_BY_PROJECT_ID_SUCCESS:
			return {
				...state,
				projectTimelines: action.payload.response,
				projectTimelinesLoading: false,
			};
		case ActionConstants.GET_TIMELINES_BY_PROJECT_ID_ERROR:
			return {
				...state,
				projectTimelinesLoading: false,
			};
		case ActionConstants.GET_PROJECT_OVERVIEW_SUCCESS:
			return {
				...state,
				projectOverview: action.payload.response,
			};
		case ActionConstants.GET_PROJECT_STATUS_SUCCESS:
			return {
				...state,
				projectStatus: action.payload.response,
			};
		case ActionConstants.GET_PROJECT_OVERVIEW_ERROR:
			return {
				...state,
				projectOverview: {},
			};
		case ActionConstants.GET_SUPPLIERS_BY_PROJECT_ID_SUCCESS:
			return {
				...state,
				projectSuppliers: action.payload.response,
			};
		default:
			return state || {};
	}
};

export default propertyReducer;
