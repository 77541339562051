import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import { decodeToken } from "core/utils/appUtils";
import get from "lodash/get";
import { getCompanyByIdAction } from "./companiesActions";
import { loadUserDetails } from "./login_registrationActions";

export const saveBusinessProfile = (formData, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.BUSINESS_PROFILE,
				payload: {
					loading: true,
				},
			});
			request({
				method: formData.companyId ? "PUT" : "POST",
				url: `${server_url}/company${
					formData.companyId ? `/${formData.companyId}` : ""
				}`,
				body: {
					...formData,
					serviceAreas: formData.serviceAreas.join(","),
					suppliersAndServices: formData.suppliersAndServices.join(","),
					ServiceAreaStates: "",
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.BUSINESS_PROFILE_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Profile Updated Successfully",
							loading: false,
						},
					});
					onSuccess({
						status: "success",
						message: "Company Data updated Successfully",
					});
					dispatch(getCompanyByIdAction(response.data.companyId));
					if (!formData.companyId) {
						dispatch(updateProfileWithCompany(response.data.companyId, "PUT"));
					}
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.BUSINESS_PROFILE_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					onSuccess({
						status: "error",
						message: "Error in Saving Data, please try again",
					});

					resolve();
				});
		});
	};
};

export const updateProfileWithCompany = (companyId, type = "POST") => {
	const userDetails = decodeToken();

	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.UPDATE_USER_PROFILE,
				payload: {
					loading: true,
				},
			});
			request({
				method: type,
				url: `${server_url}/user/${userDetails?.nameid}`,
				body: {
					companyId: companyId,
					email: userDetails.unique_name,
					userId: userDetails?.nameid,
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.UPDATE_USER_PROFILE_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Profile Updated Successfully",
							loading: false,
						},
					});
					dispatch(loadUserDetails());

					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.UPDATE_USER_PROFILE_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});

					resolve();
				});
		});
	};
};
