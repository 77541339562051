import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
} from "@mui/material";
import {
  ArrowBack,
  CorporateFare,
  Delete,
  FilterList,
  FormatListBulletedSharp,
  GridViewSharp,
  Assignment as AssignmentIcon,
  Engineering,
} from "@mui/icons-material";
import { getProperties } from "selectors/fileManagerSelectors";
import { propertyGetAll } from "actions/propertyActions";
import { selectAllFiles, selectIsFetching } from "selectors/documentsSelectors";
import { deleteFile, fetchFiles } from "actions/documentsActions";
import ImageIcon from "@mui/icons-material/Image";
import NoteIcon from "@mui/icons-material/Note";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import FileManagerList from "components/FileManagerList/FileManager";
import FileGrid from "./FileGrid";
import { palette } from "themes";
import FolderMinus from "../../assets/properties/Folder-minus.png";
import FolderPlus from "../../assets/properties/Folder-plus.png";
import { Button } from "react-bootstrap";
import Filter from "./Filter";
import { fetchJobsByProjectId } from "actions/fileManagerActions";

const FileManager = () => {
  const dispatch = useDispatch();
  const prop = useSelector(getProperties);
  const allFiles = useSelector(selectAllFiles);
  const isFetching = useSelector(selectIsFetching);
  const paginatedProps = prop.propertyList;
  const loading = prop?.loading || false;
  const error = prop?.error || null;
  const jobs_loading = prop?.jobs_loading || false;
  const jobsData = prop?.jobs;
  const paginatedHasMore = prop?.hasMore;

  const [jobs, setJobs] = useState(jobsData || []);
  const [isJobsLoading, setIsJobsLoading] = useState(jobs_loading);
  const [filesBelongsTo, setFilesBelongsTo] = useState("Property");
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [listTitle, setListTitle] = useState("");
  const [viewMode, setViewMode] = useState("list");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasMore, setHasMore] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const pageSize = 15;
  const [documentTypes, setDocumentTypes] = useState([]);
  const listRef = useRef(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [makeDelete, setMakeDelete] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const toggleView = (mode) => setViewMode(mode);

  const fetchProperties = (page) => {
    dispatch(propertyGetAll({ pageNumber: page, pageSize: pageSize }));
  };

  useEffect(() => {
    fetchProperties(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (paginatedProps) {
      const uniqueProperties = new Set(properties.map((p) => p.propertyId));
      const newProperties = paginatedProps.filter(
        (p) => !uniqueProperties.has(p.propertyId)
      );
      setProperties((prevProperties) => [...prevProperties, ...newProperties]);
    }
    setHasMore(paginatedHasMore);
  }, [paginatedProps]);

  useEffect(() => {
    if (properties.length > 0) {
      const firstProperty = properties[0];
      setSelectedProperty(firstProperty.propertyId);
      setListTitle(firstProperty.propertyName);
    }
  }, [properties]);

  useEffect(() => {
    setSelectedFiles([]);
    if (selectedProject && filesBelongsTo === "Project") {
      dispatch(fetchFiles("Project", selectedProject));
    } else if (selectedProperty && filesBelongsTo === "Property") {
      dispatch(fetchFiles("Property", selectedProperty));
    } else if (selectedJob && filesBelongsTo === "Job") {
      dispatch(fetchFiles("Job", selectedJob));
    }
  }, [dispatch, selectedProject, selectedProperty, selectedJob]);

  useEffect(() => {
    setFilteredFiles(allFiles);
    const uniqueDocumentTypes = [];
    allFiles.forEach((file) => {
      if (file.documentTypeId) {
        if (uniqueDocumentTypes[file.documentTypeId]) {
          uniqueDocumentTypes[file.documentTypeId].count += 1;
        } else {
          uniqueDocumentTypes[file.documentTypeId] = {
            text: file.documentType.documentType,
            id: file.documentTypeId,
            count: 1,
          };
        }
      }
    });

    setDocumentTypes(uniqueDocumentTypes);
  }, [allFiles]);

  const handleToggle = (propertyId, name) => {
    setSelectedProperty(propertyId);
    setSelectedProject(null);
    setSelectedJob(null);
    setListTitle(name);
    setFilesBelongsTo("Property");
  };

  const handleToggleSelected = useCallback(
    (projId, name) => {
      setSelectedProject(projId);
      setSelectedJob(null);
      setListTitle(name);
      setFilesBelongsTo("Project");
      dispatch(fetchJobsByProjectId({ projectId: projId }));
    },
    [dispatch]
  );

  const handleSelectedJob = (jobId, name) => {
    setSelectedJob(jobId);
    setListTitle(name);
    setFilesBelongsTo("Job");
  };

  const promptDelete = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => {
    setShowConfirmModal(false);
    if (makeDelete) {
      handleDelete();
      setMakeDelete(false);
    }
  }, [makeDelete]);

  useEffect(() => {
    setJobs(jobsData);
    setIsJobsLoading(jobs_loading);
  }, [jobsData, jobs_loading]);

  const handleDelete = async () => {
    try {
      const promiseAll = selectedFiles.map((fileId) =>
        dispatch(deleteFile(fileId))
      );
      await Promise.all(promiseAll);
      showSnackbar("success", "Files deleted successfully");
      setSelectedFiles([]);
      setTimeout(async () => {
        if (selectedProperty && filesBelongsTo === "Property") {
          console.log("Deletion success, making fetch call for property");
          await dispatch(fetchFiles("Property", selectedProperty));
        } else if (selectedProject && filesBelongsTo === "Project") {
          await dispatch(fetchFiles("Project", selectedProject));
        } else if (selectedJob && filesBelongsTo === "Job") {
          await dispatch(fetchFiles("Job", selectedJob));
        }
      }, 2000); // 2 seconds timeout
    } catch (error) {
      showSnackbar("error", "Error deleting files");
    }
  };

  const handleSelectAll = () => {
    if (selectedFiles.length === allFiles.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(allFiles.map((row) => row.documentId));
    }
  };

  const handleOpenFilterDrawer = () => {
    setIsFilterDrawerOpen(true);
  };

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen((prev) => !prev);
  };

  const closeFilterDrawer = (e) => {
    setIsFilterDrawerOpen(false);
  };

  const hideFilterDrawer = (e) => {
    const drawer = document.querySelector(".filter-drawer");
    const filterIcon = document.querySelector(".filter-icon");
    const filterIconText = document.querySelector(".filter-icon-text");
    const filterIconIc = document.querySelector(".filter-icon-ic");

    if (
      drawer &&
      !drawer.contains(e.target) &&
      filterIcon &&
      !filterIcon.contains(e.target) &&
      filterIconText &&
      !filterIconText.contains(e.target) &&
      filterIconIc &&
      !filterIconIc.contains(e.target)
    ) {
      setIsFilterDrawerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", hideFilterDrawer);
    return () => {
      document.removeEventListener("click", hideFilterDrawer);
    };
  }, []);

  const handleScroll = (e) => {
    if (!hasMore && !loading) {
      console.log("No more records to load.");
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const showSnackbar = (type, message) => {
    setSnackbarState({ open: true, type, message });
  };

  const handleFilterChange = (selectedFilters) => {
    if (selectedFilters.length === 0) {
      setFilteredFiles(allFiles);
    } else {
      const filtered = allFiles.filter((file) =>
        selectedFilters.includes(file.documentTypeId.toString())
      );
      setFilteredFiles(filtered);
    }
    closeFilterDrawer();
  };

  const PropertyList = useMemo(
    () => (
      <List>
        {properties.map((property) => (
          <Box key={property.propertyId}>
            <ListItem
              button
              onClick={() =>
                handleToggle(property.propertyId, property.propertyName)
              }
            >
              <img
                src={
                  selectedProperty === property.propertyId
                    ? FolderMinus
                    : FolderPlus
                }
                alt="folder"
                style={{ width: "20px", height: "20px" }}
              />
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      marginLeft: "5px",
                      color:
                        selectedProperty === property.propertyId
                          ? "#345dae"
                          : "#212529",
                    }}
                  >
                    {property.propertyName}
                  </Typography>
                }
              />
            </ListItem>
            <Collapse
              in={selectedProperty === property.propertyId}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {property?.projectHeaderList?.projectHeaders.map((item) => (
                  <React.Fragment key={item.projectId}>
                    <ListItem
                      sx={{ pl: 4, cursor: "pointer" }}
                      onClick={() =>
                        handleToggleSelected(item.projectId, item.projectName)
                      }
                    >
                      <Engineering
                        sx={{
                          fontSize: "21px",
                          color:
                            selectedProject === item.projectId
                              ? palette.bluePrimary
                              : "inherit",
                        }}
                      />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              marginLeft: "5px",
                              color:
                                selectedProject === item.projectId
                                  ? "#345dae"
                                  : "#212529",
                            }}
                          >
                            {item.projectName}
                          </Typography>
                        }
                      />
                    </ListItem>

                    {isJobsLoading ? (
                      <ListItem
                        key={"job_loading"}
                        sx={{ pl: 2, cursor: "wait" }}
                      >
                        Loading Jobs...
                      </ListItem>
                    ) : null}

                    <Collapse
                      in={selectedProject === item.projectId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding sx={{ pl: 4 }}>
                        {jobs?.map((job) => (
                          <ListItem
                            key={job.jobId}
                            sx={{ pl: 2, cursor: "pointer" }}
                            onClick={() =>
                              handleSelectedJob(job.jobId, job.jobName)
                            }
                          >
                            <AssignmentIcon
                              sx={{
                                fontSize: "21px",
                                color:
                                  selectedJob === job.jobId
                                    ? palette.bluePrimary
                                    : "inherit",
                              }}
                            />
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                    color:
                                      selectedJob === job.jobId
                                        ? "#345dae"
                                        : "#212529",
                                  }}
                                >
                                  {job.jobName}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
        {loading && (
          <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
        )}
        {!hasMore && !loading && (
          <Typography sx={{ textAlign: "center" }}>
            No more records to load.
          </Typography>
        )}
      </List>
    ),
    [
      properties,
      selectedProperty,
      selectedProject,
      selectedJob,
      loading,
      hasMore,
      handleToggleSelected,
      isJobsLoading,
      jobs,
    ]
  );

  return (
    <div style={{ backgroundColor: palette.white }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarState({ ...snackbarState, open: false })}
      >
        <Alert
          onClose={() => setSnackbarState({ ...snackbarState, open: false })}
          severity={snackbarState.type}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure to delete selected file
            {selectedFiles.length > 1 ? "s" : ""}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setMakeDelete(true)} variant="danger">
            Sure
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="d-flex align-items-center"
        style={{
          backgroundColor: palette.white,
          borderBottom: "1px solid #CCC",
        }}
      >
        <ArrowBack
          style={{ height: "20px", width: "23px", color: "#345DAE" }}
          className="pointer mt-2"
          onClick={() => window.history.back()}
        />
        <Typography
          className="h3 mt-3"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "15rem",
          }}
        >
          File Manager
        </Typography>
      </div>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            padding: "16px",
            height: "100vh",
            overflowY: "auto",
            width: "20%",
          }}
          onScroll={handleScroll}
          ref={listRef}
        >
          <Typography variant="body" sx={{ borderBottom: "1ps solid #CCC" }}>
            <strong>Property</strong>
          </Typography>
          <hr />
          {PropertyList}
        </Box>

        {isFetching ? (
          <Box
            sx={{
              width: "80%",
              backgroundColor: palette.white,
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Typography sx={{ textAlign: "center", justifyContent: "center" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "80%", backgroundColor: palette.white }}>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="ms-3 mt-2 justify-content-between"
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {listTitle}
              </Typography>
              <div className="col-7 d-flex justify-content-end">
                <div>
                  <IconButton
                    onClick={() => toggleView("list")}
                    sx={{
                      color: viewMode === "list" ? "#FFF" : "#345DAE",
                      backgroundColor:
                        viewMode === "list" ? "#345DAE" : "#f5f5f5",
                      borderRadius: 0,
                    }}
                  >
                    <FormatListBulletedSharp sx={{ fontSize: "21px" }} />
                  </IconButton>
                  <IconButton
                    onClick={() => toggleView("grid")}
                    sx={{
                      color: viewMode === "grid" ? "#FFF" : "#345DAE",
                      backgroundColor:
                        viewMode === "grid" ? "#345DAE" : "#f5f5f5",
                      borderRadius: 0,
                    }}
                  >
                    <GridViewSharp sx={{ fontSize: "21px" }} />
                  </IconButton>
                </div>

                <div
                  className="pointer d-flex me-3 filter-icon"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#345DAE",
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "auto",
                    justifyContent: "end",
                  }}
                  onClick={toggleFilterDrawer}
                >
                  <Typography
                    variant="body1"
                    className="h6 me-2 filter-icon-text"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Barlow",
                      fontWeight: "700",
                    }}
                  >
                    Filters
                  </Typography>
                  <FilterList
                    style={{ fontSize: "1.5rem" }}
                    className={"filter-icon-ic"}
                  />
                </div>
              </div>
            </Box>

            {allFiles.length > 0 && (
              <div
                data-testid={"properties-select-div"}
                className="d-flex"
                style={{ alignItems: "center" }}
              >
                <FormGroup className="ms-3">
                  <FormControlLabel
                    disabled={true}
                    control={<Checkbox sx={{ fontSize: "18px" }} />}
                    label={
                      selectedFiles.length === allFiles.length
                        ? "Deselect All"
                        : "Select All"
                    }
                    onChange={handleSelectAll}
                    checked={selectedFiles.length === allFiles.length}
                  />
                </FormGroup>
                <IconButton
                  onClick={promptDelete}
                  className="ms-1"
                  disabled={selectedFiles.length === 0}
                >
                  <Delete style={{ fontSize: "20px" }} />
                </IconButton>
                <Typography
                  variant="body1"
                  sx={{ color: palette.blackPrimary }}
                >
                  Delete
                </Typography>
              </div>
            )}
            {viewMode === "list" ? (
              <FileManagerList
                title={listTitle}
                showToolbar
                files={filteredFiles}
                fileLabels={{}}
                uploadStatus={{}}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            ) : (
              <FileGrid files={filteredFiles} />
            )}
          </Box>
        )}
      </Box>
      <Drawer
        open={isFilterDrawerOpen}
        anchor="right"
        variant="persistent"
        PaperProps={{
          sx: {
            marginTop: "14%",
            width: "20%",
            position: "absolute",
          },
        }}
        className="filter-drawer"
      >
        <Filter
          options={documentTypes}
          onFilterChange={handleFilterChange}
          onClose={closeFilterDrawer}
        />
      </Drawer>
    </div>
  );
};

export default FileManager;
