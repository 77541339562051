import { Delete } from "@mui/icons-material";
import {
	Autocomplete,
	CircularProgress,
	FormLabel,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { addUpdateJob, fetchJobAssigneesListAction } from "actions/jobActions";
import HiddenOn from "components/Wrappers/HiddenOn";
import AppRoleContext from "context/AppRoleContext";
import { isJobManager, isSystemAdmin } from "core/utils/roleUtils";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssigneeList } from "selectors/jobSelectors";

export const JobAssignment = ({
	jobId,
	jobData,
	handleClose,
	defaultPayload,
}) => {
	console.log("Check the JobData : ", jobData);
	const dispatch = useDispatch();
	const { assignedTo } = useSelector(getAssigneeList);
	const appRoleDetails = useContext(AppRoleContext);

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const [selectedAssignee, updateSelectedAssignee] = React.useState(null);

	useEffect(() => {
		dispatch(fetchJobAssigneesListAction());
	}, [dispatch, jobId]);

	const updateAssignee = (selectedAssignee) => {
		dispatch(
			addUpdateJob({
				formData: {
					...jobData,
					companyId: selectedAssignee,
				},
				onSuccess: handleClose,
				payload: defaultPayload,
			})
		);
	};

	return (
		<>
			<div className={`form-field-wrapper bt-3 `}>
				<div className="d-flex justify-content-between">
					<Autocomplete
						onChange={(_, value) => {
							// get input value from the autocomplete field
							const inputValue = value?.companyId;
							updateSelectedAssignee(inputValue);
						}}
						id={"job-assignee"}
						key={"companyId"}
						// value={jobData.companyId || ""}
						className="form-field "
						open={autoCompleteOpen}
						onOpen={() => setAutoCompleteOpen(true)}
						onClose={() => setAutoCompleteOpen(false)}
						options={isSystemAdmin(appRoleDetails) ? [] : assignedTo || []}
						isOptionEqualToValue={(option, value) => {
							return option.companyId === value.companyId;
						}}
						filterOptions={(x) => {
							return x;
						}}
						getOptionLabel={(option) => {
							return `${option.companyName}`.trim();
						}}
						renderOption={(props, option) => {
							const { key, ...optionProps } = props;
							return (
								<li key={option.companyId} {...optionProps}>
									{`${option.companyName}`.trim()}
								</li>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								className="form-field"
								onChange={(event) => {
									const textLen = event.target.value.length;
									if (textLen >= 3) {
										// Trigger search/fetch
										console.log("Fetching the record");
									}
								}}
							/>
						)}
					/>
					<button
						className={"secondaryButton ms-3"}
						onClick={() => {
							updateAssignee(selectedAssignee);
						}}
					>
						Assign
					</button>
				</div>
				<div className="assigned-users-list mt-4">
					<table className="mt-3">
						<thead>
							<tr>
								<th>Company</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{`${jobData.company?.companyName}`}</td>
								<td>
									<HiddenOn isHidden={!isJobManager(appRoleDetails)}>
										<IconButton
											onClick={() => {
												updateAssignee("");
												updateSelectedAssignee("");
											}}
										>
											<Delete style={{ fontSize: "1rem" }} />
											<Typography variant="body1">Remove</Typography>
										</IconButton>
									</HiddenOn>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default JobAssignment;
