import { Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { fetchTaskById, taskDelete } from "actions/taskActions";
import { getMomentDate } from "core/utils/appUtils";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaskData } from "selectors/taskSelectors";
import AddEditTask from "pages/task/AddNewTask";
import { TaskStatus } from "./addTaskUtils";

export const ViewTask = ({ task, job, projectId, onCancel = null }) => {
	const dispatch = useDispatch();
	const taskData = useSelector(getTaskData);
	const [editTask, setEditTask] = React.useState(false);

	console.log("Check the taskData : ", taskData, task);

	useEffect(() => {
		dispatch(fetchTaskById(task.id));
	}, [dispatch, task.id]);

	const getFormattedDate = (date) => getMomentDate(date);

	const displayedFields = [
		{
			key: "taskName",
			label: "Task Name",
			dataPath: "taskName",
		},
		{
			key: "taskStatus",
			label: "Task Status",
			dataPath: "taskStatus",
			formatter: (status) => <TaskStatus status={status.statusName} />,
		},
		{
			key: "assignedTo",
			label: "Assigned To",
			dataPath: "assignedTo",
		},
		{
			key: "plannedStartDate",
			label: "Planned Start Date",
			dataPath: "plannedStartDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "plannedEndDate",
			label: "Planned End Date",
			dataPath: "plannedEndDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "actualStartDate",
			label: "Actual Start Date",
			dataPath: "actualStartDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "actualEndDate",
			label: "Actual End Date",
			dataPath: "actualEndDate",
			formatter: (data) => getFormattedDate(data),
		},

		{
			key: "taskNotes",
			label: "Task Notes",
			dataPath: "taskNotes",
		},
	];

	return editTask ? (
		<AddEditTask
			onCancel={() => {
				setEditTask(false);
				dispatch(fetchTaskById(task.id));
			}}
			selectedTask={{ data: taskData }}
			parentId={{ jobId: job.jobId }}
			taskId={task.id}
			jobId={job.jobId}
			projectId={job.projectId}
			title={`Edit Details for "${taskData.taskName}"`}
		/>
	) : (
		<>
			<div className="d-flex justify-content-between">
				<Typography className="h3">{taskData.taskName}</Typography>
				<IconButton>
					<Close
						onClick={() => {
							if (onCancel) {
								onCancel();
							}
						}}
					/>
				</IconButton>
			</div>
			<div className="d-flex justify-content-end">
				<button
					className="secondaryButton icon-btn ms-2"
					style={{ width: "auto" }}
					onClick={() => {
						setEditTask(true);
					}}
				>
					Edit
					<EditOutlined style={{ fontSize: "14px" }} />
				</button>

				<button
					className="secondaryButton delete-btn ms-2"
					style={{ width: "auto" }}
					onClick={() => {
						dispatch(
							taskDelete({
								taskId: task.id,
								params: { taskId: task.id, projectId, jobId: job.jobId },
								projectId: projectId,
								jobId: job.jobId,
								onSuccess: () => {
									onCancel();
								},
							})
						);
					}}
				>
					Delete
					<DeleteOutline style={{ fontSize: "14px" }} />
				</button>
			</div>
			<div className="d-flex flex-wrap col-12 ">
				<div className={"col-12 mt-4"}>
					<div className="d-flex flex-wrap col-12">
						{displayedFields.map((field) => {
							return (
								<div className="col-4 mb-3">
									<Typography
										className="body1"
										style={{ fontWeight: 500, color: "#0B2240" }}
									>
										{field.label}
									</Typography>

									<Typography
										style={{
											wordBreak: "all",
											fontWeight: 700,
											color: "#0B2240",
										}}
										className="body1"
									>
										{field.formatter
											? field.formatter(get(taskData, field.dataPath, "NA"))
											: get(taskData, field.dataPath, "NA") || "NA"}
									</Typography>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewTask;
