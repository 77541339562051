import { ArrowBack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { getCompanyByIdAction } from "actions/companiesActions";
import RenderDataFields from "components/DataFields/RenderDataFields";
import ErrorMessage from "components/NoResults/ErrorMessage";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getCompanyDetailsErrorSelector,
	getCompanyDetailsSelector,
} from "selectors/companiesSelectors";

export const ViewCompanyDetails = ({
	handleClose = () => window.history.back(),
}) => {
	const dispatch = useDispatch();
	const companyDetails = useSelector(getCompanyDetailsSelector);
	const companyDetailsError = useSelector(getCompanyDetailsErrorSelector);
	const params = useParams();

	const getCompanyDetails = useCallback(() => {
		dispatch(getCompanyByIdAction(params.companyId, {}));
	}, [dispatch, params.companyId]);

	useEffect(() => {
		getCompanyDetails();
	}, [getCompanyDetails]);

	const displayFields = [
		{
			label: "Company Name",
			dataPath: "companyName",
		},
		{
			label: "Company Type",
			dataPath: "companyType",
		},
		{
			label: "Company Profile",
			dataPath: "companyProfile",
		},
		{
			label: "City",
			dataPath: "city",
		},
		{
			label: "State",
			dataPath: "state",
		},
		{
			label: "Zip Code",
			dataPath: "zipcode",
		},
		{
			label: "Mobile Number",
			dataPath: "mobileNumber",
		},
		{
			label: "Landline Number",
			dataPath: "landlineNumber",
		},
		{
			label: "Market Sectors",
			dataPath: "marketSectors",
		},
		{
			label: "Service Areas",
			dataPath: "serviceAreas",
		},
		{
			label: "Suppliers and Services",
			dataPath: "suppliersAndServices",
		},
		{
			label: "Website",
			dataPath: "website",
		},
		{
			label: "Created By",
			dataPath: "createdBy",
		},
		{
			label: "Created Date",
			dataPath: "createdDate",
		},
		{
			label: "Modified By",
			dataPath: "modifiedBy",
		},
		{
			label: "Modified Date",
			dataPath: "modifiedDate",
		},
	];

	if (
		companyDetailsError &&
		companyDetailsError.message &&
		companyDetailsError.status === "error"
	) {
		return (
			<ErrorMessage
				message={companyDetailsError.message}
				customFooter={
					<>
						<div className="mt-3 d-flex justify-content-center">
							<Typography className="link-text" onClick={handleClose}>
								Go back to view Companies
							</Typography>
						</div>
					</>
				}
			/>
		);
	}
	return (
		<>
			<div>
				<div className="d-flex align-items-center">
					<ArrowBack
						style={{ height: "20px", width: "23px", color: "#345DAE" }}
						className="pointer mt-2"
						onClick={() => handleClose()}
					/>
					<Typography
						className="h3 mt-3 ms-2"
						style={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							width: "15rem",
						}}
					>
						{companyDetails?.companyName}
					</Typography>
				</div>
				<div>
					<RenderDataFields data={companyDetails} fields={displayFields} />
				</div>
			</div>
		</>
	);
};

export default ViewCompanyDetails;
