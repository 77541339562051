import React, { useState } from "react";
import { IconButton } from "@mui/material";

import { TextField, Paper, InputAdornment } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import "./ChatAssistant.css";

import voiceAssistantImg from "assets/app/voice-assistant.png";

import { useVoiceAssistantState } from "context/VoiceAssistantContext";
import ListeningAnimation from "components/VoiceAssistantLayout/ListeningAnimation/ListeningAnimation";

let chatMessagesHistory = [
  {
    sender: "bot",
    text: "How may I be of help to you?",
    timestamp: Date.now(),
  },
];

const ChatAssistant = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  const { VoiceAssistantState, setVoiceAssistantState } =
      useVoiceAssistantState();

  const handleInputSend = () => {
    if (inputMessage.trim()) {
      handleSend("user", inputMessage);

      setInputMessage("");
    }
  };

  const handleSend = (sender, text) => {
    if (text.trim()) {
      chatMessagesHistory.push({ sender, text, timestamp: Date.now() });

      setChatMessages([...chatMessagesHistory]);
    }
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleInputSend();
    }
  };

  return (
    <div className="chat-container">
      {/* Chat Body */}
      <div className="chat-body">
        {chatMessages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.sender === "bot"
                ? "chat-message-bot"
                : "chat-message-user"
            }`}
          >
            <Paper className="chat-bubble">
              <span className="chat-message-text">{message.text}</span>
              <span className="chat-message-timestamp">
                {new Date(message.timestamp).toLocaleString()}
              </span>
            </Paper>
          </div>
        ))}
      </div>

      {/* Input Section */}
      <div className="chat-input">
        <TextField
          variant="outlined"
          placeholder="Type your message here"
          className="chat-input-field"
          fullWidth
          value={inputMessage}
          onKeyDown={keyPress}
          onChange={(e) => setInputMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleInputSend}>
                  <SendIcon className="chat-send-icon" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div >
          {VoiceAssistantState.isListening && <ListeningAnimation />}
				</div>

        <IconButton className="chat-voice-button" onClick={() => {
          setVoiceAssistantState({
            ...VoiceAssistantState,
            isListening: !VoiceAssistantState.isListening,
          });
        }}>
          <img
            src={voiceAssistantImg}
            alt="Voice Assistant"
            className="chat-voice-icon"
          />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatAssistant;
