export const APP_BUILDER_AI_ACTION_CONSTANTS = {
	GET_BUILDER_AI_COMMANDS_DATA: "GET_BUILDER_AI_COMMANDS_DATA",
	GET_BUILDER_AI_COMMANDS_DATA_SUCCESS: "GET_BUILDER_AI_COMMANDS_DATA_SUCCESS",
	GET_BUILDER_AI_COMMANDS_DATA_ERROR: "GET_BUILDER_AI_COMMANDS_DATA_ERROR",
	GET_BUILDER_AI_MICROPHONE_ACCESS: "GET_BUILDER_AI_MICROPHONE_ACCESS",
	GET_BUILDER_AI_COMMANDS_QUESTIONS_DATA_SUCCESS: "GET_BUILDER_AI_COMMANDS_QUESTIONS_DATA_SUCCESS",
	SET_BUILDER_AI_COMMANDS_QUESTIONS_INITIAL_DATA: "SET_BUILDER_AI_COMMANDS_QUESTIONS_INITIAL_DATA",
};


export const APP_BUILDER_AI_FLOW_STEPS_CONSTANTS = {
	GET_BUILDER_AI_COMMANDS_DATA: "GET_BUILDER_AI_COMMANDS_DATA",
	GET_BUILDER_AI_COMMANDS_DATA_SUCCESS: "GET_BUILDER_AI_COMMANDS_DATA_SUCCESS",
	GET_BUILDER_AI_COMMANDS_DATA_ERROR: "GET_BUILDER_AI_COMMANDS_DATA_ERROR",
};
