import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { bulkUploadFiles, fetchFiles } from "actions/documentsActions";
import FilesList from "components/FileList/FileList";
import FileUpload from "components/FileUpload/FileUpload";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllFiles,
  selectIsUploading,
} from "selectors/documentsSelectors";

export const FileManager = ({ projectId }) => {
  //Files Upload and Retrival
  const dispatch = useDispatch();
  const [filesState, setFilesState] = React.useState([]);
  const [fileLabelsState, setFileLabelsState] = React.useState({});
  const [documentTypeState, setDocumentTypeState] = React.useState("");

  const existingFiles = useSelector(selectAllFiles);

  const isUploading = useSelector(selectIsUploading);

  const fetchExistingDocs = useCallback(() => {
    dispatch(fetchFiles("Project", projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    fetchExistingDocs();
  }, [fetchExistingDocs]);

  const handleUploadFiles = (onUploadSuccessCallback) => {
    dispatch(
      bulkUploadFiles(
        filesState,
        fileLabelsState,
        documentTypeState,
        projectId,
        "Project",
        () => {
          fetchExistingDocs();
          onUploadSuccessCallback();
        }
      )
    );
  };

  return (
    <div className="file-manager-container">
      <div
        className="file-manager-upload-documents"
        data-testid="fm-upload-documents"
      >
        <Typography className="h4 ms-3 mt-3">
          <strong>Upload File</strong>s
        </Typography>
        <FileUpload
          setFilesState={setFilesState}
          setFileLabelsState={setFileLabelsState}
          setDocumentTypeState={setDocumentTypeState}
          onUploadButtonClick={handleUploadFiles}
          showUploadCancelButtons
        />
        {isUploading && (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="body1">Uploading Documents</Typography>
              <LinearProgress size="3rem" />
            </Box>
          </Grid>
        )}
      </div>

      <div className="file-manager-documents-list">
        <FilesList
          files={existingFiles || []}
          fileLabels={fileLabelsState}
          uploadStatus={{}}
        />
      </div>
    </div>
  );
};
