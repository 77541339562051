import { fetchSuppliersByProjectId } from "actions/projectActions";
import DataGridTable from "components/Table/DataGrid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSuppliers } from "selectors/projectSelector";

export const ProjectSuppliers = ({ projectId }) => {
	const dispatch = useDispatch();
	const { totalCount, suppliersList } = useSelector(getProjectSuppliers);

	console.log("Check the Suppliers Details ", totalCount, suppliersList);
	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	useEffect(() => {
		// fetch project suppliers
		dispatch(
			fetchSuppliersByProjectId(projectId, {
				...pageModel,
				pageNumber: pageModel.page + 1,
			})
		);
	}, [projectId, pageModel, dispatch]);

	const columns = [
		{
			field: "companyName",
			headerName: "Company Name",
			width: 200,
		},
		{
			field: "companyType",
			headerName: "Company Type",
			width: 200,
		},
		{
			field: "marketSectors",
			headerName: "Operating Sectors",
			width: 200,
		},
		{
			field: "suppliersAndServices",
			headerName: "Services",
			width: 200,
		},
		{
			field: "serviceAreas",
			headerName: "Service Locations",
			width: 200,
		},
	];

	return (
		<>
			<div className="p-2 mt-4">
				<DataGridTable
					columns={columns}
					data={suppliersList}
					defineRowId={(row) => row.companyId}
					checkboxSelection={false}
					showToolbar={false}
					pagination={totalCount > 10}
					totalCount={totalCount}
					paginationModel={pageModel}
					onPaginationChange={(props) => {
						setPageModel(props);
					}}
				/>
			</div>
		</>
	);
};

export default ProjectSuppliers;
