const initialState = {
  configId: 0,
  dateFormat: "",
  prodUrlUi: "",
  devUrlUi: "",
  prodUrlAppl: "",
  devUrlAppl: "",
  startOfWeek: "",
  voiceAssistantEnabled: false,
  createdBy: "",
  createdDate: "",
  modifiedBy: "",
  modifiedDate: "",
};

const appConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONFIG_SUCCESS":
      return { ...state, ...action.payload };
    case "UPDATE_CONFIG":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default appConfigReducer;
