import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const Filter = ({ options = [], onFilterChange, onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFilters((prev) =>
      checked ? [...prev, value] : prev.filter((id) => id !== value)
    );
  };

  const filteredOptions = options.filter((option) =>
    option?.text?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  //['1', '2'], [{id: '1', text: 'abc', count: 10}, {id: '2', text: 'xyz', count: 20}]

  useEffect(() => {
    let totalC = 0;
    filteredOptions.forEach((f) => {
      if (selectedFilters.includes(f.id.toString())) {
        totalC += f.count;
      }
    });
    setTotalCnt(totalC);
  }, [selectedFilters]);

  // useEffect(() => {
  //   onFilterChange(selectedFilters);
  // }, [selectedFilters, onFilterChange]);

  return (
    <Box sx={{ ml: "5%" }}>
      <Grid sx={{ mt: "5%" }} className="d-flex justify-content-between">
        <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
          Filters
        </Typography>
        <CloseIcon
          sx={{ cursor: "pointer", fontSize: "18px", marginRight: "2%" }}
          onClick={onClose}
        />
      </Grid>
      <Grid>
        <Typography sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}>
          Document Types
        </Typography>
      </Grid>
      <Divider />
      <Grid>
        <TextField
          size="small"
          placeholder="Search here..."
          name="name"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ fontSize: "14px", mt: "10px" }}
        />
      </Grid>
      <FormGroup>
        {filteredOptions.map((f) => (
          <FormControlLabel
            key={f.id}
            labelPlacement="start"
            sx={{
              justifyContent: "space-between",
              whiteSpace: "nowrap",
              fontSize: "14px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              margin: "0",
            }}
            control={
              <Checkbox
                sx={{ p: 0 }}
                value={f.id}
                onChange={handleFilterChange}
              />
            }
            label={f.text + `(${f.count})`}
          />
        ))}
      </FormGroup>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20%",
        }}
      >
        <Grid>
          <Button
            variant="contained"
            size="medium"
            sx={{ fontSize: "15px" }}
            onClick={() => onFilterChange(selectedFilters)}
          >
            Show {totalCnt} Documents
          </Button>
        </Grid>
        <Grid sx={{ mt: "10px" }}>
          <Button
            variant="outlined"
            size="medium"
            sx={{ fontSize: "15px" }}
            onClick={() => setSearchQuery("")}
          >
            Clear All Filters
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filter;
