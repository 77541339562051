import React from "react";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import microsoftLogo from "assets/loginRegistration/microsoftLogo.png";
// import { activateUserSignIn } from "core/utils/appUtils";
import { Button } from "@mui/material";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { useDispatch } from "react-redux";
import { azureSsoLoginAction } from "actions/login_registrationActions";
import { useNavigate } from "react-router-dom";

export const MicrosoftLogin = (props) => {
	const { instance } = useMsal();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogin = (loginType) => {
		instance
			.loginPopup(loginRequest)
			.then((response) => {
				console.log("Check the Microsoft loginPopup Response", response);
				dispatch(azureSsoLoginAction(response, navigate));
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<div>
				<Button
					// variant="outlined"
					className="primaryButton hoverEffect social-btn"
					onClick={() => {
						handleLogin();
					}}
				>
					<span className="social-logo">
						<MicrosoftSignIn />
					</span>
					<span className="social-text">Sign in with Microsoft</span>
				</Button>
			</div>
		</>
	);
};

export const MicrosoftSignIn = () => {
	return (
		<>
			<div className="social-logo">
				<img src={microsoftLogo} alt={"microsoftLogo"} />
			</div>
		</>
	);
};

export default MicrosoftLogin;
