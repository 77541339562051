import React from "react";

const ProjectContext = React.createContext();

export const ProjectProvider = ({ children }) => {
	const [selectedProject, updateProjectContext] = React.useState({});

	const setProjectContext = (project) => {
		updateProjectContext(project);
	};

	return (
		<ProjectContext.Provider value={{ selectedProject, setProjectContext }}>
			{children}
		</ProjectContext.Provider>
	);
};

export default ProjectContext;
