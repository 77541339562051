import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { BackdropLoader } from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { appLoadingSelector, getAppApiAlert } from "selectors/appSelector";
import { ProjectProvider } from "context/ProjectContext";
import Layout2 from "./Layout/Layout2";
import createSignalRConnection from "./Alerts/alertConnection";
import { alertNotificationsAction, showAppApiAlert } from "actions/appActions";
import "@progress/kendo-theme-default/dist/all.css";
import { SnackBarWithInfoAlert } from "./Alerts/AlertSnackbar";

export default function App() {
	const dispatch = useDispatch();
	const apiAlert = useSelector(getAppApiAlert);

	useEffect(() => {
		const connection = createSignalRConnection();

		// Function to start the connection
		const startSignalRConnection = async () => {
			try {
				// Check connection state
				if (connection._connectionState === "Connected") return;

				await connection.start();
				console.log("SignalR Connected");
			} catch (err) {
				console.error("SignalR Connection Error:", err);
				// Retry connection after 30 seconds
				setTimeout(startSignalRConnection, 30000);
			}
		};
		const onReceiveMessage = (callback) => {
			connection.on("ReceiveAlert", callback);
		};
		// Start the connection
		startSignalRConnection();

		// Listen for incoming messages
		onReceiveMessage((user, message) => {
			console.log("Check the Alert Message : ", { user, message });
			dispatch(alertNotificationsAction({ user, message }));
		});

		// Listen for incoming messages
		// connection.on("ReceiveMessage", (user, message) => {
		//     setMessages((prevMessages) => [...prevMessages, { user, message }]);
		// });

		// Clean up connection on component unmount
		return () => {
			connection.stop().then(() => console.log("SignalR Disconnected"));
		};
	}, [dispatch]);

	const isLoading = useSelector(appLoadingSelector);

	return (
		<div className="app">
			<SnackBarWithInfoAlert
				open={apiAlert.showAlert}
				autoHideDuration={3000}
				alertSeverity={apiAlert.type}
				alertMessage={apiAlert?.message}
				handleClose={() => {
					dispatch(showAppApiAlert({}));
				}}
			/>
			<BackdropLoader open={isLoading} />
			<BrowserRouter>
				<AppWrapper />
			</BrowserRouter>
		</div>
	);
}

const AppWrapper = () => {
	return (
		<ProjectProvider>
			<Layout2 />
		</ProjectProvider>
	);
};
