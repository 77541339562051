import { propertyGetAll } from "./propertyActions";

import {
  PROPERTY_GET_ALL_SUCCESS,
  PROPERTY_ERROR,
  PROPERTY_LOADING,
} from "../constants/types";

import queryString from "query-string";
import { omit } from "lodash";

// import { bulkUploadFiles } from "./documentsActions";

import { server_url } from "../constants/types";
import request from "config/api";

// Redux Actions (actions.js)
export const FETCH_PROPERTIES_REQUEST = PROPERTY_LOADING;
export const FETCH_PROPERTIES_SUCCESS = PROPERTY_GET_ALL_SUCCESS;
export const FETCH_PROPERTIES_FAILURE = PROPERTY_ERROR;

export const FILE_MANAGER_GET_JOBS_LOADING = "FILE_MANAGER_GET_JOBS_LOADING";
export const FILE_MANAGER_GET_JOBS_SUCCESS = "FILE_MANAGER_GET_JOBS_SUCCESS";
export const FILE_MANAGER_GET_JOBS_ERROR = "FILE_MANAGER_GET_JOBS_ERROR";

export const fetchPropertiesRequest = () => ({
  type: FETCH_PROPERTIES_REQUEST,
});

export const fetchPropertiesSuccess = (properties) => ({
  type: FETCH_PROPERTIES_SUCCESS,
  payload: properties,
});

export const fetchPropertiesFailure = (error) => ({
  type: FETCH_PROPERTIES_FAILURE,
  payload: error,
});

export const fetchProperties = () => async (dispatch) => {
  dispatch(fetchPropertiesRequest());
  dispatch(propertyGetAll({ pageNumber: 1, pageSize: 15 }));
  //   try {
  //     const response = await fetch("/api/properties"); // Replace with actual API endpoint
  //     const data = await response.json();
  //     dispatch(fetchPropertiesSuccess(data));
  //   } catch (error) {
  //     dispatch(fetchPropertiesFailure(error.message));
  //   }
};
let jobsCache = {};

export const fetchJobsByProjectId = ({ projectId }) => {
  return (dispatch) => {
    if (jobsCache[projectId]) {
      dispatch({
        type: FILE_MANAGER_GET_JOBS_SUCCESS,
        payload: {
          jobs: jobsCache[projectId],
        },
      });
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      dispatch({
        type: FILE_MANAGER_GET_JOBS_LOADING,
      });

      request({
        url: `${server_url}/Job/List?ProjectId=${projectId}`,
        method: "GET",
      })
        .then((response) => {
          jobsCache[projectId] = response.data.items;
          dispatch({
            type: FILE_MANAGER_GET_JOBS_SUCCESS,
            payload: {
              jobs: response.data.items,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: FILE_MANAGER_GET_JOBS_ERROR,
            payload: {
              status: "error",
              text: "Error occurred during getting all warranties.",
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};
