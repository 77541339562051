import React, { useState } from "react";
import {
	Box,
	Typography,
	Card,
	CardContent,
	Switch,
	Stack,
} from "@mui/material";

export const NotificationSettings = ({ userData }) => (
	<Card>
		<CardContent>
			<Typography variant="h6" className="mb-6">
				Notification Preferences
			</Typography>
			<Stack spacing={3}>
				{[
					{ label: "Email Notifications", key: "email" },
					{ label: "Push Notifications", key: "push" },
					{ label: "Marketing Updates", key: "marketing" },
				].map((item) => (
					<Box key={item.key} className="flex justify-between items-center">
						<Box>
							<Typography variant="subtitle1">{item.label}</Typography>
							<Typography variant="body2" color="textSecondary">
								Receive notifications about{" "}
								{item.key === "email"
									? "account updates"
									: item.key === "push"
									? "new features"
									: "promotional offers"}
							</Typography>
						</Box>
						<Switch
							checked={userData.notifications[item.key]}
							color="primary"
							disabled
						/>
					</Box>
				))}
			</Stack>
		</CardContent>
	</Card>
);

export default NotificationSettings;
