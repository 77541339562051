import axios from "axios";
import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";
import { decodeToken } from "core/utils/appUtils";
import { GUID_ERROR_MESSAGE } from "constants/appConstants";
import { updateUserProfileDetailsAction } from "./userActions";
import { getCompanyByIdAction } from "./companiesActions";
import { fetchUserCompanyDetails } from "./appActions";

export const loginAction = (
	credentials,
	history = () => {},
	extraPayload = {}
) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.LOGIN,
				payload: {
					loading: true,
				},
			});
			axios
				.post(`${server_url}/account/login`, {
					...credentials,
				})
				.then((response) => {
					dispatch({
						type: ActionConstants.LOGIN_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "Get All LEAD data successfully.",
						},
					});
					console.log("Check the Extra Payload ", extraPayload);
					if (extraPayload.isSettingsPage) {
						dispatch(
							updateUserProfileDetailsAction({
								formData: extraPayload,
								onSuccess: () => {
									history("/");
								},
							})
						);
					} else {
						localStorage.setItem("token", get(response, "data.token", ""));
						dispatch(loadUserDetails(credentials.username));
						history("/");
					}

					console.log("Response : ", response);
					resolve();
				})
				.catch((e) => {
					const message = "Something Went Wrong";
					const errorResponse = get(e, "response.data", {
						message,
					});
					console.log("Error Response : ", e, errorResponse);

					dispatch({
						type: ActionConstants.LOGIN_ERROR,
						payload: {
							status: "error",
							message: errorResponse,
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const socialLoginAction = (credentials, history) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.LOGIN_EXTERNAL,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			localStorage.setItem("ssoToken", credentials.accessToken);
			axios
				.post(`${server_url}/account/loginExternal`, {
					AccessToken: credentials.accessToken,
				})
				.then((response) => {
					console.log("After Social Login : ", response, response.data);
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "Get All LEAD data successfully.",
						},
					});
					localStorage.setItem("token", response.data.token);
					const decodedToken = decodeToken();
					console.log("Check the Decoded Token : ", decodedToken);
					dispatch(loadUserDetails());

					// if (response.data.registeredNewUser) {
					// 	history(
					// 		`/app/registerForm?email=${decodedToken.unique_name}&type=sso`
					// 	);
					// } else {
					if (response.data.registeredNewUser) {
						history("/sso-login/new-registration");
					} else {
						history("/");
					}
					// }
					console.log("Response : ", response);
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });

					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_ERROR,
					});

					resolve();
				});
		});
	};
};

export const signUpEmailVerify = (signUpBody, onSuccess, resend = false) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SIGNUP_EMAIL,
				payload: {
					loading: true,
				},
			});
			axios
				.get(
					`${server_url}/user/getUserExistsByEmail?email=${signUpBody.eMailAddress}`
				)
				.then((emailCheckResponse) => {
					const query = queryString.stringify(signUpBody);
					if (emailCheckResponse.data) {
						onSuccess(
							{
								emailExists: true,
								message: "Given Email already exists",
							},
							false
						);
						dispatch({
							type: ActionConstants.SIGNUP_EMAIL_SUCCESS,
							payload: {
								loading: false,
								status: "error",
								text: "Already had an email",
							},
						});
					} else {
						axios
							.post(`${server_url}/Generic/sendemail?${query}`)
							.then((response) => {
								dispatch({
									type: ActionConstants.SIGNUP_EMAIL_SUCCESS,
									payload: {
										leadList: response.data,
										loading: false,
										status: "success",
										text: "Get All LEAD data successfully.",
									},
								});
								onSuccess(
									{
										emailExists: false,
										message:
											"Verification email has been sent, please verify for registration process",
									},
									true
								);
								resolve();
							})
							.catch((e) => {
								console.log("Error Response : ", e);
								const errorResponse = get(e, "response", {});
								onSuccess();
								dispatch({
									type: ActionConstants.SIGNUP_EMAIL_ERROR,
									payload: {
										status: "error",
										message:
											errorResponse.status === 401
												? "Bad Credentials"
												: "Something Went Wrong",
										loading: false,
									},
								});
								resolve();
							});
					}
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					onSuccess(
						{
							emailExists: true,
							message: "Something went wrong! please try again",
						},
						false
					);
					dispatch({
						type: ActionConstants.SIGNUP_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const registerUserDetailsAction = (
	userDetails,
	invGuid,
	userType,
	onSuccess
) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.REGISTRATION,
				payload: {
					loading: true,
				},
			});
			axios
				.get(
					`${server_url}/user/getUserExistsByEmail?email=${userDetails.email}`
				)
				.then((emailCheckResponse) => {
					if (emailCheckResponse.data) {
						console.log("Email Exists");
						dispatch({
							type: ActionConstants.REGISTRATION_ERROR,
							payload: {
								status: "error",
								message: "Account with this Email already exists....",
								loading: false,
							},
						});
					} else {
						axios
							.post(`${server_url}/user?InvGuid=${invGuid}`, {
								...userDetails,
								userType: userType,
								// companyName: null,
								// role: "admin",
							})
							.then((response) => {
								dispatch({
									type: ActionConstants.REGISTRATION_SUCCESS,
									payload: {
										leadList: response.data,
										loading: false,
										status: "success",
										text: "User Registered Successfuly",
									},
								});
								onSuccess(response.data);
								dispatch(
									loginAction({
										username: userDetails.email,
										password: userDetails.password,
									})
								);
								// history("/");
								console.log("Response : ", response);
								resolve();
							})
							.catch((e) => {
								console.log("Error Response : ", e);
								const errorResponse = get(e, "response", {});
								dispatch({
									type: ActionConstants.REGISTRATION_ERROR,
									payload: {
										status: "error",
										message:
											errorResponse.status === 401
												? "Bad Credentials"
												: "Something Went Wrong",
										loading: false,
									},
								});
								resolve();
							});
					}
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					onSuccess(
						{
							emailExists: true,
							message: "Something went wrong! please try again",
						},
						false
					);
					dispatch({
						type: ActionConstants.SIGNUP_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const updateSSOUserDetails = (userDetails, userType, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.REGISTRATION,
				payload: {
					loading: true,
				},
			});
			const decodedToken = decodeToken();
			console.log("Check the Decoded Token : ", decodedToken);
			request({
				method: "PUT",
				url: `${server_url}/user/UpdateSSOUser/${decodedToken?.nameid}`,
				body: {
					...userDetails,
					userType: userType,
					email: decodedToken.unique_name,
					userId: decodedToken?.nameid,
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.REGISTRATION_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "User Registered Successfuly",
						},
					});
					const ssoToken = localStorage.getItem("ssoToken");
					if (get(decodedToken, "LoginSource", "") === "MSSO") {
						dispatch(
							azureSsoLoginAction({ idToken: ssoToken }, () => {
								onSuccess(response.data);
							})
						);
					} else {
						dispatch(
							socialLoginAction({ accessToken: ssoToken }, () => {
								onSuccess(response.data);
							})
						);
					}

					// history("/");
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.REGISTRATION_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
export const paymentInformationAction = (paymentDetails, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.PAYMENT,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/card`,
				body: {
					...paymentDetails,
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.PAYMENT_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "User Registered Successfuly",
						},
					});
					onSuccess(response.data);
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.PAYMENT_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const loadUserDetails = () => {
	const userDetails = decodeToken();
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.USER_DETAILS,
				payload: {
					loading: true,
				},
			});
			if (userDetails?.nameid) {
				request({
					method: "GET",
					url: `${server_url}/user/${userDetails?.nameid}`,
				})
					.then((response) => {
						dispatch({
							type: ActionConstants.USER_DETAILS_SUCCESS,
							payload: response.data,
						});
						dispatch(fetchUserCompanyDetails(response.data.companyId));
						resolve();
					})
					.catch((e) => {
						console.log("Error Response : ", e);
						const errorResponse = get(e, "response", {});
						dispatch({
							type: ActionConstants.USER_DETAILS_ERROR,
							payload: {
								status: "error",
								message:
									errorResponse.status === 401
										? "Bad Credentials"
										: "Something Went Wrong",
								loading: false,
							},
						});
						resolve();
					});
			} else {
				dispatch({
					type: ActionConstants.USER_DETAILS_ERROR,
					payload: {
						status: "error",
						message: "User not found from Token",
						loading: false,
					},
				});
				resolve();
			}
		});
	};
};

export const getGuidDetailsAction = (guid, UserAction = "") => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_GUID_DETAILS,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/Generic/GetEmailForGuid?Guid=${guid}&UserAction=${UserAction}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_GUID_DETAILS_SUCCESS,
						payload: response.data,
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_GUID_DETAILS_ERROR,
						payload: {
							status: "error",
							error: GUID_ERROR_MESSAGE,
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const azureSsoLoginAction = (credentials, history) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.LOGIN_EXTERNAL,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			localStorage.setItem("ssoToken", credentials.idToken);
			axios
				.post(`${server_url}/account/azureLoginExternal`, credentials)
				.then((response) => {
					console.log("After Social Login : ", response, response.data);
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "Get All LEAD data successfully.",
						},
					});
					localStorage.setItem("token", response.data.token);
					const decodedToken = decodeToken();
					console.log("Check the Decoded Token : ", decodedToken);
					dispatch(loadUserDetails());
					// if (response.data.registeredNewUser) {
					// 	history(
					// 		`/app/registerForm?email=${decodedToken.unique_name}&type=sso`
					// 	);
					// } else {
					if (response.data.registeredNewUser) {
						history("/sso-login/new-registration");
					} else {
						history("/");
					}
					// }
					console.log("Response : ", response);
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });

					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_ERROR,
					});

					resolve();
				});
		});
	};
};

export const sentPasswordResetEmailAction = (email, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SEND_RESET_PASSWORD_EMAIL,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/generic/ForgotPwd?eMailAddress=${email}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
						payload: response.data,
					});
					onSuccess({
						success: true,
						message:
							"Password reset link email has been sent to your email, please verify for email to reset password",
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.SEND_RESET_PASSWORD_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					onSuccess({
						success: false,
						message:
							"Error in sending password reset link, please try again later",
					});
					resolve();
				});
		});
	};
};

export const resetPasswordAction = (payload) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SEND_RESET_PASSWORD_EMAIL,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/generic/ResetPwd?eMailAddress=${payload.email}&pwd=${payload.password}&userGuid=${payload.guid}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
						payload: response.data,
					});
					if (payload.onSuccess) {
						payload.onSuccess({
							type: "success",
							message: "Password reset successfully",
						});
					}
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.SEND_RESET_PASSWORD_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					if (payload.onSuccess) {
						payload.onSuccess({
							type: "error",
							message: "Error in resetting password, please try again later",
						});
					}
					resolve();
				});
		});
	};
};
