export const GET_ESTIMATES_REQUEST = "GET_ESTIMATES_REQUEST";
export const GET_ESTIMATES_SUCCESS = "GET_ESTIMATES_SUCCESS";
export const GET_ESTIMATES_ERROR = "GET_ESTIMATES_ERROR";

export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR";

export const GET_TEMPLATE_FIELDS_REQUEST = "GET_TEMPLATE_FIELDS_REQUEST";
export const GET_TEMPLATE_FIELDS_SUCCESS = "GET_TEMPLATE_FIELDS_SUCCESS";
export const GET_TEMPLATE_FIELDS_ERROR = "GET_TEMPLATE_FIELDS_ERROR";

export const SAVE_PROJECT_ESTIMATE_REQUEST = "SAVE_PROJECT_ESTIMATE_REQUEST";
export const SAVE_PROJECT_ESTIMATE_SUCCESS = "SAVE_PROJECT_ESTIMATE_SUCCESS";
export const SAVE_PROJECT_ESTIMATE_ERROR = "SAVE_PROJECT_ESTIMATE_ERROR";

export const GET_ESTIMATE_ITEMS_REQUEST = "GET_ESTIMATE_ITEMS_REQUEST";
export const GET_ESTIMATE_ITEMS_SUCCESS = "GET_ESTIMATE_ITEMS_SUCCESS";
export const GET_ESTIMATE_ITEMS_ERROR = "GET_ESTIMATE_ITEMS_ERROR";

export const SAVE_ESTIMATE_ITEMS_REQUEST = "SAVE_ESTIMATE_ITEMS_REQUEST";
export const SAVE_ESTIMATE_ITEMS_SUCCESS = "SAVE_ESTIMATE_ITEMS_SUCCESS";
export const SAVE_ESTIMATE_ITEMS_ERROR = "SAVE_ESTIMATE_ITEMS_ERROR";

export const CONVERT_ESTIMATE_TO_PROJECT_REQUEST =
	"CONVERT_ESTIMATE_TO_PROJECT_REQUEST";
export const CONVERT_ESTIMATE_TO_PROJECT_SUCCESS =
	"CONVERT_ESTIMATE_TO_PROJECT_SUCCESS";
export const CONVERT_ESTIMATE_TO_PROJECT_ERROR =
	"CONVERT_ESTIMATE_TO_PROJECT_ERROR";
