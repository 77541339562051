import React, { useEffect, useState } from "react";
import { Typography, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getTempaltesList } from "selectors/estimatesSelectors";
import { getUserProfileDetails } from "selectors/appSelector";
import { FormLabel } from "components/TextFields/FormInputField";
import { getProperties } from "selectors/propertySelectors";
import { getProjects } from "selectors/projectSelector";
import { get, isUndefined } from "lodash";
import { propertyGetAll } from "actions/propertyActions";
import { fetchProjects } from "actions/projectActions";
import {
	fetchTemplatesAction,
	saveEstimateTemplateAction,
} from "actions/estimationsActions";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";

export const CreateEstimateContent = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { propertiesList } = useSelector(getProperties);
	const { projectsList } = useSelector(getProjects);
	const templatesList = useSelector(getTempaltesList);

	const userProfileDetails = useSelector(getUserProfileDetails);

	const [formState, setFormState] = useState({});

	useEffect(() => {
		dispatch(propertyGetAll({ pageSize: 500 }));
		dispatch(fetchTemplatesAction());
	}, [dispatch]);

	const dynamicOptions = {
		propertyId: propertiesList.map((property) => ({
			key: property.propertyId,
			text: property.propertyName,
			// ...property,
		})),
		// projectId: projectsList.map((project) => ({
		// 	key: project.projectId,
		// 	text: project.projectName,
		// 	...project,
		// })),
		templateId: templatesList.map((temp) => ({
			key: temp.templateId,
			text: temp.templateName,
			...temp,
		})),
	};

	console.log("Check the Dynamic Options", dynamicOptions);

	const fields = [
		{
			key: "templateId",
			label: "Select Template",
			type: "select",
			select: true,
			options: [],
			required: true,
			width: "50%",
		},
		{
			key: "propertyId",
			label: "Select Property",
			type: "select",
			required: true,
			select: true,
			options: [],
			width: "50%",
		},
		{
			key: "estimateName",
			label: "Estimate Name",
			type: "text",
			required: true,
			width: "100%",
		},
		{
			key: "estimateDescription",
			label: "Estimate Description",
			type: "text",
			required: false,
			width: "100%",
		},

		// {
		// 	key: "projectId",
		// 	label: "Select Project",
		// 	type: "select",
		// 	select: true,
		// 	options: [],
		// 	width: "45%",
		// },
	];

	const isValid = () => {
		return ["templateId", "estimateName", "propertyId"].every(
			(field) => formState[field]
		);
	};

	const getOptions = (field) => {
		const options = get(dynamicOptions, field.key, field.options || []);
		return options;
	};
	return (
		<>
			<div className="mb-2 p-md-2 p-1">
				<div className="mb-3">
					<div className="d-flex flex-wrap">
						{fields.map((field) => {
							return (
								<div style={{ width: field.width || "50%", padding: "0 10px" }}>
									<FormLabel text={field.label} required={field.required} />
									<TextField
										type={field.type}
										select={field.select}
										name={field.key}
										id={field.key}
										value={formState[field.key] || ""}
										onChange={(e) => {
											setFormState({
												...formState,
												[field.key]: e.target.value,
											});
										}}
										className="form-field"
										SelectProps={{
											renderValue: (selected) => {
												return (
													<Typography>
														{typeof getOptions(field)[0] === "string"
															? selected
															: getOptions(field).find(
																	(option) => option.key === selected
															  )?.text}
													</Typography>
												);
											},
										}}
									>
										{getOptions(field).map((option) =>
											!isUndefined(option.key) ? (
												<MenuItem
													key={option.key}
													value={option.key}
													onClick={() => {
														if (field.key === "propertyId") {
															dispatch(
																fetchProjects({
																	pageSize: 500,
																	propertyId: option.key,
																})
															);
														}
													}}
												>
													{option.text}
												</MenuItem>
											) : (
												<MenuItem value={option}>{option}</MenuItem>
											)
										)}
									</TextField>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					<button
						className={`primaryButton full-width ${
							!isValid() ? "disabled" : ""
						}`}
						onClick={() => {
							// const templateWithId = props.template.find((temp) => {
							// 	return temp.templateId;
							// });
							const payload = {
								...formState,
								companyId: userProfileDetails.companyId,
								// templateId: get(templateWithId, "templateId", null),
							};

							dispatch(
								saveEstimateTemplateAction(payload, (responsePayload) => {
									setFormState({});
									props.setCreateModel(false);
									navigate(
										`/builder/estimations/${responsePayload.estimateId}`
									);
								})
							);

							console.log("Check form State", payload);
						}}
						disabled={!isValid()}
					>
						Create & Save Estimate
					</button>
				</div>
				{!isValid() && (
					<div className="mt-2">
						<Typography style={{ color: "red" }}>
							Please fill all the required fields.
						</Typography>
					</div>
				)}
			</div>
		</>
	);
};
