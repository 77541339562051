import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.company;

export const companiesLoader = createSelector(selectState, (companies) =>
	get(companies, "loading", false)
);

export const connectionsSelector = createSelector(selectState, (companies) => ({
	connected: get(companies, "connections", []),
	newConnections: get(companies, "newConnections", []),
	pendingConnections: get(companies, "pendingConnections", []),
	unfilteredCompanies: get(companies, "unfilteredCompanies", []),
}));

export const companiesSelector = createSelector(selectState, (companies) => ({
	companiesList: get(companies, "companiesList", []),
	totalCount: get(companies, "totalCompaniesCount", 0),
}));

export const connectedCompaniesSelector = createSelector(
	selectState,
	(companies) => get(companies, "connectedCompanies", [])
);

export const connectedSuppliersSelector = createSelector(
	selectState,
	(companies) => ({
		connectedSuppliers: get(companies, "connectedSuppliers", []),
		loadingSuppliers: get(companies, "loadingSuppliers", false),
	})
);

export const getMarketSectorsSelector = createSelector(
	selectState,
	(companies) => get(companies, "marketSectorsList", [])
);

export const getCompanyTypesSelector = createSelector(
	selectState,
	(companies) => get(companies, "companyTypesList", [])
);

export const getCompanyFormDropdownsSelector = createSelector(
	[getMarketSectorsSelector, getCompanyTypesSelector],
	(marketSectors, companyTypes) => ({ marketSectors, companyTypes })
);

export const getCompanyDetailsSelector = createSelector(
	selectState,
	(companies) => get(companies, "companyDetails", {})
);

export const getCompanyDetailsErrorSelector = createSelector(
	selectState,
	(companies) => get(companies, "companyDetailsError", {})
);
