import { InputAdornment, Typography } from "@mui/material";
import { updateUserProfileDetailsAction } from "actions/userActions";
import FormInputField from "components/TextFields/FormInputField";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import { phoneRegExp } from "constants/appConstants";
import { getText } from "core/utils/getContentText";
import { useFormik } from "formik";
import { get, omit } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

export const EditUsers = ({ userDetails = {}, onUpdateSuccess = () => {} }) => {
	const dispatch = useDispatch();

	const { validationSchema } = userDetailsForm;
	const initialState = userDetailsForm.defaultState(userDetails);

	const formik = useFormik({
		initialValues: { ...initialState },
		validationSchema: validationSchema,
		onSubmit: () => {
			const extractedFields = omit(formik.values, ["confirmPassword"]);
			dispatch(
				updateUserProfileDetailsAction({
					formData: {
						...extractedFields,
						// userType: "user",
						//  role: "user"
					},
					onSuccess: () => {
						onUpdateSuccess();
					},
				})
			);
		},
	});
	return (
		<>
			<div className="pb-5 pt-0 registration-form user-invite-form">
				<div className="invite-form-header mt-3 text-center">
					<Typography className="h3 mt-2">
						Update Details for {userDetails?.userName}
					</Typography>
				</div>
				<div className="container ">
					<div id="create-project" className="mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div id="container row">
								<div id="section-1 mt-3">
									<div className="d-flex">
										<div className="form-fields">
											<div className="container row justify-content-between">
												{addUserFields.map((field) => {
													const extraInputProps = get(
														field,
														"extraInputProps",
														{}
													);

													const extraProps =
														typeof extraInputProps === "function"
															? extraInputProps(formik)
															: extraInputProps;

													return (
														<FormInputField
															field={field}
															formik={formik}
															value={formik.values[field.key]}
															key={field.key}
															{...extraProps}
														/>
													);
												})}
											</div>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<div className="d-flex justify-content-center align-self-center">
										<button
											type="submit"
											className={`primaryButton m-3 full-width`}
										>
											{"Update User"}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

const userDetailsValidationSchema = yup.object({
	firstName: yup.string("Enter First Name").required("First Name is required"),
	lastName: yup.string("Enter Last Name").required("Last Name is required"),

	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	mobileNumber: yup
		.string("Enter your Mobile")
		.required("Mobile is required")
		.matches(phoneRegExp, "Phone number is not valid"),
});

const getLabel = (key) => getText(`registrationForm.user.${key}`);

const addUserFields = [
	{
		key: "firstName",
		label: getLabel("firstName"),
		type: "text",
		required: true,
		width: "50%",
	},
	{
		key: "lastName",
		label: getLabel("lastName"),
		type: "text",
		required: true,
		width: "50%",
	},
	{
		key: "email",
		label: getLabel("email"),
		type: "text",
		required: true,
		width: "50%",
	},
	{
		id: "mobileNumber",
		key: "mobileNumber",
		label: getLabel("mobile"),
		placeholder: "(xxx) xxx-xxxx",
		type: "phone",
		required: true,
		width: "50%",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},
	{
		key: "landPhone",
		label: "landline",
		placeholder: "(xxx) xxx-xxxx",
		type: "phone",
		width: "50%",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},
	{
		key: "password",
		label: getLabel("password"),
		type: "password",
		width: "50%",
	},
];

const userDetailsForm = {
	validationSchema: userDetailsValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		}
		return Object.fromEntries(
			Object.keys(userDetailsValidationSchema.fields).map((key) => [key, ""])
		);
	},
};

export default EditUsers;
