import {
  SETTINGS_GET_COMPANIES,
  SETTINGS_GET_COMPANIES_LOADING,
  SETTINGS_STATUS_ERROR,
  SETTINGS_COMPANIES_CLEAR_SNACKBAR,
  SETTINGS_DELETE_COMPANY_SUCCESS,
} from "../constants/types";

const initialState = {
  companiesList: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const marketPlaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_COMPANIES_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case SETTINGS_GET_COMPANIES:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case SETTINGS_GET_COMPANIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_STATUS_ERROR:
      return {
        ...state,
        companiesList: [],
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    default:
      return state || {};
  }
};

export default marketPlaceReducer;
