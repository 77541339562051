import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { Box, InputAdornment, LinearProgress, Typography } from "@mui/material";
import {
	AssignmentOutlined,
	Delete,
	EditOutlined,
	VerifiedUser,
} from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import { MenuItem } from "@mui/material";
import HiddenOn from "components/Wrappers/HiddenOn";
import { isJobManager } from "core/utils/roleUtils";
import get from "lodash/get";
import { getMomentDate } from "core/utils/appUtils";
import Tooltip from "components/Tooltip/Tooltip";
import { createFilterOptions } from "@mui/material/Autocomplete";

const getTextContent = (key) => getText(`jobs.addJobs.${key}`);
const filter = createFilterOptions();

export const jobStatusOptions = [
	"Planning",
	"Pending",
	"In Progress",
	"Completed",
];

const addJobValidationSchema = yup.object({
	jobName: yup.string("Enter Job Name").required("Job Name is required"),
	jobTypeId: yup.string("Select Job type").required("Job type is required"),
	jobStatusId: yup
		.string("Select Job Status")
		.required("Job Status is required"),
	estimatedCost: yup
		.number("Enter cost till now")
		.typeError("Please enter a valid Estimation")
		.integer("Cost must be an number")
		.nullable(),
	costToDate: yup
		.number("Enter cost till now")
		.typeError("Please enter a valid cost")
		.integer("Cost must be an number")
		.nullable(),
});
export const addJobForm = {
	formFields: [
		{
			key: "jobName",
			placeholder: "Job Name",
			label: getTextContent("jobName"),
			type: "text",
			required: true,
			width: "100%",
		},
		{
			key: "jobTypeId",
			placeholder: "Select from List",
			label: "Job Type",
			type: "select",
			select: true,
			options: [
				"Plumbing",
				"Electrical",
				"Carpentry",
				"Painting",
				"Masonary",
				"Concrete Finisher",
				"Others",
			],
			required: true,
			width: "50%",
		},
		{
			key: "jobStatusId",
			placeholder: "Select Status",
			label: "Job Status",
			type: "select",
			select: true,
			options: [],
			required: true,
			width: "50%",
		},
		{
			key: "estimatedCost",
			placeholder: "Enter Estimated Cost",
			label: "Estimated Cost",
			type: "text",
			width: "50%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "costToDate",
			placeholder: "Cost till Date",
			label: "Cost to Date",
			type: "text",
			width: "50%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "plannedStartDate",
			placeholder: getTextContent("plannedStartDate"),
			label: getTextContent("plannedStartDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "plannedEndDate",
			placeholder: getTextContent("plannedEndDate"),
			label: getTextContent("plannedEndDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "actualStartDate",
			placeholder: getTextContent("actualStartDate"),
			label: getTextContent("actualStartDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "actualEndDate",
			placeholder: getTextContent("actualEndDate"),
			label: getTextContent("actualEndDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "companyId",
			label: "Assigned To",
			placeholder: "SelectJob assignee",
			select: true,
			value: "",
			options: [""],
			width: "50%",
			type: "dynamicField",
			autoCompleteProps: {
				getChange: (_, value) => {
					return value?.companyId;
				},
				isOptionEqualToValue: (option, value) => {
					return option.companyId === value.companyId;
				},
				filterOptions: (x) => {
					return x;
				},

				getOptionLabel: (option) => {
					return `${option.companyName}`.trim();
				},
				renderOption: (props, option) => {
					const { key, ...optionProps } = props;
					return (
						<li key={option.companyId} {...optionProps}>
							{`${option.companyName}`.trim()}
						</li>
					);
				},
			},
		},
		{
			key: "jobSupervisor",
			label: "Job Supervisor",
			placeholder: "Select From List",
			value: "",
			select: true,
			options: [""],
			width: "50%",
			type: "dynamicField",
			autoCompleteProps: {
				creatable: true,
				getChange: (_, value) => {
					let inputValue = "";
					// Handle different value types
					if (typeof value === "string") {
						inputValue = value;
					} else if (value && value.inputValue) {
						// Handle the "Add new" option
						inputValue = value.inputValue;
					} else if (value) {
						// Handle selected user object
						inputValue = `${value.firstName} ${value.lastName}`.trim();
					}
					return inputValue;
				},
				isOptionEqualToValue: (option, value) => {
					if (typeof value === "string") {
						return `${option.firstName} ${option.lastName}`.trim() === value;
					}
					return option.userId === value.userId;
				},
				filterOptions: (options, params) => {
					const filtered = filter(options, params);
					const { inputValue } = params;

					// Check if input matches any existing option
					const isExisting = options.some(
						(option) =>
							inputValue === `${option.firstName} ${option.lastName}`.trim() ||
							inputValue === option.title
					);

					// Add the "create new" option if input is valid and doesn't exist
					if (inputValue.trim() !== "" && !isExisting) {
						filtered.push({
							inputValue: inputValue.trim(),
							title: `Add "${inputValue.trim()}"`,
							firstName: "",
							lastName: "",
						});
					}

					return filtered;
				},
				getOptionLabel: (option) => {
					if (typeof option === "string") {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return `${option.firstName} ${option.lastName}`.trim();
				},
				renderOption: (props, option) => {
					const { key, ...optionProps } = props;
					return (
						<li key={option.userId || "new"} {...optionProps}>
							{option.title || `${option.firstName} ${option.lastName}`.trim()}
						</li>
					);
				},
			},
		},
		{
			key: "jobNotes",
			placeholder: "Enter Job Notes",
			label: "Job Notes",
			type: "textarea",
			width: "100%",
			extraInputProps: {
				multiline: true,
				className:
					"registration-formField project-notes property-details-textarea",
			},
		},
	],
	validationSchema: addJobValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		} else {
			const dynamicInitialState = Object.fromEntries(
				Object.keys(addJobValidationSchema.fields).map((key) => [key, ""])
			);

			return {
				...dynamicInitialState,
				costToDate: "0",
			};
		}
	},
};

export const priority = {
	0: "Low Priority",
	1: "Medium Priority",
	2: "High Priority",
};
export const jobListcolumns = (props = {}) => [
	{
		field: "jobCode",
		headerName: "Job Id",
		width: "15%",
		renderCell: (rowData) => {
			return (
				<Tooltip title={rowData.row.jobCode}>
					<Typography
						className="pointer underline-text"
						variant="body2"
						onClick={() => {
							props.setViewJob(rowData.row);
							// props.setSelectedJob({ id: rowData.id, data: rowData.row });
						}}
					>
						{rowData.row.jobCode}
					</Typography>
				</Tooltip>
			);
		},
	},

	{
		field: "jobName",
		headerName: "Job Name",
		width: "15%",
		renderCell: (rowData) => {
			return (
				<Typography
					className="pointer underline-text"
					variant="body2"
					onClick={() => {
						props.setViewJob(rowData.row);
						// props.setSelectedJob({ id: rowData.id, data: rowData.row });
					}}
				>
					{rowData.row.jobName}
				</Typography>
			);
		},
	},
	{
		field: "jobSupervisor",
		headerName: "Job Supervisor",
		width: "10%",
	},
	{
		field: "assignedTo",
		headerName: "Assigned To",
		width: "10%",
		renderCell: (rowData) => {
			return (
				<Typography variant="body2">
					{get(rowData, "row.company.companyName", "-")}
				</Typography>
			);
		},
	},
	{
		field: "completionPerc",
		headerName: "Completion %",
		width: "20%",
		renderCell: (rowData) => {
			const completionStatus = get(rowData, "row.statusComplete", 0);
			return (
				<Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
					<Box sx={{ width: "100%", mr: 1 }}>
						<LinearProgress variant="determinate" value={completionStatus} />
					</Box>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" sx={{ color: "text.secondary" }}>
							{`${completionStatus}%`}
						</Typography>
					</Box>
				</Box>
			);
		},
	},

	{
		field: "plannedStartDate",
		headerName: "Start Date",
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "plannedEndDate",
		headerName: "End Date",
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "actions",
		type: "actions",
		flex: 1,
		align: "right",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						props.setEditJob(true);
						props.setAddNewJob(true);
						props.setSelectedJob({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit Job</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.setAddTask(true);
						props.setSelectedJob({
							id: params.id,
							data: params.row,
						});
					}}
				>
					<AssignmentOutlined /> <p className="m-0">Add Task</p>
				</MenuItem>
				<HiddenOn
					isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
				>
					<MenuItem
						onClick={() => {
							props.setUserManagement(true);
							props.setJobActionsContext(params.id);
							props.setSelectedJob({
								id: params.id,
								data: params.row,
							});
						}}
					>
						<VerifiedUser />
						Assign Job
					</MenuItem>
				</HiddenOn>
				<HiddenOn
					isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
				>
					<MenuItem
						onClick={() => {
							props.onJobDelete(params.id, params.row);
						}}
					>
						<Delete /> <p className="m-0">Delete Job</p>
					</MenuItem>
				</HiddenOn>
			</BurgerMenu>,
		],
	},
];
