import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILES_REQUEST,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  BULK_UPLOAD_FILE_REQUEST,
  BULK_UPLOAD_FILE_SUCCESS,
  BULK_UPLOAD_FILE_FAILURE,
  PREVIEW_FILE_SUCCESS,
  PREVIEW_FILE_REQUEST,
  FETCH_DOCUMENT_TYPES_REQUEST,
  FETCH_DOCUMENT_TYPES_SUCCESS,
  FETCH_DOCUMENT_TYPES_FAILURE,
} from "../constants/documentsConstants";

const initialState = {
  files: [],
  isUploading: false,
  isFetching: false,
  uploadError: null,
  fetchError: null,
  previewURL: "",
  documentTypes: [],
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
    case BULK_UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isUploading: true,
        uploadError: null,
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        files: [action.payload, ...state.files], // Prepend uploaded file
      };
    case BULK_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isUploading: false,
        // files: [...state.files], // Prepend uploaded file
      };

    case UPLOAD_FILE_FAILURE:
    case BULK_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploading: false,
        uploadError: action.error,
      };

    case FETCH_FILES_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      };

    case FETCH_FILES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        files: action.payload,
      };

    case FETCH_FILES_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchError: action.error,
      };
    case PREVIEW_FILE_REQUEST:
      return {
        ...state,
        previewURL: null,
      };
    case PREVIEW_FILE_SUCCESS:
      return {
        ...state,
        previewURL: action.payload,
      };
    case FETCH_DOCUMENT_TYPES_REQUEST:
      return {
        ...state,
        isFetching: true,
        fetchError: null,
      };
    case FETCH_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        documentTypes: action.payload,
      };
    case FETCH_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchError: action.error,
      };

    default:
      return state || {};
  }
};

export default documentsReducer;
