import { Error } from "@mui/icons-material";
import { TextField, Typography } from "@mui/material";
import {
	getGuidDetailsAction,
	resetPasswordAction,
} from "actions/login_registrationActions";
import { CopilotFooter, CopilotHeader } from "components/Header";
import HiddenOn from "components/Wrappers/HiddenOn";
import { GUID_ERROR_MESSAGE } from "constants/appConstants";
// import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getEmailFromGuid } from "selectors/appSelector";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";

const validationSchema = yup.object({
	password: yup
		.string("Enter password")
		.required("Password is required")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
			"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
		),
	confirmPassword: yup
		.string("Enter password")
		.required("Confirm password is required")
		.oneOf([yup.ref("password")], "Passwords must match"),
});

export const ResetPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const { email, guidError } = useSelector(getEmailFromGuid);

	const [showToast, setShowToast] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState("");

	useEffect(() => {
		dispatch(getGuidDetailsAction(params.GUID, "resetpwd"));
	}, [params.GUID, dispatch]);

	const backToLogin = () => {
		return (
			<>
				<div style={{ justifySelf: "center" }}>
					<div className="mt-4 mb-2">
						<p
							className="link-text"
							onClick={() => {
								navigate("/login");
							}}
						>
							Click here to Login Back again
						</p>
					</div>
				</div>
			</>
		);
	};

	const renderError = () => {
		return (
			<>
				<div className="h2 text-center mt-5 pt-4">
					<div>
						<Error style={{ fontSize: "3rem", color: "red" }} />
					</div>
					{guidError || GUID_ERROR_MESSAGE}
				</div>
				{backToLogin()}
			</>
		);
	};

	return (
		<>
			<SnackBarWithInfoAlert
				open={showToast}
				type={toastMessage.type}
				message={toastMessage.message}
				onClose={() => setShowToast(false)}
			/>
			<CopilotHeader />
			<HiddenOn isHidden={guidError} fallback={renderError()}>
				<div className="text-center mt-3">
					<Typography className="h4">Reset Password</Typography>
				</div>
				<div className="col-8 p-3 mt-3" style={{ placeSelf: "center" }}>
					<Formik
						initialValues={{ password: "", confirmPassword: "" }}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							dispatch(
								resetPasswordAction({
									email,
									password: values.password,
									guid: params.GUID,
									onSuccess: (payload) => {
										setShowToast(true);
										setToastMessage(payload);
										setTimeout(() => {
											navigate("/login");
										}, 2000);
										// navigate("/login");
									},
								})
							);
						}}
					>
						{({ isSubmitting }) => (
							<Form>
								<div className="d-flex  align-items-center">
									<Typography
										variant="body1"
										className="col-md-3 col-5 text-align-start"
									>
										<strong>Password</strong>
									</Typography>
									<Field
										type="password"
										name="password"
										as={TextField}
										// value={password}
										// onChange={(e) => setPassword(e.target.value)}
										helperText={<ErrorMessage name="password" />}
										error={<ErrorMessage name="password" />}
										className="form-field formField col-md-6 col-7"
									/>
								</div>
								<div className="d-flex  align-items-center">
									<Typography
										variant="body1"
										className="col-md-3 col-5 text-align-start"
									>
										<strong>Confirm Password</strong>
									</Typography>
									<Field
										name="confirmPassword"
										type="password"
										as={TextField}
										className="form-field formField col-md-6 col-7"
										helperText={<ErrorMessage name="confirmPassword" />}
										error={<ErrorMessage name="confirmPassword" />}
									/>
								</div>
								<div style={{ justifySelf: "center" }}>
									<button
										type="submit"
										className="outlinedButton"
										style={{ width: "auto" }}
										disabled={isSubmitting}
									>
										Reset Password
									</button>
								</div>
								{/* <div style={{ justifySelf: "center" }}>
									<button
										onClick={() => {
											dispatch(
												resetPasswordAction({
													email,
													password,
													onSuccess: () => {
														navigate("/login");
													},
												})
											);
										}}
										className="outlinedButton"
										style={{ width: "auto" }}
										disabled={password !== confirmPassword}
									>
										Reset Password
									</button>
								</div> */}
							</Form>
						)}
					</Formik>
					{backToLogin()}
				</div>
			</HiddenOn>
			<CopilotFooter fixed />
		</>
	);
};

export default ResetPassword;
