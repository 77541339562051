import { ActionConstants } from "constants";

const initialState = {
	templateFields: [],
	templatesList: [],
	templateLoading: false,
	estimatesTemplateLoading: false,
	estimatesList: [],
};

const estimatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.GET_TEMPLATES_REQUEST:
			return {
				...state,
				templatesList: [],
				estimatesTemplateLoading: true,
			};
		case ActionConstants.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				templatesList: action.payload,
				estimatesTemplateLoading: false,
			};
		case ActionConstants.GET_TEMPLATES_ERROR:
			return {
				...state,
				templatesList: [],
				estimatesTemplateLoading: false,
				error: action.payload,
			};
		case ActionConstants.GET_TEMPLATE_FIELDS_REQUEST:
			return {
				...state,
				templateFields: [],
				templateLoading: true,
			};
		case ActionConstants.GET_TEMPLATE_FIELDS_SUCCESS:
			return {
				...state,
				templateFields: action.payload.data,
				templateLoading: false,
			};
		case ActionConstants.GET_TEMPLATE_FIELDS_ERROR:
			return {
				...state,
				templateFields: [],
				templateLoading: false,
				error: action.payload,
			};
		case ActionConstants.GET_ESTIMATES_SUCCESS:
			return {
				...state,
				estimatesList: action.payload.data,
				totalCount: action.payload.totalCount,
			};
		case ActionConstants.GET_ESTIMATES_ERROR:
			return {
				...state,
				estimatesList: [],
				totalCount: 0,
			};
		default:
			return state || {};
	}
};

export default estimatesReducer;
