import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => {
	return state.project;
};

export const getProjects = createSelector(selectState, (project) => ({
	projectsList: get(project, "projectList", []),
	totalCount: get(project, "totalCount", 0),
	projectsLoading: get(project, "projectsLoading", false),
}));

export const getProjectData = createSelector(selectState, (project) =>
	get(project, "projectData", {})
);

export const getProjectTimelines = createSelector(selectState, (project) => ({
	projectTimelines: get(project, "projectTimelines", []),
	projectTimelinesLoading: get(project, "projectTimelinesLoading", false),
}));

export const getProjectOverview = createSelector(selectState, (project) =>
	get(project, "projectOverview", {})
);

export const getProjectDynamicOptions = createSelector(
	selectState,
	(project) => {
		return {
			stage: get(project, "projectStatus", []).map(
				(status) => status.statusName
			),
			projectStatusId: get(project, "projectStatus", []).map((option) => ({
				key: option.projectStatusId,
				text: option.statusName,
			})),
		};
	}
);

export const getProjectSuppliers = createSelector(selectState, (project) =>
	get(project, "projectSuppliers", {
		totalCount: 0,
		suppliersList: [],
	})
);
