import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Checkbox,
	IconButton,
	Box,
	Typography,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import { get } from "lodash";
import NoResultsFound from "components/NoResults/NoResultsFound";
import Tooltip from "components/Tooltip/Tooltip";
import HiddenOn from "components/Wrappers/HiddenOn";

const MUIExpandableTable = ({
	columns,
	data = [],
	defineRowId,
	expandableContent,
	hasExpandbleContent = () => true,
	CustomPagination,
	totalCount = 0,
	page = 0,
	pageSize = 10,
	pagination = true,
	onPageChange = () => {},
	paginationProps = {},
	disableSelect,
	hideSelect = false,
}) => {
	const PaginationFooter = CustomPagination || TablePagination;

	const [expandedRows, setExpandedRows] = useState(new Set());
	const [selectedRows, setSelectedRows] = useState(new Set());

	const toggleExpand = (id) => {
		const newExpanded = new Set(expandedRows);
		if (newExpanded.has(id)) {
			newExpanded.delete(id);
		} else {
			newExpanded.add(id);
		}
		setExpandedRows(newExpanded);
	};

	const toggleSelect = (id) => {
		const newSelected = new Set(selectedRows);
		if (newSelected.has(id)) {
			newSelected.delete(id);
		} else {
			newSelected.add(id);
		}
		setSelectedRows(newSelected);
	};

	const toggleSelectAll = () => {
		if (selectedRows.size === data.length) {
			setSelectedRows(new Set());
		} else {
			setSelectedRows(new Set(data.map((row) => defineRowId(row))));
		}
	};

	const getWidth = (width = 100) => {
		if (typeof width === "string") {
			return width;
		}
		return `${width}px`;
	};

	const handlePagination = (pageModel) => {
		onPageChange(pageModel);
	};

	return (
		<TableContainer component={Paper}>
			<Table className="cstm-datagrid-table">
				<TableHead className="cstm-datagrid-table-header">
					<TableRow className="cstm-datagrid-table-row">
						<HiddenOn isHidden={hideSelect}>
							<TableCell
								padding="checkbox"
								className={`cstm-col-header cstm-col-header-select-all`}
								style={{ width: "10px" }}
							>
								<Checkbox
									checked={selectedRows.size === data.length}
									onChange={toggleSelectAll}
									indeterminate={
										selectedRows.size > 0 && selectedRows.size < data.length
									}
									disable={disableSelect}
								/>
							</TableCell>
						</HiddenOn>
						<TableCell
							style={{ width: "10px" }}
							className={`cstm-col-header header-toggle-expand`}
						></TableCell>
						{columns.map((column) => {
							return (
								<TableCell
									className={`cstm-col-header header-${column.field}`}
									style={{
										width: getWidth(column.width),
										maxWidth: getWidth(column.width),
									}}
								>
									<Tooltip title={column.headerName}>
										{column.headerName}
									</Tooltip>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.length > 0 ? (
						data.map((row, rowIndex) => (
							<React.Fragment key={defineRowId(row)}>
								<TableRow
									hover
									className={`cstm-data-row data-row-${rowIndex}`}
								>
									<HiddenOn isHidden={hideSelect}>
										<TableCell
											padding="checkbox"
											className="cstm-datagrid-data-col cstm-datagrid-data-col-checkbox"
											style={{ width: "10px" }}
										>
											<Checkbox
												checked={selectedRows.has(defineRowId(row))}
												onChange={() => toggleSelect(defineRowId(row))}
											/>
										</TableCell>
									</HiddenOn>
									<TableCell
										padding="checkbox"
										className="cstm-datagrid-data-col cstm-datagrid-data-col-expand"
										style={{ width: "10px" }}
									>
										{hasExpandbleContent(row) && (
											<IconButton
												size="small"
												onClick={() => toggleExpand(defineRowId(row))}
											>
												{expandedRows.has(defineRowId(row)) ? (
													<KeyboardArrowDown style={{ width: "30px" }} />
												) : (
													<KeyboardArrowRight style={{ width: "30px" }} />
												)}
											</IconButton>
										)}
									</TableCell>
									{columns.map((column) => {
										if (column.renderCell) {
											return (
												<TableCell
													style={{
														width: getWidth(column.width),
														maxWidth: getWidth(column.width),
													}}
													className={`cstm-datagrid-data-col data-col-${column.field}`}
												>
													{column.renderCell({
														row,
														id: defineRowId(row),
														column,
													})}
												</TableCell>
											);
										}
										if (column.field === "actions") {
											return (
												<TableCell
													className={`cstm-datagrid-data-col data-col-${column.field}`}
												>
													{column.getActions({
														row,
														id: defineRowId(row),
														column,
													})}
												</TableCell>
											);
										}
										const cellValue = column.valueFormatter
											? column.valueFormatter(
													get(row, column.field, ""),
													row,
													column.field
											  )
											: get(row, column.field, "-");
										return (
											<TableCell
												className={`cstm-datagrid-data-col data-col-${column.field}`}
												style={{
													width: getWidth(column.width),
													maxWidth: getWidth(column.width),
												}}
											>
												<Tooltip title={cellValue}>
													<Typography variant="body1">{cellValue}</Typography>
												</Tooltip>
											</TableCell>
										);
									})}
								</TableRow>
								{expandedRows.has(defineRowId(row)) && (
									<TableRow>
										<TableCell colSpan={12} sx={{ bgcolor: "#fafafa" }}>
											<Box sx={{ ml: 4 }}>{expandableContent({ row })}</Box>
										</TableCell>
									</TableRow>
								)}
							</React.Fragment>
						))
					) : (
						<NoResultsFound />
					)}
				</TableBody>
			</Table>
			<HiddenOn isHidden={!pagination}>
				<PaginationFooter
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={totalCount}
					page={page}
					rowsPerPage={pageSize}
					onPageChange={(event, newPage) => {
						handlePagination({ page: newPage, pageSize });
					}}
					onRowsPerPageChange={(event) => {
						const pageSize = parseInt(event.target.value, 10);

						handlePagination({ page: 0, pageSize });
					}}
					{...paginationProps}
				/>
			</HiddenOn>
		</TableContainer>
	);
};

export default MUIExpandableTable;
