import React, { useState } from "react";
import { isJobManager } from "core/utils/roleUtils";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  MenuItem,
  IconButton,
  Typography,
  TextField,
  Box,
  Chip,
  InputAdornment,
} from "@mui/material";
import {
  AssignmentOutlined,
  Attachment,
  Delete,
  EditOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import startCase from "lodash/startCase";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";

import BurgerMenu from "components/Menu/BurgerMenu";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import GeolocationField from "components/Location/GeoLocation";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import DatePickerField from "components/DateComponets/DatePicker";
import isUndefined from "lodash/isUndefined";
import HiddenOn from "components/Wrappers/HiddenOn";

import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { TaskStatus } from "pages/task/addTaskUtils";
const getTextContent = (key) => getText(`ads.addAds.${key}`);

const addCompanyValidationSchema = yup.object({
  productName: yup
    .string("Enter Product Name")
    .required("Product Name is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  warrantyStartDate: yup
    .string("Enter warranty StartDate")
    .required("Warranty StartDate is required"),
  //   servicesOffered: yup
  //     .string("Enter Services Offered")
  //     .required("Services Offered is required"),
  warrantyDuration: yup.string("Select Duration").required("Duration required"),
  supplierName: yup.string("Enter Supplier").required("Supplier required"),

  // budget: yup
  // 	.number("Enter total Budget")
  // 	.required("budget count is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("budget count must be an integer"),

  // costToDate: yup
  // 	.number("Enter cost till now")
  // 	.required("Cost is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("Cost must be an integer"),
});

let d = [];
for (var i = 1; i < 48; i++) {
  d.push(i.toString());
}
export const durationOptions = d;

export const AddAdsForm = {
  steps: Array.from(
    { length: 1 },
    (_, index) => `profile.contractor.step${index + 1}`
  ),
  stepDescription: {
    step0: {
      subHeading:
        "Please enter all the details of the Warranty that are required to be filled.",
    },
  },
  formFields: {
    step0: [
      {
        key: "advertiseName",
        placeholder: "Advertise Name",
        label: getTextContent("advertiseName"),
        type: "text",
        required: true,
        width: "100%",
      },

      {
        key: "advertiseImage",
        placeholder: getTextContent("advertiseImage"),
        label: getTextContent("advertiseImage"),
        type:"text",// "file", //"file",
        required: true,
        width: "100%",
      },
      {
        key: "validFrom",
        placeholder: getTextContent("validFrom"),
        label: getTextContent("validFrom"),
        type: "dateField",
        required: true,
        width: "100%",
      },
      {
        key: "validTo",
        placeholder: "Duration",
        label: getTextContent("validTo"),
        type: "dateField",
        //select: true,
        required: true,
        //options: durationOptions,
        width: "50%",
        //value: "12",
      },

      {
        key: "mktCompany",
        placeholder: getTextContent("mktCompany"),
        label: getTextContent("mktCompany"),
        type: "number",
        required: true,
        width: "100%",
      },
    ],
  },
  //validationSchema: addCompanyValidationSchema,
  defaultState: (defaultState) => {
    if (defaultState) {
      return defaultState;
    } else {
      const dynamicInitialState = Object.fromEntries(
        Object.keys(addCompanyValidationSchema.fields).map((key) => [key, ""])
      );

      return {
        ...dynamicInitialState,
      };
    }
  },
};

export const adsListColumns = (props = {}) => [
  { field: "advertiseName", headerName: "Advertise Name", width: 200 },
  {
    field: "advertiseImage",
    headerName: "Advertise Image",
    width: 150,
    renderCell: (params) => {
      return (
        <img
          src={`data:image/png;base64, ${params.row.advertiseImage}`}
          alt="Advertise"
          style={{ width: "100px", height: "60px", borderRadius: "5px" }}
        />
      );
    },
  },
  {
    field: "advertiseDuration",
    headerName: "Advertise Duration",
    width: 150,
  },

  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      const today = new Date();
      const validTo = new Date(params.row.validTo);
      const diffTime = Math.abs(validTo - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        return <WarrantyStatus status="Expiring soon" />;
      } else if (diffDays < 0) {
        return <WarrantyStatus status="Expired" />;
      } else {
        return <WarrantyStatus status="Active" />;
      }
    },
    width: 150,
  },
  {
    field: "validFrom",
    headerName: "valid From",
    width: 120,
    renderCell: (params) => {
      return formatDateToReadable(params.value);
    },
  },
  {
    field: "validTo",
    headerName: "valid To",
    renderCell: (params) => {
      return <> {params.value} Months </>;
    },
  },
  {
    field: "mktCompanyId",
    headerName: "mktCompany Id",
    width: 150,
  },
  {
    field: "actions",
    type: "actions",
    flex: 1,
    align: "right",
    getActions: (params) => [
      <BurgerMenu>
        <MenuItem
          onClick={() => {
            console.log("Check the Params : ", params);
            props.setEditJob(true);
            props.setAddNewJob(true);
            props.setSelectedJob({ id: params.warrantyId, data: params.row });
          }}
        >
          <EditOutlined /> <p className="m-0">Edit</p>
        </MenuItem>

        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.onCompanyDelete(params.id);
            }}
          >
            <Delete /> <p className="m-0">Delete</p>
          </MenuItem>
        </HiddenOn>
      </BurgerMenu>,
    ],
  },
];

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileToBase64() {
  const file = document.querySelector("#myfile").files[0];
  console.log(await getBase64(file));
}

export const WarrantyStatus = ({ status }) => {
  const statusColors = {
    "Expiring soon": "#FFBA08",
    Expired: "#FF5630",
    "Under Warranty": "#36B37E",
    Active: "#36B37E",
  };
  return (
    <>
      <div
        style={{
          border: `1px solid ${statusColors[status]}`,
          width: "8rem",
          height: "2rem",
          borderRadius: "5px",
        }}
        className="d-flex align-items-center "
      >
        <StatusIndicator
          color={statusColors[status]}
          className="ms-2 me-2 mb-1"
        />
        <Typography
          variant="body1"
          className="ms-2"
          style={{ color: "#0B2240", fontSize: "11px" }}
        >
          {startCase(status)}
        </Typography>
      </div>
    </>
  );
};

function formatDateToReadable(dateString) {
  const date = new Date(dateString);

  // Function to get the correct day suffix
  const getDaySuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${getDaySuffix(day)} ${month} ${year}`;
}

// Example usage
const formattedDate = formatDateToReadable("2024-11-12T00:00:00");
console.log(formattedDate); // Output: 12th Nov 2024
