import React, { useEffect } from "react";
import ExpandableDataGrid from "components/Table/ExpandableDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { jobGetAll } from "actions/jobActions";
import { get } from "lodash";
import NoResultsFound from "components/NoResults/NoResultsFound";
import { getJobsList } from "selectors/jobSelectors";
import { getMomentDate } from "core/utils/appUtils";
import { Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import DataGridTable from "components/Table/DataGrid";

export const ScheduledJobsList = ({ projectId, projectData }) => {
	const navigate = useNavigate();
	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	const { jobsList, totalCount } = useSelector(getJobsList);
	const location = useLocation();
	const { state = {} } = location;

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			jobGetAll({
				...pageModel,
				pageNumber: pageModel.page + 1,
				projectId: projectId,
			})
		);
	}, [dispatch, pageModel, projectId]);

	const columns = [
		{ field: "jobId", headerName: "Job ID", width: "10%" },
		{
			field: "jobName",
			headerName: "Job Name",
			width: "15%",
			renderCell: (rowData) => {
				return (
					<Typography
						className="pointer underline-text"
						variant="body2"
						onClick={() => {
							console.log("Check the Project Data", projectData);
							navigate(
								`/properties/${projectData.property.propertyId}/projects/${projectData.projectId}/jobs/${rowData.row.jobId}`,
								{
									state: {
										...state,
										jobId: rowData.row.jobId,
										jobName: rowData.row.jobName,
									},
								}
							);
							// navigate(`/jobs/${rowData.row.jobId}`);
							// props.setViewJob(rowData.row);
							// props.setSelectedJob({ id: rowData.id, data: rowData.row });
						}}
					>
						{rowData.row.jobName}
					</Typography>
				);
			},
		},
		// {field: "jobType", headerName: "Job Type"},
		{
			field: "startDate",
			headerName: "Start Date",
			width: "12%",
			renderCell: (row) => {
				const rowData = row.row;
				const value =
					rowData?.plannedStartDate ||
					rowData?.actualStartDate ||
					projectData.plannedStartDate;
				return (
					<div className="d-flex justify-content-between">
						<Typography variant="body1">{getMomentDate(value)}</Typography>
						<CalendarMonth style={{ fontSize: "16px" }} />
					</div>
				);
			},
		},
		{
			field: "jobEndDate",
			headerName: "End Date",
			width: "12%",
			renderCell: (row) => {
				const rowData = row.row;
				const value =
					rowData?.plannedEndDate ||
					rowData?.actualEndDate ||
					projectData.plannedStartDate;
				return (
					<div className="d-flex justify-content-between">
						<Typography variant="body1">{getMomentDate(value)}</Typography>
						<CalendarMonth style={{ fontSize: "16px" }} />
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Current Status",
			width: "15%",
			renderCell: ({ row }) => {
				const status = get(row, "jobStatus.statusName", "");
				return status;
			},
		},
		{
			field: "jobNotes",
			headerName: "Description",
			width: "40%",
			valueFormatter: (value) => {
				console.log("Check the Value : ", value);
				return value || "-";
			},
		},
	];

	const taskScheduleColumns = [
		{
			field: "taskName",
			headerName: "Task Name",
			width: 200,
		},
		{
			field: "plannedStartDate",
			headerName: "Start Date",
			width: 200,
			renderCell: (row) => {
				const rowData = row.row;
				const value =
					rowData?.plannedStartDate ||
					rowData?.actualStartDate ||
					projectData.plannedStartDate;
				return (
					<div className="d-flex justify-content-between">
						<Typography variant="body1">{getMomentDate(value)}</Typography>
						<CalendarMonth style={{ fontSize: "16px" }} />
					</div>
				);
			},
		},
		{
			field: "plannedEndDate",
			headerName: "End Date",
			width: 200,
			renderCell: (row) => {
				const rowData = row.row;
				const value =
					rowData?.plannedEndDate ||
					rowData?.actualEndDate ||
					projectData.plannedStartDate;
				return (
					<div className="d-flex justify-content-between">
						<Typography variant="body1">{getMomentDate(value)}</Typography>
						<CalendarMonth style={{ fontSize: "16px" }} />
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 200,
			renderCell: ({ row }) => {
				console.log("Status Row : ", row);
				const status = get(row, "taskStatus.statusName", "");
				return status;
			},
		},
		{
			field: "taskNotes",
			headerName: "Description",
			width: 200,
			valueFormatter: (value) => {
				return value || "-";
			},
		},
	];

	return (
		<>
			<div
				data-testid="scheduled-jobs-list-content-section"
				className="scheduled-jobs-list mt-3 p-2"
			>
				{totalCount > 0 ? (
					<ExpandableDataGrid
						data={jobsList}
						totalCount={totalCount}
						columns={columns}
						hideSelect={true}
						defineRowId={(row) => row.jobId}
						expandableContent={({ row }) => {
							const tasksList = get(row, "taskListResponse.tasks", []) || [];
							return (
								<>
									<div className="col-11">
										{tasksList.length > 0 ? (
											<DataGridTable
												data={tasksList}
												columns={taskScheduleColumns}
												showToolbar={false}
												pagination={false}
												checkboxSelection={false}
												defineRowId={(row) => {
													console.log("Check defineRowId:", row.taskId);
													return row.taskId;
												}}
											/>
										) : (
											<NoResultsFound
												message="No Tasks have been found."
												headerType="h5"
											/>
										)}
									</div>
								</>
							);
						}}
						{...pageModel}
						pageSize={1}
						onPageChange={(pageModel) => {
							console.log("Check the Page Model : ", pageModel);
							setPageModel(pageModel);
						}}
					/>
				) : (
					<NoResultsFound message="No jobs have been created." />
				)}
			</div>
		</>
	);
};

export default ScheduledJobsList;
