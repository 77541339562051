import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import * as XLSX from "xlsx";
import { server_url } from "../../constants/types";
import DataGridTable from "components/Table/DataGrid";
import {
  Close,
  Download,
  FilterList,
  SearchRounded,
} from "@mui/icons-material";
import { downloadFile, previewFileAction } from "actions/documentsActions";
import { useDispatch, useSelector } from "react-redux";
import { ModelDialog } from "components/Model";
import DocumentPreview from "../FileList/PreviewFile";
import { getPreviewUrl } from "selectors/documentsSelectors";
import "../FileList/files.scss";
import { makeStyles } from "@mui/styles";
import { formatBytes } from "core/utils/appUtils";
// import PopoverMenu from "components/Popover/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
};

const FileManagerList = ({
  title = "Uploaded Documents",
  showToolbar = false,
  files,
  selectedFiles,
  setSelectedFiles,
}) => {
  const dispatch = useDispatch();
  const [previewFile, setPreviewFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const classes = useStyles();

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon color="error" />;
      case "docx":
      case "csv":
        return <DescriptionIcon color="primary" />;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <SlideshowIcon color="secondary" />;
      case "png":
      case "jpeg":
      case "jpg":
        return <ImageIcon color="action" />;
      case "txt":
        return <TextSnippetIcon color="action" />;
      case "message/rfc822": // Email file
        return <EmailIcon color="info" />;
      default:
        return <InsertDriveFileIcon />; // Default file icon
    }
  };

  const getUploadIcon = (status) => {
    if (status === "uploading") {
      return <CircularProgress size={24} />;
    } else if (status === "completed") {
      return <CheckCircleIcon color="success" />;
    }
    return null;
  };

  const handlePreview = (file) => {
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      handleExcelPreview(file.url); // Use URL from the server
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      handleOfficePreview(file.url); // Use URL from the server
    } else {
      setPreviewFile(file);
    }
  };

  const handleExcelPreview = (url) => {
    fetch(url)
      .then((res) => res.arrayBuffer())
      .then((data) => {
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(jsonData);
        setPreviewFile({ name: url.split("/").pop(), type: "excel", url });
      });
  };

  const handleOfficePreview = (url) => {
    setPreviewFile({ name: url.split("/").pop(), url, type: "office" });
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewSelected, setPreviewSelected] = useState({});

  const previewURl = useSelector(getPreviewUrl);

  // useEffect(() => {
  // 	if (showPreview && previewSelected.documentId) {
  // 		dispatch(downloadFile(previewSelected));
  // 	}
  // }, [showPreview, previewSelected, dispatch]);

  const columns = [
    {
      field: "fileName",
      headerName: "File Name",
      sortable: false,
      width: 150,
      resizable: false,
    },
    {
      field: "documentLabel",
      headerName: "Document Label",
      sortable: false,
      width: 150,
      resizable: false,
    },
    {
      field: "documentType",
      headerName: "Document Type",
      sortable: false,
      width: 150,
      resizable: false,
      renderCell: (rowData) => {
        return (
          <Typography variant="body1">
            {rowData.row?.documentType?.documentType || "NA"}
          </Typography>
        );
      },
    },
    // {
    // 	field: "projectName",
    // 	headerName: "Project Name",
    // 	sortable: false,
    // 	width: 150,
    // 	resizable: false,
    // },
    // {
    //   field: "type",
    //   headerName: "Format",
    //   sortable: false,
    //   width: 150,
    //   resizable: false,
    // },
    {
      field: "fileSize",
      headerName: "Size",
      sortable: false,
      width: 50,
      resizable: false,
      renderCell: (rowData) => {
        return (
          <Typography variant="body1">
            {formatBytes(rowData.row.fileSize) || "NA"}
          </Typography>
        );
      },
    },
    {
      field: "createdBy",
      headerName: "Uploaded By",
      sortable: false,
      width: 150,
      resizable: false,
    },
    {
      field: "createdDate",
      headerName: "Uploaded on",
      sortable: false,
      width: 150,
      resizable: false,
      renderCell: (rowData) => {
        return (
          <Typography variant="body1">
            {formatDate(rowData.row.createdDate)}
          </Typography>
        );
      },
    },
    {
      field: "preview",
      headerName: "",
      sortable: false,
      width: 180,
      resizable: false,
      renderCell: (rowData) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="outlinedButton"
              onClick={() => {
                // handlePreview(rowData.row);
                setShowPreview(true);
                setPreviewSelected(rowData.row);
                dispatch(previewFileAction(rowData.row));
              }}
            >
              View
            </button>
            <button
              className="primaryButton d-flex align-items-center"
              onClick={() => {
                setPreviewSelected(rowData.row);
                dispatch(previewFileAction(rowData.row, true));
              }}
            >
              Download
              <Download style={{ fontSize: "20px" }} />
            </button>
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      width: 100,
      align: "right",
      getActions: (params) => {
        return [];
      },
    },
  ];

  useEffect(() => {}, [showPreview]);

  return (
    <Box sx={{ margin: "0 auto", paddingTop: 2 }} className="p-md-3 m-0">
      <div className="projects-content-section">
        <DataGridTable
          getRowHeight={() => "auto"}
          columns={columns}
          data={files}
          checkboxSelection
          defineRowId={(row) => {
            return row.documentId;
          }}
          getRowId={(row) => {
            return row.documentId;
          }}
          pagination={false}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedFiles(newSelection); // Update selected rows
          }}
          rowSelectionModel={selectedFiles} // Maintain selection state
          // page
          // totalCount={totalCount}
          // paginationModel={pageModel}
          // onPaginationChange={(props) => {
          // 	setPageModel(props);
          // }}
          showToolbar={false}
          className={classes.root}
        />
      </div>

      {previewFile && (
        <Dialog
          open={!!previewFile}
          onClose={() => setPreviewFile(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Preview: {previewFile.name}</DialogTitle>
          <DialogContent>
            {previewFile.type === "excel" && (
              <div>
                <Typography variant="h6">Excel File Preview</Typography>
                {excelData ? (
                  <table>
                    <thead>
                      <tr>
                        {Object.keys(excelData[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {excelData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((value, cellIndex) => (
                            <td key={cellIndex}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Typography>No data available.</Typography>
                )}
              </div>
            )}

            {previewFile.type === "office" && (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                  previewFile.url
                )}`}
                width="100%"
                height="500px"
                frameBorder="0"
                title="Office Preview"
              />
            )}

            {previewFile.type.startsWith("image/") && (
              <img
                src={previewFile.url}
                alt={previewFile.name}
                style={{ maxWidth: "100%" }}
              />
            )}
            {previewFile.type === "application/pdf" && (
              <>
                <a
                  href={encodeURI(
                    `${server_url}/Documents/Download?fileName=${previewFile.fileName}&folderName=${previewFile.filePath}&downloadPath=C:\Users\Venkatesh\Downloads`
                  )}
                >
                  Open the file
                </a>

                <h4>Testing</h4>
                {/* <embed
                  src={encodeURI(
                    `${server_url}/Documents/Download?fileName=${previewFile.fileName}&folderName=${previewFile.filePath}&downloadPath=C:\Users\Venkatesh\Downloads`
                  )}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                /> */}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      {previewURl && (
        <div className="preview-dialog">
          <ModelDialog
            fullWidth
            dialogClasses={{ dialog: "preview-model-container" }}
            open={showPreview && previewURl}
            handleClose={() => setShowPreview(false)}
            // title="Preview"
            dialogTitle={
              <div className="d-flex justify-content-between">
                <Typography className="h4">Preview</Typography>
                <IconButton onClick={() => setShowPreview(false)}>
                  <Close style={{ fontSize: "16px" }} />
                </IconButton>
              </div>
            }
            dialogContent={
              <DocumentPreview url={previewURl} file={previewSelected} />
            }
            style={{ height: "100%" }}
          />
        </div>
      )}
    </Box>
  );
};

export default FileManagerList;
