import React, { useContext, useState } from "react";
import {
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	Paper,
	Typography,
	Avatar,
	useMediaQuery,
} from "@mui/material";
import {
	AccountCircle,
	CreditCard,
	Receipt,
	Settings,
	Notifications,
	Business,
	ArrowBack,
} from "@mui/icons-material";
import "./profile.scss";
import MyAccount from "./myProfileComponents/MyAccount";
import MySubscriptions from "./myProfileComponents/MySubscriptions";
import CompanyProfileContent from "./myProfileComponents/CompanyProfile";
import BillingInformation from "./myProfileComponents/BillingInformation";
import SettingsContent from "./myProfileComponents/MySettings";
import NotificationSettings from "./myProfileComponents/NotificationSettings";
import HiddenOn from "components/Wrappers/HiddenOn";
import { useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import { isBuilderAdmin, roleConstants } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { get } from "lodash";
import { decodeToken } from "core/utils/appUtils";
// Mock user data
const defaultData = {
	name: "John Doe",
	email: "john.doe@example.com",
	phone: "+1 (555) 123-4567",
	avatar: "/api/placeholder/150/150",
	subscription: "Basic Plan",
	nextBilling: "2024-01-15",
	notifications: {
		email: true,
		push: false,
		marketing: true,
	},
};

const menuItems = [
	{
		text: "My Account",
		icon: <AccountCircle className="my-profile-menu-icon" />,
		id: "account",
	},
	{
		text: "Company Profile",
		icon: <Business className="my-profile-menu-icon" />,
		id: "companyProfile",
		shouldHide: (appRoleDetails) => {
			return (
				!isBuilderAdmin(appRoleDetails) ||
				appRoleDetails.roleName === roleConstants.SYSTEM_ADMIN
			);
		},
	},
	{
		text: "Subscriptions",
		icon: <CreditCard className="my-profile-menu-icon" />,
		id: "subscription",
		shouldHide: (appRoleDetails) => {
			return (
				!isBuilderAdmin(appRoleDetails) ||
				appRoleDetails.roleName === roleConstants.SYSTEM_ADMIN
			);
		},
	},
	{
		text: "Billing Information",
		icon: <Receipt className="my-profile-menu-icon" />,
		id: "invoices",
		shouldHide: (appRoleDetails) => {
			return (
				!isBuilderAdmin(appRoleDetails) ||
				appRoleDetails.roleName === roleConstants.SYSTEM_ADMIN
			);
		},
	},
	{
		text: "Account Settings",
		icon: <Settings className="my-profile-menu-icon" />,
		id: "settings",
		shouldHide: () => {
			const tokenData = decodeToken();

			console.log("Check the Token Data", tokenData);
		},
	},
	{
		text: "Notifications",
		icon: <Notifications className="my-profile-menu-icon" />,
		id: "notifications",
	},
];

const renderContent = (selectedId, userData = defaultData) => {
	switch (selectedId) {
		case "account":
			return <MyAccount userData={userData} />;
		case "subscription":
			return <MySubscriptions userData={userData} />;
		case "invoices":
			return <BillingInformation userData={userData} />;
		case "settings":
			return <SettingsContent userData={userData} />;
		case "notifications":
			return <NotificationSettings userData={userData} />;
		case "companyProfile":
			return <CompanyProfileContent userData={userData} />;
		default:
			return <MyAccount userData={userData} />;
	}
};

const ProfilePage = () => {
	const [selectedOption, setSelectedOption] = useState(menuItems[0]);

	const appRoleDetails = useContext(AppRoleContext);

	return (
		<Paper className="my-profile-container">
			<Box className="d-flex">
				<Box className="col-4">
					<List component="nav" className="p-2">
						{menuItems.map((item) => {
							const hiddenCon = get(item, "shouldHide", () => false);
							const shouldShow = hiddenCon(appRoleDetails);
							return (
								<HiddenOn isHidden={shouldShow}>
									<ListItem
										key={item.id}
										button
										onClick={() => setSelectedOption(item)}
										className={
											selectedOption.id === item.id
												? "my-profile-menu myProf-active-menu"
												: "my-profile-menu myProf-inactive-menu"
										}
									>
										<ListItemIcon
											className={
												selectedOption.id === item.id
													? "text-blue-600"
													: "text-gray-600"
											}
										>
											{item.icon}
										</ListItemIcon>
										<ListItemText
											primary={item.text}
											className={
												selectedOption.id === item.id
													? "text-blue-600"
													: "text-gray-700"
											}
										/>
									</ListItem>
								</HiddenOn>
							);
						})}
					</List>
				</Box>
				<Divider
					orientation="vertical"
					flexItem
					className="divider"
					style={{
						opacity: 1,
						marginRight: "1rem",
						width: "0",
						height: "auto",
					}}
				/>
				<div className="col-8">{renderContent(selectedOption.id)}</div>
			</Box>
		</Paper>
	);
};

export const ProfileResponsiveLayout = () => {
	const isMobile = useMediaQuery("(max-width:600px)");
	return isMobile ? <RenderMobileLayout /> : <ProfilePage />;
};

export const RenderMobileLayout = () => {
	const [selectedOption, setSelectedOption] = useState(menuItems[0]);
	const [showMenuContent, setShowMenuContent] = useState(false);

	const userDetails = useSelector(getUserProfileDetails);

	const appRoleDetails = useContext(AppRoleContext);

	const userName = userDetails.userName;
	return (
		<Paper className="my-profile-container ">
			<HiddenOn
				isHidden={showMenuContent}
				fallback={
					<div className="col-12">
						<div className="ms-3 mt-1 pt-2">
							<button
								className={"outlinedButton w-auto"}
								style={{ width: "auto" }}
								onClick={() => {
									setShowMenuContent(false);
								}}
							>
								<ArrowBack
									style={{ fontSize: "14px", marginRight: "0.5rem" }}
								/>
								Profile Menu
							</button>
						</div>
						{renderContent(selectedOption.id)}
					</div>
				}
			>
				<div
					className="pt-2 align-center justify-items-center col-12"
					style={{ justifyItems: "center" }}
				>
					<Avatar fontSize="medium" src={defaultData.avatar} />
					<Typography className="h6">Hello {userName}</Typography>
				</div>
				<Box className="col-12">
					<List component="nav" className="p-2">
						{menuItems.map((item) => {
							const hiddenCon = get(item, "shouldHide", () => false);
							const shouldShow = hiddenCon(appRoleDetails);
							return (
								<HiddenOn isHidden={shouldShow}>
									<ListItem
										key={item.id}
										button
										onClick={() => {
											setSelectedOption(item);
											setShowMenuContent(true);
										}}
										className={
											selectedOption.id === item.id
												? "my-profile-menu myProf-active-menu"
												: "my-profile-menu myProf-inactive-menu"
										}
									>
										<ListItemIcon
											className={
												selectedOption.id === item.id
													? "text-blue-600"
													: "text-gray-600"
											}
										>
											{item.icon}
										</ListItemIcon>
										<ListItemText
											primary={item.text}
											className={
												selectedOption.id === item.id
													? "text-blue-600"
													: "text-gray-700"
											}
										/>
									</ListItem>
								</HiddenOn>
							);
						})}
					</List>
				</Box>
			</HiddenOn>
		</Paper>
	);
};

export default ProfileResponsiveLayout;
