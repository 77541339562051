import { Typography } from "@mui/material";
import React from "react";
import "./noResults.scss";

export const NoResultsFound = ({ message, headerType = "h1" }) => {
	return (
		<div className="no-results-message ">
			<Typography className={headerType}>
				{message || "No Results Found"}
			</Typography>
		</div>
	);
};

export default NoResultsFound;
