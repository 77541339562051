import React, { useState, useRef, useEffect } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Alert, CircularProgress } from "@mui/material";
import { AlertHeading } from "react-bootstrap";
import styled from "styled-components";

const DownloadPdf = ({
	shouldSavePDF,
	onDownloadComplete,
	fileName = "document.pdf",
	children,
	paperSize = "A4",
	scale = 0.5,
	margin = "0cm",
}) => {
	const pdfExportRef = useRef(null);
	const [isExporting, setIsExporting] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		let timeoutId;

		const exportPDF = async () => {
			if (shouldSavePDF && pdfExportRef.current) {
				setIsExporting(true);
				setError(null);

				try {
					// Wait for any animations or renders to complete
					timeoutId = setTimeout(async () => {
						try {
							await pdfExportRef.current.save();
							onDownloadComplete?.();
						} catch (err) {
							setError("Failed to generate PDF. Please try again.");
							console.error("PDF Export Error:", err);
						} finally {
							setIsExporting(false);
						}
					}, 1000);
				} catch (err) {
					setError("Failed to initialize PDF export. Please try again.");
					console.error("PDF Init Error:", err);
					setIsExporting(false);
				}
			}
		};

		exportPDF();

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [shouldSavePDF, onDownloadComplete]);

	return (
		<div className="relative text-center">
			{isExporting && (
				<div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
					<CircularProgress className="mb-4" />
					<p className="text-gray-700">Generating PDF...</p>
				</div>
			)}

			{error && (
				<Alert variant="destructive" className="mb-4">
					<AlertHeading>{error}</AlertHeading>
				</Alert>
			)}
			<PDFContainer>
				<PDFExport
					ref={pdfExportRef}
					paperSize={paperSize}
					fileName={fileName}
					scale={scale}
					margin={margin}
					author="Buildercopilot estimation tool"
					creator="Buildercopilot estimation tool"
					date={new Date()}
				>
					<div className="pdf-content">{children}</div>
				</PDFExport>
			</PDFContainer>
		</div>
	);
};

export const PDFContainer = styled.div`
	position: absolute;
	left: -2500px;
	top: 0;
	width: 1166px;
`;

export default DownloadPdf;
