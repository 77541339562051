import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.app;

export const appLoadingSelector = createSelector(selectState, (app) =>
	get(app, "loading", false)
);
export const loginErrorSelector = createSelector(selectState, (app) =>
	get(app, "loginError", null)
);

export const getUserProfileDetails = createSelector(selectState, (app) =>
	get(app, "userDetails", {})
);

export const getDashboardDetails = createSelector(selectState, (app) =>
	get(app, "dashboardData", {})
);

export const getAppRole = createSelector(getUserProfileDetails, (user) => {
	return {
		roleCategory: get(user, "role.applUserCategory", "default"),
		roleName: get(user, "role.applRoleName", "default"),
	};
});

export const getGlobalSearch = createSelector(selectState, (app) => ({
	searchResults: get(app, "globalSearchResults", []),
	loadingResults: get(app, "fetchingGlobalSearch", false),
}));

export const getApiError = createSelector(selectState, (app) => ({
	error: get(app, "error", null),
}));

export const getAlertNotifications = createSelector(selectState, (app) =>
	get(app, "alertNotifications", [])
);

export const getEmailFromGuid = createSelector(selectState, (app) => ({
	email: get(app, "emailFromGuid", ""),
	guidError: get(app, "guidError", null),
}));

export const getAppConfig = createSelector(selectState, (app) =>
	get(app, "appConfig", {})
);

export const getAppConfigVoiceAssistantEnabled = createSelector(
	selectState,
	(app) => get(app, "appConfig.voiceAssistantEnabled", false)
);

export const getAppApiAlert = createSelector(selectState, (app) => {
	return get(app, "apiAlert", {});
});

export const getAppConfigVoiceAssistantConfig = createSelector(
	selectState,
	(app) => get(app, "voiceAssistantConfig", {})
);

export const getVoiceAssistantMicrophoneAccess = createSelector(
	selectState,
	(app) => get(app, "voiceAssistantMicrophoneAccess", false)
);
