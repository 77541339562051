import { Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { fetchUsers, inviteNewUser } from "actions/userActions";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import BurgerMenu from "components/Menu/BurgerMenu";
import { ModelDialog } from "components/Model";
import DataGridTable from "components/Table/DataGrid";
import HiddenOn from "components/Wrappers/HiddenOn";
import { emailRegex } from "constants/appConstants";
import AppRoleContext from "context/AppRoleContext";
import { isProjectManager, roleConstants } from "core/utils/roleUtils";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalUsersCount, getUsersList } from "selectors/userSelector";
import InviteUsers from "./InviteUsers";
import EditUsers from "./EditUsers";

export const UsersList = () => {
	const dispatch = useDispatch();
	const appRoleDetails = useContext(AppRoleContext);

	const usersList = useSelector(getUsersList);
	const totalCount = useSelector(getTotalUsersCount);
	const [newUserModel, setNewUserModel] = useState(false);
	const [toast, setToastMessage] = useState({});
	const [showToast, setShowToast] = useState(false);
	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	const [editUser, setEditUser] = useState(null);
	const [showEdit, setShowEdit] = useState(false);

	const fetchUsersList = useCallback(() => {
		dispatch(fetchUsers({ ...pageModel, pageNumber: pageModel.page + 1 }));
	}, [dispatch, pageModel]);

	useEffect(() => {
		fetchUsersList();
	}, [fetchUsersList]);

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 100,
		},
		{
			field: "firstName",
			headerName: "First Name",
			width: 200,
			valueFormatter: (_, row) => {
				return `${row.firstName} ${row.lastName}`;
			},
			// ,
		},
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "mobileNumber",
			headerName: "Contact Number",
			width: 200,
		},
		{
			field: "userType",
			headerName: "Role",
			width: 200,
		},
		{
			field: "actions",
			type: "actions",
			flex: 1,
			width: 100,
			align: "right",
			getActions: (params) => [
				<BurgerMenu>
					<MenuItem
						onClick={() => {
							console.log("Check the Params : ", params);
							setShowEdit(true);
							setEditUser(params.row);
						}}
					>
						<EditOutlined /> <p className="m-0">Edit Details</p>
					</MenuItem>
					<MenuItem
						onClick={() => {
							console.log("Check the Params : ", params);
						}}
						disabled
					>
						<DeleteOutline /> <p className="m-0">Remove User</p>
					</MenuItem>
				</BurgerMenu>,
			],
		},
	];

	return (
		<>
			<ModelDialog
				open={showEdit}
				handleClose={() => {
					setShowEdit(false);
					setEditUser(null);
				}}
				dialogContent={
					<EditUsers
						userDetails={editUser}
						onUpdateSuccess={() => {
							fetchUsersList();
							setShowEdit(false);
							setEditUser(null);
						}}
					/>
				}
			/>
			<SnackBarWithInfoAlert
				open={showToast}
				alertSeverity={toast.status}
				alertMessage={toast.message}
				handleClose={() => {
					setShowToast(false);
					setToastMessage({});
				}}
			/>
			<ModelDialog
				open={newUserModel}
				dialogContent={
					<AddNewUser
						setNewUserModel={setNewUserModel}
						onInviteSuccess={(payload) => {
							setShowToast(true);
							setToastMessage(payload);
						}}
					/>
				}
				handleClose={() => setNewUserModel(false)}
			/>
			<div className="user-list-container p-2">
				<div className="user-list-header d-flex justify-content-between">
					<div>
						<Typography className="h1">Users</Typography>
						<Typography className="body1">
							Showing list of all the users associated with this account
						</Typography>
					</div>
					<HiddenOn
						isHidden={
							appRoleDetails.roleName === roleConstants.SYSTEM_ADMIN ||
							!isProjectManager(appRoleDetails)
						}
					>
						<div className="align-self-center">
							<button
								className="primaryButton mt-3"
								onClick={() => {
									setNewUserModel(true);
								}}
							>
								Add New User
							</button>
						</div>
					</HiddenOn>
				</div>
				<div className="user-list-section mt-3 p-3">
					<DataGridTable
						columns={columns}
						data={usersList}
						defineRowId={(row) => row.userId}
						checkboxSelection={false}
						showToolbar={false}
						pagination={totalCount > 10}
						totalCount={totalCount}
						paginationModel={pageModel}
						onPaginationChange={(props) => {
							setPageModel(props);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export const AddNewUser = ({ setNewUserModel, onInviteSuccess }) => {
	const dispatch = useDispatch();
	const [emailId, setEmailId] = useState("");

	return (
		<>
			<div>
				<div className="d-flex justify-content-between">
					<Typography className="body1 mb-3">
						<strong>
							Invite User by sending an email link to create account{" "}
						</strong>
					</Typography>
					<IconButton
						onClick={() => {
							setNewUserModel(false);
						}}
						style={{ fontSize: "12px" }}
					>
						<Close style={{ fontSize: "1.5rem", marginTop: "-4px" }} />
					</IconButton>
				</div>

				<div className="">
					<label>
						<strong>User Email</strong>
					</label>
					<TextField
						placeholder="Enter Email"
						className={`form-field mt-2`}
						type="email"
						style={{ width: "90%" }}
						onChange={(event) => {
							setEmailId(event.target.value);
						}}
					/>
				</div>
				<div>
					<button
						className={`primaryButton mt-3 ${
							emailId && !emailRegex.test(emailId) ? "disabled" : ""
						}`}
						style={{ width: "90%" }}
						disabled={emailId && !emailRegex.test(emailId)}
						onClick={() => {
							dispatch(
								inviteNewUser(emailId, (payload) => {
									setNewUserModel(false);
									onInviteSuccess(payload);
								})
							);
						}}
					>
						Send Invite
					</button>
				</div>
				{emailId && !emailRegex.test(emailId) && (
					<div>
						<Typography
							variant="body1"
							className="mt-2"
							style={{ color: "red", fontSize: "12px" }}
						>
							Enter Valid Email to send an invite
						</Typography>
					</div>
				)}
			</div>
		</>
	);
};

export default UsersList;
