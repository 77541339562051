import { DeleteOutline } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { fetchUsers } from "actions/userActions";
import BurgerMenu from "components/Menu/BurgerMenu";
import ManageUsers from "./ManageUsers";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ProjectTeamList from "./ProjectTeamList";

export const UserManagement = ({ projectId }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	return (
		<>
			<div className="user-list-container p-2">
				<div>
					<ManageUsers
						buttonText="Add to Team"
						headerTitle="Add members to team"
						projectRefId={projectId}
					/>
				</div>
				<ProjectTeamList projectRefId={projectId} />
			</div>
		</>
	);
};

export default UserManagement;
