// import { CancelRounded, CheckRounded, Search } from "@mui/icons-material";
import {
	FormLabel,
	IconButton,
	// Autocomplete,
	// CircularProgress,
	// Grid,
	// InputAdornment,
	Paper,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	TextField,
	// TextField,
	Typography,
} from "@mui/material";
import { getConnectionsAction } from "actions/companiesActions";
import RenderTabs from "components/Tabs/Tabs";
import { getText } from "core/utils/getContentText";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { connectionsSelector } from "selectors/companiesSelectors";
// import { getConnectionsSelector } from "selectors/connectionSelectors";
import "./connections.scss";
// import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import NoResultsFound from "components/NoResults/NoResultsFound";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import "components/Table/dataGridTable.scss";
import {
	CancelRounded,
	CheckRounded,
	Close,
	GroupAddOutlined,
} from "@mui/icons-material";
import {
	addNewConnection,
	updateConnectionStatus,
} from "actions/companiesActions";
import { decodeToken } from "core/utils/appUtils";
import { emailRegex, getConnType } from "constants/appConstants";
import get from "lodash/get";
import Tooltip from "components/Tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import { ModelDialog } from "components/Model";
import { signUpEmailVerify } from "actions/login_registrationActions";
import { isProjectManager } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { inviteNewUser } from "actions/userActions";

const RenderConnections = ({ tabConfig, type, selectedTab }) => {
	const dispatch = useDispatch();
	const tabData = useSelector(connectionsSelector);
	const navigate = useNavigate();
	// const appRoleDetails = useContext(AppRoleContext);

	const companiesList = tabData[tabConfig.key] || [];

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	// const [addNewConnection, setAddNewConnection] = React.useState(false);
	const [toaster, setToaster] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const onSuccess = (payload) => {
		setShowAlert(true);
		setToaster({
			open: true,
			message: payload.message,
			type: payload.type,
		});
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	const columns = [
		{
			id: "companyName",
			label: "Company Name",
			minWidth: 200,
			format: ({ value, company }) => (
				<Tooltip title={"Click to view company"} placement="top">
					<Typography
						className="h5 link-text"
						onClick={() =>
							navigate(`/companies/viewCompany/${company.companyId}`)
						}
					>
						{value}
					</Typography>
				</Tooltip>
			),
		},
		{
			id: "companyType",
			label: "Type",
			minWidth: "auto",
		},
		{
			id: "marketSectors",
			label: "Sectors",
			minWidth: "auto",
		},
		{
			id: "suppliersAndServices",
			label: "Services",
			minWidth: "auto",
		},
		{
			id: "serviceAreas",
			label: "Service Areas",
			minWidth: "auto",
		},
		{
			id: "connect",
			label: "",
			minWidth: "auto",
			format: ({
				value,
				company,
				tabConfig,
				dispatch,
				type,
				onSuccess,
				selectedTab,
			}) => {
				const { CompanyId } = decodeToken();
				const clientOrgId = String(
					get(company, "connection.clientOrganizationId", null)
				);
				const payload =
					type === "builder"
						? {
								clientOrganizationId: CompanyId,
								supplierOrganizationId: company.companyId,
						  }
						: {
								clientOrganizationId: company.companyId,
								supplierOrganizationId: CompanyId,
						  };

				if (tabConfig.key === "newConnections") {
					return (
						<div style={{ textAlign: "center", marginTop: "0.8rem" }}>
							<p>
								<button
									className="primaryButton"
									style={{
										width: "5rem",
										height: "2rem",
										fontWeight: 500,
										borderRadius: "1rem",
									}}
									onClick={() => {
										dispatch(
											addNewConnection(
												{
													...payload,
													connectionStatusId: 7,
												},
												onSuccess,
												{ ConnectionStatus: getConnType[selectedTab] }
											)
										);
									}}
								>
									+ Connect
								</button>
							</p>
						</div>
					);
				}
				if (tabConfig.key === "pendingConnections") {
					const {
						connection: { connectionId },
					} = company;
					return (
						<>
							{clientOrgId === CompanyId ? (
								<button
									className="primaryButton"
									disabled
									style={{
										width: "5rem",
										height: "2rem",
										fontWeight: 500,
										borderRadius: "1rem",
										margin: "0.7rem",
										cursor: "default",
									}}
								>
									Pending
								</button>
							) : (
								<button
									className="outlinedButton"
									style={{
										width: "5rem",
										height: "2rem",
										fontWeight: 500,
										borderRadius: "1rem",
										margin: "0.7rem",
									}}
									onClick={() => {
										dispatch(
											updateConnectionStatus(
												{
													...payload,
													clientOrganizationId: company.companyId,
													supplierOrganizationId: CompanyId,
													connectionStatusId: 6,
												},
												connectionId,
												onSuccess,
												{ ConnectionStatus: getConnType[selectedTab] }
											)
										);
									}}
								>
									<CheckRounded style={{ fontSize: "14px", color: "green" }} />
									Approve
								</button>
							)}
							<button
								className="outlinedButton"
								style={{
									borderColor: "red",
									width: "5rem",
									color: "red",
									marginLeft: "5px",
									height: "2rem",
									borderRadius: "1rem",
									margin: "0.7rem",
								}}
								onClick={() => {
									dispatch(
										updateConnectionStatus(
											{
												...payload,
												connectionStatusId: 8,
											},
											connectionId,
											onSuccess,
											{ ConnectionStatus: getConnType[selectedTab] }
										)
									);
								}}
							>
								<CancelRounded style={{ fontSize: "14px", color: "red" }} />{" "}
								{clientOrgId === CompanyId ? "Revert" : "Reject"}
							</button>
						</>
					);
				}
				return "";
			},
		},
	];

	return companiesList.length === 0 ? (
		<NoResultsFound />
	) : (
		<div>
			<SnackBarWithInfoAlert
				open={showAlert}
				alertSeverity={toaster.type}
				alertMessage={toaster.message}
				handleClose={() => {
					setShowAlert(false);
				}}
			/>
			<div>
				{/* <Typography className={"h5"}>Available Connections</Typography> */}
			</div>
			<div>
				{/* <Grid container>
					<Typography
						className="h6 mr-2 align-content-center"
						variant="subtitle1"
					>
						Search Connections:
					</Typography>
					<TextField
						className="form-field ml-3"
						variant="outlined"
						style={{ width: "30%", marginLeft: "1rem" }}
						placeholder={"Company Name/Type/Location"}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									style={{ cursor: "pointer" }}
									onClick={() => {
										console.log("Search clicked");
									}}
								>
									<Search style={{ fontSize: "2rem" }} />
								</InputAdornment>
							),
						}}
					/>
				</Grid> */}
			</div>
			<div className="projects-content-section">
				<HiddenOn isHidden={companiesList.length === 0}>
					{/* <Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-5"> */}
					<TableContainer
						// sx={{ maxHeight: 440 }}
						className="mt-5"
						component={Paper}
					>
						<Table
							stickyHeader
							aria-label="sticky table"
							className="cstm-datagrid-table"
						>
							<TableHead
								className="cstm-datagrid-table-header"
								style={{ background: "lightgray !important" }}
							>
								<TableRow className="cstm-datagrid-table-row">
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{
												minWidth: column.minWidth,
												color: "#0B2240",
												// color: "white",
											}}
											className={`cstm-col-header`}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{companiesList
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}
												className={`cstm-data-row`}
											>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell
															key={column.id}
															align={column.align}
															className="cstm-datagrid-data-col"
														>
															{column.format
																? column.format({
																		value,
																		company: row,
																		tabConfig,
																		dispatch,
																		type,
																		onSuccess,
																		selectedTab,
																  })
																: value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={companiesList.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
					{/* </Paper> */}
				</HiddenOn>
			</div>
		</div>
	);
};

const tabs = [
	{
		key: "pendingConnections",
		label: "Pending",
		TabComponent: RenderConnections,
	},
	{
		key: "newConnections",
		label: "New Connections",
		TabComponent: RenderConnections,
	},
	{
		key: "connected",
		label: "Connected",
		TabComponent: RenderConnections,
	},
];

export const Connections = ({ type }) => {
	const [selectedTab, updateTab] = React.useState("pendingConnections");
	const [openInvite, setOpenInvite] = React.useState(false);
	const dispatch = useDispatch();

	const appRoleDetails = useContext(AppRoleContext);

	useEffect(() => {
		dispatch(
			getConnectionsAction({ ConnectionStatus: getConnType[selectedTab] })
		);
	}, [dispatch, selectedTab]);

	return (
		<>
			<ModelDialog
				open={openInvite}
				dialogContent={
					<InviteNewConnection
						handleClose={() => {
							setOpenInvite(false);
						}}
					/>
				}
				handleClose={() => setOpenInvite(false)}
			/>

			<div className="p-3">
				<div>
					<div className="d-flex flex-wrap justify-content-between">
						<Typography className="h2">Connections </Typography>
						<HiddenOn isHidden={!isProjectManager(appRoleDetails)}>
							<button
								className="primaryButton w-auto"
								onClick={() => {
									setOpenInvite(true);
								}}
							>
								<GroupAddOutlined
									style={{ fontSize: "1.5rem" }}
									className="me-2"
									onClick={() => {
										setOpenInvite(true);
									}}
								/>
								Invite Connection
							</button>
						</HiddenOn>
					</div>

					<Typography variant="body1">
						{getText(`connections.${type}Description`)}
					</Typography>
				</div>

				<div className="project-tabs tabs mt-5">
					<RenderTabs
						tabs={tabs}
						selectedTab={selectedTab}
						onTabClick={(newTab) => {
							updateTab(newTab);
						}}
						tabParams={{ type, selectedTab }}
					/>
				</div>
			</div>
		</>
	);
};

export const InviteNewConnection = ({ handleClose }) => {
	const [email, setEmail] = useState("");
	const [successPayload, setSuccessPayload] = useState({});
	const dispatch = useDispatch();

	const sendInviteLink = () => {
		dispatch(
			inviteNewUser(
				email,
				(payload) => {
					setSuccessPayload(payload);
				},
				"inviteConnection"
			)
		);
	};

	return (
		<>
			<div>
				<div className="d-flex justify-content-between align-items-center">
					<Typography className="h5">
						Invite New Connection to Builder Copilot
					</Typography>
					<IconButton onClick={handleClose}>
						<Close fontSize="small" />
					</IconButton>
				</div>
				<div>
					<Typography
						variant="body1"
						style={{
							fontSize: "1rem",
							fontStyle: "italic",
							lineHeight: " 1.3",
						}}
					>
						Easily grow your network by inviting new connections to join Builder
						Copilot. They’ll receive an invitation link to create an account.
						Once their account is created, they’ll be directly connected to your
						network, helping you collaborate and build together seamlessly.
					</Typography>
				</div>
				{successPayload.message && (
					<div className="mt-3">
						<Typography
							variant="body1"
							style={{ color: successPayload.emailExists ? "red" : "green" }}
						>
							<strong>
								{successPayload.emailExists
									? "Given email has already having Builder Copilot account, Please search for available Connections"
									: "Email invite sent successfully, Please ask the user to check their email and create an account"}
							</strong>
						</Typography>
					</div>
				)}
				<div className="">
					<FormLabel className="mt-3 me-2 h4">Company Email</FormLabel>
					<div className="d-flex flex-wrap">
						<div className="col-md-9 col-12">
							<TextField
								className="form-field "
								variant="outlined"
								type="email"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</div>
						<button
							className={`primaryButton col-md-3 col-12 ms-md-3 mt-md-0 mt-3 ${
								email && !emailRegex.test(email) ? "disabled" : ""
							}`}
							onClick={() => {
								sendInviteLink();
							}}
							disabled={email && !emailRegex.test(email)}
						>
							Send Invite
						</button>
					</div>
					{email && !emailRegex.test(email) && (
						<div>
							<Typography
								variant="body1"
								className="mt-2"
								style={{ color: "red", fontSize: "12px" }}
							>
								Enter Valid Email to send an invite
							</Typography>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Connections;
