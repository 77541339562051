import {
	AssignmentOutlined,
	Close,
	CloseOutlined,
	DeleteOutline,
	EditOutlined,
	Task,
} from "@mui/icons-material";
import {
	IconButton,
	Typography,
	Grid,
	LinearProgress,
	Hidden,
} from "@mui/material";
import { fetchJobById, jobDelete } from "actions/jobActions";
import { getMomentDate, scrollToTop } from "core/utils/appUtils";
import { get } from "lodash";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";
import ManageJobUsers from "pages/projects/tabComponents/jobs/AssignedTo";
import JobAssignment from "pages/projects/tabComponents/jobs/JobAssignment";
import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ViewTask } from "routes/loadables";
import { getjobData } from "selectors/jobSelectors";
import AddEditJob from "pages/job/AddNewJob";
import AddEditTask from "pages/task/AddNewTask";
import { isProjectManager } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles, bulkUploadFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import "./job.scss";
import HiddenOn from "components/Wrappers/HiddenOn";
import JobTasks from "pages/projects/tabComponents/tasks/JobTasks";
import { taskGetAll } from "actions/taskActions";
import { getTasks } from "selectors/taskSelectors";
import { ModelDialog } from "components/Model";

export const ViewJob = ({ jobId = null, projectId, onJobCancel }) => {
	const params = useParams();
	const dispatch = useDispatch();

	const jobData = useSelector(getjobData);
	const { tasksList } = useSelector(getTasks);
	const [selectedTask, setSelectedTask] = React.useState(null);
	const [editJob, setEditJob] = React.useState(false);
	const [addNewTask, setAddNewTask] = React.useState(false);
	const [userManagement, setUserManagement] = React.useState(false);

	// const tasksList = get(jobData, "taskListResponse.tasks", []) || [];

	useEffect(() => {
		dispatch(fetchJobById(params.jobId || jobId));
		dispatch(taskGetAll({ jobId: params.jobId || jobId }));
	}, [dispatch, params.jobId, jobId]);

	if (addNewTask) {
		return (
			<AddEditTask
				onCancel={() => {
					setAddNewTask(false);
					dispatch(fetchJobById(params.jobId || jobId));
				}}
				parentId={{ jobId: jobData.jobId }}
				jobId={jobData.jobId || params.jobId || jobId}
				projectId={jobData.projectId}
			/>
		);
	}

	return selectedTask && selectedTask.taskId ? (
		<>
			<ViewTask
				task={selectedTask}
				job={jobData}
				projectId={projectId}
				onCancel={() => {
					setSelectedTask(null);
					// setTaskOpen(false);
				}}
			/>
		</>
	) : editJob ? (
		<>
			<AddEditJob
				jobId={params.jobId || jobId}
				selectedJob={{ data: jobData }}
				projectId={{ projectId }}
				title={`Edit Details for "${get(jobData, "jobName", null)}"`}
				propData={{ projectId: projectId }}
				onCancel={() => {
					setEditJob(false);
				}}
				onSave={() => {
					dispatch(fetchJobById(params.jobId || jobId));
				}}
			/>
		</>
	) : (
		<div className="p-2 p-md-3">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography className="h4 mb-4">{"Assign Job"}</Typography>
						<IconButton
							onClick={() => {
								setUserManagement(false);
							}}
						>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<>
						<JobAssignment
							jobRefId={params.jobId || jobId}
							projectId={projectId}
							jobId={jobData.jobId}
							jobData={jobData}
							defaultPayload={{ projectId: projectId }}
							handleClose={() => {
								setUserManagement(false);
								dispatch(fetchJobById(params.jobId || jobId));
							}}
						/>
					</>
				}
			/>
			<div className="d-flex justify-content-between">
				<Typography className="h1 mt-3">{jobData.jobCode}</Typography>
				<IconButton>
					<Close
						onClick={() => {
							if (onJobCancel) {
								onJobCancel();
							}
						}}
					/>
				</IconButton>
			</div>
			<div className="d-flex justify-content-end me-md-4 me-0">
				<button
					className="primaryButton icon-btn ms-md-2 ms-1"
					style={{ width: "auto" }}
					onClick={() => {
						setUserManagement(true);
					}}
				>
					<Hidden smDown>
						<AssignmentOutlined style={{ fontSize: "14px" }} />
					</Hidden>
					Assign Job
				</button>
				<button
					className="secondaryButton icon-btn ms-md-2 ms-1"
					// style={{ width: "3rem" }}
					onClick={() => {
						setEditJob(true);
					}}
					style={{ width: "auto" }}
				>
					Edit Job
					<Hidden smDown>
						<EditOutlined style={{ fontSize: "14px" }} />
					</Hidden>
				</button>
				<button
					className="secondaryButton delete-btn ms-md-2 ms-1 me-sm-1"
					// style={{ width: "auto" }}
					onClick={() => {
						dispatch(
							jobDelete({ jobId, projectId, onSuccess: () => onJobCancel() })
						);
					}}
					style={{ width: "auto" }}
				>
					Delete Job
					<Hidden smDown>
						<DeleteOutline style={{ fontSize: "14px" }} />
					</Hidden>
				</button>
			</div>
			<div className="d-flex flex-wrap">
				<div className="col-12">
					<div className="d-flex flex-wrap">
						<RenderJobFields jobData={jobData} />
					</div>
				</div>
			</div>
			<div className="col-12 mt-5 mb-3 p-2">
				<div className="d-flex justify-content-between">
					<Typography className="h4 mb-3 view-job-sec-header">
						Tasks associated with this Job
					</Typography>
					<button
						className="primaryButton icon-btn ms-2 col-4"
						// style={{ width: "8rem" }}
						onClick={() => {
							setAddNewTask(true);
							scrollToTop();
						}}
					>
						<Task style={{ fontSize: "14px", marginRight: "3px" }} />
						Add Task
					</button>
				</div>
				<div className="" style={{ width: "100%" }}>
					<div className="p-0">
						<JobTasks
							tasksData={tasksList}
							job={jobData}
							jobId={jobData?.jobId}
							hideAddTask={true}
							getUpdatedJobData={() => {
								dispatch(fetchJobById(params.jobId || jobId));
							}}
						/>
					</div>
				</div>
			</div>

			<JobFileManager jobId={jobId} jobData={jobData} />
		</div>
	);
};

export const RenderJobFields = ({ jobData }) => {
	const getFormattedDate = (date) => getMomentDate(date);

	const displayedFields = [
		{
			key: "jobName",
			label: "Job Name",
			dataPath: "jobName",
		},
		{
			key: "jobCode",
			label: "Job Code",
			dataPath: "jobCode",
		},
		{
			key: "jobType",
			label: "Job Type",
			dataPath: "jobType",
			formatter: (type) => type.jobType,
		},
		{
			key: "jobStatus",
			label: "Job Status",
			dataPath: "jobStatus",
			formatter: (status) => status.statusName,
		},
		{
			key: "assignedTo",
			label: "Assigned To",
			dataPath: "company.companyName",
		},
		{
			key: "jobSupervisor",
			label: "Job Supervisor",
			dataPath: "jobSupervisor",
		},
		{
			key: "estimatedCost",
			label: "Estimated Cost",
			dataPath: "estimatedCost",
			formatter: (data) => (data ? `$ ${data}` : "NA"),
		},
		{
			key: "costToDate",
			label: "Cost Till Date",
			dataPath: "costToDate",
			formatter: (data) => (data ? `$ ${data}` : "NA"),
		},
		{
			key: "plannedStartDate",
			label: "Planned Start Date",
			dataPath: "plannedStartDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "plannedEndDate",
			label: "Planned End Date",
			dataPath: "plannedEndDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "actualStartDate",
			label: "Actual Start Date",
			dataPath: "actualStartDate",
			formatter: (data) => getFormattedDate(data),
		},
		{
			key: "actualEndDate",
			label: "Actual End Date",
			dataPath: "actualEndDate",
			formatter: (data) => getFormattedDate(data),
		},

		{
			key: "jobNotes",
			label: "Job Notes",
			dataPath: "jobNotes",
		},
	];

	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 mt-4"}>
				<div className="d-flex flex-wrap col-12">
					{displayedFields.map((field) => {
						return (
							<div className="col-4 mb-3">
								<Typography
									className="body1"
									style={{ fontWeight: 500, color: "#0B2240" }}
								>
									{field.label}
								</Typography>

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{field.formatter
										? field.formatter(get(jobData, field.dataPath, "NA"))
										: get(jobData, field.dataPath, "NA") || "NA"}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export const JobFileManager = ({ jobId, jobData }) => {
	const dispatch = useDispatch();
	const existingFiles = useSelector(selectAllFiles);
	const isUploading = useSelector(selectIsUploading);

	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const fetchExistingDocs = useCallback(() => {
		dispatch(fetchFiles("Job", jobId));
	}, [dispatch, jobId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	const handleUploadFiles = (onUploadSuccessCallback) => {
		dispatch(
			bulkUploadFiles(
				filesState,
				fileLabelsState,
				documentTypeState,
				jobId,
				"Job",
				() => {
					fetchExistingDocs();
					onUploadSuccessCallback();
				}
			)
		);
	};

	return (
		<>
			<div className="d-flex flex-wrap col-12 mb-5">
				<div className={"col-12 "}>
					<Typography className="h4 view-job-sec-header">
						Job Related Documents
					</Typography>

					<FileUpload
						setFilesState={setFilesState}
						setFileLabelsState={setFileLabelsState}
						setDocumentTypeState={setDocumentTypeState}
						onUploadButtonClick={handleUploadFiles}
						showUploadCancelButtons
					/>

					{/* Pass the files, labels, upload status, and existing files to FilesList */}
					<FilesList
						files={existingFiles || []}
						fileLabels={fileLabelsState}
						uploadStatus={{}}
						title="    "
						showToolbar
					/>
					{isUploading && (
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="center"
							justifyContent="center"
							sx={{ minHeight: "50vh" }}
						>
							<Grid item xs={3}>
								<LinearProgress size="3rem" />
							</Grid>
						</Grid>
					)}
				</div>
			</div>
		</>
	);
};

export default ViewJob;
