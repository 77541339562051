import React, { useState, useEffect, useContext } from "react";
import AddEditJob from "pages/job/AddNewJob";
import { useDispatch, useSelector } from "react-redux";
import { jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import {
  IconButton,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

import DataGridTable from "components/Table/DataGrid";

import { useNavigate } from "react-router-dom";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import NoResultsFound from "components/NoResults/NoResultsFound";
import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAppRole, isJobManager, roleConfigKeys } from "core/utils/roleUtils";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import ManageJobUsers from "pages/projects/tabComponents/jobs/ManageJobUsers";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";
import {
  companyDelete,
  fetchMarketCompanies,
  marketCompanyClearShowSnackbar,
} from "actions/marketCompanyActions";
import { getMarketPlaceCompanies } from "selectors/marketPlaceSelectors";
import { AddNewCompany } from "./AddNewCompany";
import { companiesListColumns } from "./marketCompanyUtils";

export const MarketCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companies, totalCount, loading, showSnackbar, status, statusText } =
    useSelector(getMarketPlaceCompanies);

  const [addNewJob, setAddNewJob] = useState(false);

  const [pageModel, setPageModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [editJob, setEditJob] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [userManagement, setUserManagement] = useState(false);
  const [jobActionsContext, setJobActionsContext] = useState(null);

  const appRoleDetails = useContext(AppRoleContext);

  useEffect(() => {
    dispatch(fetchMarketCompanies({ ...pageModel }));
  }, [dispatch, pageModel]);

  useEffect(() => {
    console.log("selected", selectedJob);
  }, [selectedJob]);

  if (addCompany) {
    return (
      <AddNewCompany
        onCancel={() => setAddCompany(false)}
        parentId={{ jobId: selectedJob.id }}
      />
    );
  }

  return (
    <div className="market-companies-page p-3">
      <ModelDialog
        fullWidth
        open={userManagement}
        handleClose={() => {
          setUserManagement(false);
        }}
        dialogTitle={
          <div className="d-flex justify-content-between">
            <Typography className="h4 mb-4">{"Assign Job"}</Typography>
            <IconButton
              onClick={() => {
                setUserManagement(false);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        }
        dialogContent={
          <>
            {/* <ManageJobUsers
							jobRefId={jobActionsContext}
							projectId={projectId}
						/> */}
            <JobTeamList projectRefId={jobActionsContext} title={" "} />
          </>
        }
      />
      {addNewJob ? (
        <AddNewCompany
          onCancel={() => setAddNewJob(false)}
          jobId={editJob}
          selectedJob={selectedJob}
          title={get(selectedJob, "data.companyName", null)}
        />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => marketCompanyClearShowSnackbar()}
          >
            <Alert
              onClose={() => marketCompanyClearShowSnackbar()}
              severity={status}
            >
              {statusText}
            </Alert>
          </Snackbar>

          <div className="projects-header-section">
            <div>
              <Typography className="h1">Companies</Typography>
              {/* <BreadCrumb /> */}
            </div>
          </div>
          <div className="projects-content-section">
            {totalCount > 0 ? (
              <DataGridTable
                pagination={totalCount > 10}
                totalCount={totalCount}
                isLoading={loading}
                columns={companiesListColumns({
                  setEditJob,
                  setAddCompany,
                  setAddNewJob,
                  setSelectedJob,
                  appRoleDetails,
                  onCompanyDelete: (companyId) => {
                    dispatch(companyDelete({ companyId, pageModel }));
                  },
                  setUserManagement,
                  setJobActionsContext,
                })}
                data={companies}
                defineRowId={(row) => {
                  return row.mktCompanyId;
                }}
                paginationModel={pageModel}
                onPaginationChange={(props) => {
                  setPageModel(props);
                }}
                onSelect={(props) => {
                  setSelectedRows(props);
                }}
                selectedRows={selectedRows}
                onDeleteSelection={() => {
                  console.log("Delete Selection : ", selectedRows);
                }}
                onRowClick={(row) => {
                  setAddNewJob(!addNewJob);
                  setSelectedJob({ data: row.row });
                  //navigate(`/settings/companies/view/${row.id}`);
                }}
              />
            ) : (
              <NoResultsFound />
            )}
          </div>
        </>
      )}

      <HiddenOn
        isHidden={!hasAppRole(roleConfigKeys.CREATE_JOB, appRoleDetails)}
      >
        {!addNewJob && (
          <div className="page-footer  fixed-footer align-content-center">
            <div className="d-flex justify-content-end ">
              <button
                className="primaryButton addProjectButton"
                style={{ width: "8rem" }}
                onClick={() => {
                  setAddNewJob(!addNewJob);
                }}
              >
                {"+ Add Company"}
              </button>
            </div>
          </div>
        )}
      </HiddenOn>
    </div>
  );
};

export default MarketCompany;
