import {
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAILURE,
  FILE_MANAGER_GET_JOBS_LOADING,
  FILE_MANAGER_GET_JOBS_SUCCESS,
  FILE_MANAGER_GET_JOBS_ERROR,
} from "../actions/fileManagerActions";

// const initialState = {
//   propertyList: [],
//   loading: false,
//   error: null,
//   totalCount: null,
// };

const initialState = {
  propertyList: [],
  totalCount: 0,
  loading: false,
  error: false,
  status: "success",
  text: "",
  showSnackbar: false,
  propertyData: {},
  pastProperties: [],
  pastPropertiesLoading: false,
  jobs_loading: false,
  jobs: [],
};

const fileManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case FETCH_PROPERTIES_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case FILE_MANAGER_GET_JOBS_LOADING:
      return {
        ...state,
        jobs_loading: true,
        jobs: [],
        ...action.payload,
      };
    case FILE_MANAGER_GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs_loading: false,
        ...action.payload,
      };
    case FILE_MANAGER_GET_JOBS_ERROR:
      return {
        ...state,
        jobs_loading: false,
        jobs: [],
        ...action.payload,
      };
    default:
      return state;
  }
};

export default fileManagerReducer;
