import request from "config/api";
import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILES_REQUEST,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
  BULK_UPLOAD_FILE_REQUEST,
  BULK_UPLOAD_FILE_SUCCESS,
  BULK_UPLOAD_FILE_FAILURE,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  PREVIEW_FILE_REQUEST,
  PREVIEW_FILE_SUCCESS,
  PREVIEW_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  FETCH_DOCUMENT_TYPES_FAILURE,
  FETCH_DOCUMENT_TYPES_REQUEST,
  FETCH_DOCUMENT_TYPES_SUCCESS,
} from "../constants/documentsConstants";
import { server_url } from "constants/types";
import queryString from "query-string";
import { createDownloadFile } from "core/utils/appUtils";

export const uploadFile = (file, label, id, identifier) => async (dispatch) => {
  dispatch({ type: UPLOAD_FILE_REQUEST });

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const defaultLabel = file.name.split(".")[0];
    request({
      method: "POST",
      url: `${server_url}/Documents?Label=${
        label || defaultLabel
      }&folderName=${identifier}&InstId=${id}`,
      body: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response });
      })
      .catch((e) => {
        dispatch({
          type: UPLOAD_FILE_FAILURE,
          error: {
            text: "Error occurred during document upload : " + e.message,
            status: "error",
            loading: false,
            showSnackbar: true,
          },
        });
        throw new Error("Failed to upload document");
      });
  } catch (error) {
    //dispatch({ type: UPLOAD_FILE_FAILURE, error: error.message });
  }
};

export const bulkUploadFiles =
  (
    files,
    labels,
    docTypes,
    id,
    identifier,
    callBackFn = () => {},
    errCallBack = () => {}
  ) =>
  async (dispatch) => {
    dispatch({ type: BULK_UPLOAD_FILE_REQUEST });

    try {
      Promise.allSettled(
        files.map((file, index) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", file.name);
          const defaultLabel = file.name.split(".")[0];
          return request({
            method: "POST",
            url: `${server_url}/Documents?Label=${
              labels[index] || defaultLabel
            }&documentTypeId=${docTypes}&folderName=${identifier}&InstId=${id}`,
            body: formData,
            headers: {
              "content-type": "multipart/form-data",
            },
          });
        })
      )
        .then(async (response) => {
          const allUploaded = response.every(
            (res) => res.status === "fulfilled"
          );
          const partialUploaded = response.some(
            (res) => res.status === "rejected"
          );
          if (allUploaded) {
            dispatch({
              type: BULK_UPLOAD_FILE_SUCCESS,
              payload: {
                response,
                files,
                message: "All files uploaded successfully",
              },
            });
          } else if (partialUploaded) {
            errCallBack(response);
            dispatch({
              type: BULK_UPLOAD_FILE_SUCCESS,
              payload: {
                response,
                message: "Some files having issue in uploading",
              },
            });
          }

          callBackFn(response, id);
        })
        .catch((e) => {
          errCallBack(e);
          dispatch({
            type: BULK_UPLOAD_FILE_FAILURE,
            error: {
              text: "Error occurred during document upload : " + e.message,
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          // throw new Error("Failed to upload document");
        });
    } catch (error) {
      //dispatch({ type: UPLOAD_FILE_FAILURE, error: error.message });
      errCallBack(error);
      dispatch({
        type: BULK_UPLOAD_FILE_FAILURE,
        error: {
          text: "Error occurred during document upload : " + error,
          status: "error",
          loading: false,
          showSnackbar: true,
        },
      });
    }
  };

export const fetchFiles = (identifier, id) => async (dispatch) => {
  dispatch({ type: FETCH_FILES_REQUEST });
  try {
    request({
      method: "GET",
      url: `${server_url}/Documents?ObjectType=${identifier}&InstId=${id}`,
      // url: encodeURI(
      //   `${server_url}/Documents/Download?fileName=DxDiag.txt&folderName=Company_36/Property_82`
      // ),
    })
      .then(async (response) => {
        dispatch({ type: FETCH_FILES_SUCCESS, payload: response.data.items });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            dispatch({ type: FETCH_FILES_SUCCESS, payload: [] });
          }
        } else {
          dispatch({
            type: FETCH_FILES_FAILURE,
            error: {
              text: "Error occurred during documents listing : " + e.message,
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          throw new Error("Failed to fetch documents");
        }
      });
  } catch (error) {
    dispatch({ type: FETCH_FILES_FAILURE, error: error.message });
  }
};

export const downloadFile = (file) => async (dispatch) => {
  dispatch({ type: DOWNLOAD_FILE_REQUEST });
  try {
    const queryParams = queryString.stringify(file);
    request({
      method: "GET",
      url: `${server_url}/Documents/Download?fileName=${file.fileName}&folderName=${file.filePath}`,
      headers: {
        "content-type": "application/octet-stream",
      },
    })
      .then(async (fileResponse) => {
        console.log("Check the Response : ", fileResponse);
        dispatch({ type: DOWNLOAD_FILE_SUCCESS, payload: fileResponse.data });
        try {
          // Get the blob from the response
          const blob = new Blob([fileResponse.data]);

          // Create a URL for the blob
          const downloadUrl = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = file.fileName; // Set the download filename

          // Append to document, click, and remove
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Clean up the blob URL
          window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
          console.error("Download failed:", error);
          throw error;
        }
      })
      .catch((e) => {
        dispatch({
          type: DOWNLOAD_FILE_FAILURE,
          error: {
            text: "Error occurred during documents listing : " + e.message,
            status: "error",
            loading: false,
            showSnackbar: true,
          },
        });
        throw new Error("Failed to fetch documents");
      });
  } catch (error) {
    dispatch({ type: DOWNLOAD_FILE_FAILURE, error: error.message });
  }
};

export const previewFileAction =
  (file, isDownload = false) =>
  async (dispatch) => {
    dispatch({ type: PREVIEW_FILE_REQUEST });
    try {
      request({
        method: "GET",
        url: `${server_url}/Documents/Preview?fileName=${file.fileName}&folderName=${file.filePath}`,
        headers: {
          // "content-type": "application/octet-stream",
          headers: {
            "x-ms-version": "2020-04-08",
            Accept: "*/*",
          },
        },
      })
        .then(async (response) => {
          console.log("Check the Response : ", response);
          if (isDownload) {
            dispatch(downloadFileFromAzure(response.data, file));
          } else {
            dispatch({ type: PREVIEW_FILE_SUCCESS, payload: response.data });
          }
        })
        .catch((e) => {
          dispatch({
            type: PREVIEW_FILE_FAILURE,
            error: {
              text: "Error occurred during documents listing : " + e.message,
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          throw new Error("Failed to fetch documents");
        });
    } catch (error) {
      dispatch({ type: PREVIEW_FILE_FAILURE, error: error.message });
    }
  };

export const downloadFileFromAzure =
  (presignedUrl, file) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_FILE_REQUEST });
    try {
      // Fetch the file
      const response = await fetch(presignedUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get total size
      const totalSize = parseInt(response.headers.get("content-length") || "0");
      let downloadedSize = 0;

      // Create a ReadableStream from the response
      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        chunks.push(value);
        downloadedSize += value.length;

        // Calculate and report progress
        const progress = (downloadedSize / totalSize) * 100;
        // onProgress?.(progress);
      }

      // Combine chunks into a single Blob
      const blob = new Blob(chunks);
      dispatch({ type: DOWNLOAD_FILE_SUCCESS });

      createDownloadFile(blob, file);
    } catch (error) {
      console.error("Download failed:", error);
      throw error;
    }
  };

export const deleteFile = (id) => async (dispatch) => {
  dispatch({ type: DELETE_FILE_REQUEST });
  try {
    request({
      method: "DELETE",
      url: `${server_url}/Documents/${id}`,
    })
      .then(async (response) => {
        dispatch({
          type: DELETE_FILE_SUCCESS,
          payload: {
            ...response,
            showSnackbar: true,
            text: "Document deleted successfully",
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: DELETE_FILE_FAILURE,
          error: {
            text: "Error occurred during document deletion : " + e.message,
            status: "error",
            loading: false,
            showSnackbar: true,
          },
        });
        throw new Error("Failed to delete document");
      });
  } catch (error) {
    dispatch({ type: DELETE_FILE_FAILURE, error: error.message });
  }
};
export const fetchDocumentTypes = () => async (dispatch) => {
  dispatch({ type: FETCH_DOCUMENT_TYPES_REQUEST });
  try {
    request({
      method: "GET",
      url: `${server_url}/DocumentType`,
    })
      .then(async (response) => {
        dispatch({
          type: FETCH_DOCUMENT_TYPES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: FETCH_DOCUMENT_TYPES_FAILURE,
          error: {
            text:
              "Error occurred during document types fetching : " + e.message,
            status: "error",
            loading: false,
            showSnackbar: true,
          },
        });
        throw new Error("Failed to fetch document types");
      });
  } catch (error) {
    dispatch({ type: FETCH_DOCUMENT_TYPES_FAILURE, error: error.message });
  }
};
