import React, { useState } from "react";
import { isJobManager } from "core/utils/roleUtils";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  MenuItem,
  IconButton,
  Typography,
  TextField,
  Box,
  Chip,
  InputAdornment,
} from "@mui/material";
import {
  AssignmentOutlined,
  Attachment,
  Delete,
  EditOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import startCase from "lodash/startCase";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";

import BurgerMenu from "components/Menu/BurgerMenu";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import GeolocationField from "components/Location/GeoLocation";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import DatePickerField from "components/DateComponets/DatePicker";
import isUndefined from "lodash/isUndefined";
import HiddenOn from "components/Wrappers/HiddenOn";

import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { TaskStatus } from "pages/task/addTaskUtils";
import { previewFileAction } from "actions/documentsActions";
const getTextContent = (key) => getText(`warranty.addWarranty.${key}`);
const addCompanyValidationSchema = yup.object({
  productName: yup
    .string("Enter Product Name")
    .required("Product Name is required"),
  description: yup.string("Enter Description"),
  // .required("Description is required"),
  warrantyStartDate: yup.date().required("Warranty StartDate is required"),
  warrantyDuration: yup.string("Select Duration").required("Duration required"),
  supplierName: yup.string("Enter Supplier").required("Supplier required"),

  // receipts: yup.mixed().required("Receipt file required"),
  // productPhotos: yup.mixed().required("Product Photos required"),
  // budget: yup
  // 	.number("Enter total Budget")
  // 	.required("budget count is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("budget count must be an integer"),

  // costToDate: yup
  // 	.number("Enter cost till now")
  // 	.required("Cost is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("Cost must be an integer"),
});

let d = [];
for (var i = 1; i < 48; i++) {
  d.push(i.toString());
}
export const durationOptions = d;

export const AddWarrantyForm = {
  steps: Array.from(
    { length: 1 },
    (_, index) => `profile.contractor.step${index + 1}`
  ),
  stepDescription: {
    step0: {
      subHeading:
        "Please enter all the details of the Warranty that are required to be filled.",
    },
  },
  formFields: {
    step0: [
      {
        key: "serialNumber",
        placeholder: "serialNumber",
        label: getTextContent("serialNumber"),
        type: "number",
        width: "20%",
      },
      {
        key: "productName",
        placeholder: "Product Name",
        label: getTextContent("productName"),
        type: "text",
        required: true,
        width: "100%",
      },

      {
        key: "description",
        placeholder: getTextContent("description"),
        label: getTextContent("description"),
        type: "textArea",
        width: "100%",
      },
      {
        key: "warrantyStartDate",
        placeholder: getTextContent("warrantyStartDate"),
        label: getTextContent("warrantyStartDate"),
        type: "dateField",
        required: true,
        width: "100%",
      },
      {
        key: "warrantyDuration",
        placeholder: "Duration",
        label: getTextContent("duration"),
        type: "select",
        select: true,
        required: true,
        options: durationOptions,
        width: "50%",
        value: "12",
      },

      {
        key: "supplierName",
        placeholder: getTextContent("supplierName"),
        label: getTextContent("supplierName"),
        type: "DatePicker",
        required: true,
        width: "100%",
      },

      {
        key: "receipts",
        placeholder: "Receipts ",
        label: getTextContent("receipts"),
        type: "file",
        required: true,
        width: "100%",
      },
      {
        key: "productPhotos",
        placeholder: "Product Photos ",
        label: getTextContent("productPhotos"),
        type: "file",
        required: true,
        width: "100%",
      },
    ],
  },
  validationSchema: addCompanyValidationSchema,
  defaultState: (defaultState) => {
    if (defaultState) {
      return defaultState;
    } else {
      const dynamicInitialState = Object.fromEntries(
        Object.keys(addCompanyValidationSchema.fields).map((key) => [key, ""])
      );

      return {
        ...dynamicInitialState,
      };
    }
  },
};

export const warrantyListColumns = (props = {}, dispatch) => [
  { field: "serialNumber", headerName: "Serial Number", width: 150 },
  { field: "productName", headerName: "Product Name", width: 200 },
  {
    field: "description",
    headerName: "Description",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      return (
        <WarrantyStatus status={get(params.row, "status", "Under Warranty")} />
      );
    },
    width: 150,
  },
  {
    field: "warrantyStartDate",
    headerName: "Warranty Start Date",
    width: 120,
    renderCell: (params) => {
      return formatDateToReadable(params.value);
    },
  },
  {
    field: "warrantyDuration",
    headerName: "Warranty",
    renderCell: (params) => {
      return <> {params.value} Months </>;
    },
  },
  {
    field: "supplierName",
    headerName: "Supplier Name",
    width: 150,
  },
  {
    field: "receipt",
    headerName: "Receipt",
    renderCell: (params) => {
      return params.row.receiptId ? (
        <>
          <Attachment
            title={params.row.receiptDocuments.fileName}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(previewFileAction(params.row.receiptDocuments, true));
            }}
          />
        </>
      ) : null;
    },
  },
  {
    field: "productPhoto",
    headerName: "Product Photos",
    renderCell: (params) => {
      return params.row.productPhotoId ? (
        <Attachment
          onClick={(e) => {
            e.stopPropagation();
            dispatch(previewFileAction(params.row.productDocuments, true));
          }}
        />
      ) : null;
    },
  },

  {
    field: "actions",
    type: "actions",
    flex: 1,
    align: "right",
    getActions: (params) => [
      <BurgerMenu>
        <MenuItem
          onClick={() => {
            console.log("Check the Params : ", params);
            props.setEditJob(true);
            props.setAddNewJob(true);
            props.setSelectedJob({ id: params.warrantyId, data: params.row });
          }}
        >
          <EditOutlined /> <p className="m-0">Edit</p>
        </MenuItem>

        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.onCompanyDelete(params.id);
            }}
          >
            <Delete /> <p className="m-0">Delete</p>
          </MenuItem>
        </HiddenOn>
      </BurgerMenu>,
    ],
  },
];

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileToBase64() {
  const file = document.querySelector("#myfile").files[0];
  console.log(await getBase64(file));
}

export const WarrantyStatus = ({ status }) => {
  const statusColors = {
    "Expiring soon": "#FFBA08",
    Expired: "#FF5630",
    "Under Warranty": "#36B37E",
  };
  return (
    <>
      <div
        style={{
          border: `1px solid ${statusColors[status]}`,
          width: "8rem",
          height: "2rem",
          borderRadius: "5px",
        }}
        className="d-flex align-items-center "
      >
        <StatusIndicator
          color={statusColors[status]}
          className="ms-2 me-2 mb-1"
        />
        <Typography
          variant="body1"
          className="ms-2"
          style={{ color: "#0B2240", fontSize: "11px" }}
        >
          {startCase(status)}
        </Typography>
      </div>
    </>
  );
};

function formatDateToReadable(dateString) {
  const date = new Date(dateString);

  // Function to get the correct day suffix
  const getDaySuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${getDaySuffix(day)} ${month} ${year}`;
}

// Example usage
const formattedDate = formatDateToReadable("2024-11-12T00:00:00");
console.log(formattedDate); // Output: 12th Nov 2024
