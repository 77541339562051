import {
	Close,
	HelpOutlineOutlined,
	InfoOutlined,
	SettingsOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
	Typography,
	TextField,
	InputAdornment,
	Autocomplete,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { AgChartsReact } from "ag-charts-react";
import { RenderFoldersList } from "pages/property/PropertiesFolderView";
import { useDispatch, useSelector } from "react-redux";
import { propertyGetAll } from "actions/propertyActions";
import { getProperties } from "selectors/propertySelectors";
import { useNavigate } from "react-router-dom";
import {
	calculateTimeAgoText,
	serializeStringToJson,
} from "core/utils/appUtils";
import {
	getAlertNotifications,
	getDashboardDetails,
} from "selectors/appSelector";
import { globalSearchAction } from "actions/appActions";
import { getGlobalSearch } from "selectors/appSelector";

import { fetchDashboardData } from "actions/appActions";
import get from "lodash/get";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";

import { MOBILE_MAX_WIDTH_768 } from "constants/appConstants";

export const Dashboard = () => {
	const dispatch = useDispatch();
	const dashboardData = useSelector(getDashboardDetails);

	const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);
	const navigate = useNavigate();

	const [searchTerm, setSearchTerm] = useState("");
	const [selectedItem, setSelectedItem] = React.useState(null);
	const { searchResults, loadingResults } = useSelector(getGlobalSearch);

	const redirectTypes = {
		Project: "/projects/view",
		Property: "/properties/view",
		Job: "/jobs/view",
		Task: "/tasks/view",
	};

	useEffect(() => {
		dispatch(globalSearchAction(" "));
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchDashboardData());
	}, [dispatch]);

	const getDataValue = (key) => {
		return get(dashboardData, key, "-");
	};

	return (
		<>
			{isMobile ? (
				<Autocomplete
					freeSolo
					value={selectedItem}
					onChange={(event, value) => {
						setSelectedItem(value);
						const optionPath = redirectTypes[value.objecttype];
						console.log(
							"On Change : ",
							redirectTypes,
							value.objecttype,
							optionPath
						);
						navigate(`${optionPath}/${value.id}`);
					}}
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "center",
						marginBlockEnd: "20px",
						marginTop: "20px",
					}}
					id="global-search-field"
					isOptionEqualToValue={(option, value) => option.id === value.id}
					filterOptions={(x) => x}
					getOptionLabel={(option) => option.name}
					options={searchResults}
					loading={loadingResults}
					onInputChange={(event, value) => {
						setSearchTerm(value);
						console.log("onInputChange ,", value);
						if (value.length >= 3) {
							dispatch(globalSearchAction(value));
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder="Search for Address, Property, Homeowner, Project & Job."
							sx={{
								width: "90%",
								"& .MuiOutlinedInput-root": {
									borderRadius: "10px",
									backgroundColor: "#fff",
								},
							}}
							InputLabelProps={{
								style: {
									fontFamily: "Barlow",
									fontWeight: "400",
									fontSize: "14px",
									letterSpacing: "-3%",
									color: "#fff",
									// Add any other CSS properties you want
								},
							}}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon sx={{ fontSize: 30 }} />
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			) : (
				<div style={{ backgroundColor: "#FFFFFF" }}>
					<Typography className="h4  ms-3" style={{ height: "3rem" }}>
						Welcome!
					</Typography>
				</div>
			)}

			<div id="dashboard-container" className="dashboard-container">
				<div style={{ backgroundColor: "#FFFFFF" }}>
					<Typography className="h4  ms-3" style={{ height: "3rem" }}>
						Dashboard
					</Typography>
				</div>
				<div id="dashboard-overview" className="dashboard-overview">
					<div className="d-flex flex-wrap">
						<div className="col-lg-4 col-md-6 col-12 m-0 overview-col-section">
							{[
								{
									label: "YTD Bookings",
									key: "ytdBookings",
									value: `$ ${getDataValue("ytdBookings")}`,
								},
								{
									label: "Average Revenue per Sqft",
									key: "avgRevenuePerSqft",
									value: `$ ${getDataValue("avgRevenuePSQFT")}`,
									suffix: "/FT",
								},
								{
									label: "Average Cost per Sqft",
									key: "avgCostPerSqft",
									value: `$ ${getDataValue("avgCostPSQFT")}`,
									suffix: "/FT",
								},
							].map((item, index) => {
								return (
									<div
										id={item.key}
										className="dashboard-card multi-card-col m-2 mt-3 mb-3"
									>
										<div className="card-content">
											<CardHeader item={item} />
											<div className="card-body">
												<Typography className="m-1 card-value" variant="h5">
													{item.value}
													<span>{item.suffix}</span>
												</Typography>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className="col-lg-4 col-md-6 col-12 m-0 overview-col-section">
							<div id="sqftCompleted">
								<div className="dashboard-card m-2 mt-3 mb-3 ">
									<div className="card-content">
										<CardHeader
											item={{ label: "SQFT Completed", key: "sqftCompleted" }}
										/>
										<div className="card-body">
											<Typography className="m-1 card-value" variant="h5">
												{getDataValue("sqftCompleted")}
												<span>SQ.FT</span>
											</Typography>
										</div>
									</div>
								</div>
							</div>
							<div id="projectsByStatus" className="card-flex-grow">
								<div className="dashboard-card m-2 mt-3 mb-3">
									<div className="card-content">
										<CardHeader
											item={{
												label: "Projects by Status",
												key: "projectsByStatus",
											}}
										/>
										<ProjectStatusChart />
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-12 m-0 overview-col-section">
							<div className="dashboard-card m-2 mt-3 mb-3">
								<div className="card-content">
									<CardHeader
										item={{
											label: "Projects Pipeline",
											key: "pipelineProjects",
										}}
									/>
									<ProjectPipelineChart />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="dashboard-recent-activities " className="d-flex flex-wrap">
					<div
						id="recent-properties"
						className="dashboard-card col-md-7 col-12 m-2"
					>
						<RecentPropertiesList />
					</div>
					<div
						id="recent-alerts"
						className="dashboard-card col-md-3 col-12 m-2"
					>
						<RecentAlertsList />
					</div>
				</div>
			</div>
		</>
	);
};

export const CardHeader = ({ item }) => {
	return (
		<>
			<div className="card-header d-flex justify-content-between">
				<Typography className="m-1 card-title" variant="body1">
					{item.label}
				</Typography>
				<div className="d-flex align-items-center">
					<p className="m-1">
						<SettingsOutlined className="help-icons pointer" />
					</p>
					<p className="m-1">
						<HelpOutlineOutlined className="help-icons pointer" />
					</p>
				</div>
			</div>
		</>
	);
};

export const ProjectStatusChart = () => {
	const dashboardData = useSelector(getDashboardDetails);
	const projectStatus = get(dashboardData, "projectStatus.projects", []);
	const totalProjects = get(dashboardData, "projectStatus.totalProjects", 0);
	const options = {
		data: projectStatus,
		height: 300, // Increased height to accommodate legend
		legend: {
			enabled: true,
			position: "bottom",
			item: {
				paddingY: 15,
			},
			spacing: 40,
		},
		series: [
			{
				type: "donut",
				calloutLabelKey: "count",
				angleKey: "count",
				labelKey: "status",
				innerRadiusRatio: 0.7,
				strokeWidth: 3,
				fills: ["#345DAE", "#0B2240", "#FAAF40"], // Orange, Royal Blue, Navy
				strokes: ["#FFFFFF"],
				legendItemKey: "status", // This ensures legend items are created
			},
		],
	};
	return (
		<div>
			<AgChartsReact options={options} />
		</div>
	);
};

export const ProjectPipelineChart = () => {
	const dashboardData = useSelector(getDashboardDetails);

	const projectStatus = get(dashboardData, "projectStatus.projects", []);
	const totalProjects = get(dashboardData, "projectStatus.totalProjects", 0);

	const options = {
		data: projectStatus,
		height: 400,
		legend: {
			enabled: true,
			position: "bottom",
		},
		series: [
			{
				innerLabels: [
					{
						text: "Total Projects",
						spacing: 4,
						fontSize: 20,
						fontWeight: "bold",
						color: "#345DAE",
					},
					{
						text: String(totalProjects),
						spacing: 4,
						fontSize: 24,
						marginTop: "1rem",
						fontWeight: "bold",
						color: "#345DAE",
					},
				],
				type: "donut",
				calloutLabelKey: "count",
				angleKey: "count",
				innerRadiusRatio: 0.7,
				strokeWidth: 3,
				fills: [
					"#345DAE",
					"#0B2240",
					"#FAAF40",
					"#8B8B8B",
					"#2D4DC8",
					"#36B37E",
				], // Orange, Royal Blue, Navy
				strokes: ["#FFFFFF"],
				legendItemKey: "status",
			},
		],
	};
	return (
		<div>
			<AgChartsReact options={options} />
		</div>
	);
};

export const RecentPropertiesList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { propertiesList } = useSelector(getProperties);
	const pageModel = { pageNumber: 1, pageSize: 15 };

	useEffect(() => {
		dispatch(
			propertyGetAll({
				...pageModel,
				Year: new Date().getFullYear(),
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);
	return (
		<div style={{ height: "100%" }} className="d-flex flex-column">
			<CardHeader
				item={{ label: "Recent Properties", key: "recentProperties" }}
			/>
			<div className="d-flex flex-wrap">
				<RenderFoldersList
					propertiesList={propertiesList}
					pageModel={pageModel}
				/>
			</div>
			{propertiesList && propertiesList.length > 0 && (
				<div
					className="text-center align-content-end mb-3"
					style={{ height: "100%" }}
				>
					<button
						className="outlinedButton"
						style={{ width: "10rem" }}
						onClick={() => {
							navigate("/properties");
						}}
					>
						See All Properties
					</button>
				</div>
			)}
		</div>
	);
};
export const alerts = [
	{
		source: "CNN",
		title:
			"What Key Plumbing Trends Will Impact the Industry in 2025 and Beyond?",
		content:
			"After facing marginal decline in 2023, the plumbing industry has started to regain its footing in 2024, with growth in the market projected over the next few years.",
		dateTime: "2024-10-08T10:30:00", // Example date
	},
	{
		source: "TimesNow",
		title:
			"Tornado, severe weather alert for 19.5 million people in Texas, Louisiana, Southern Arkansas, Mississippi.",
		content: "",
		dateTime: "2024-10-08T14:45:00", // Example date
	},
	{
		source: "CNN",
		title:
			"What Key Plumbing Trends Will Impact the Industry in 2025 and Beyond?",
		content:
			"After facing marginal decline in 2023, the plumbing industry has started to regain its footing in 2024, with growth in the market projected over the next few years.",
		dateTime: "2024-10-08T10:30:00", // Example date
	},
];
export const RecentAlertsList = () => {
	const navigate = useNavigate();
	const messages = useSelector(getAlertNotifications);

	return (
		<>
			<div className="d-flex flex-column" style={{ height: "100%" }}>
				<CardHeader item={{ label: "Top Alerts", key: "recentAlerts" }} />
				<div id="alerts-content" className="alerts-content" style={{ flex: 1 }}>
					<div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden">
						{messages
							.slice(0, 10)
							.map((alert) => serializeStringToJson(alert.user))
							.map((alert, index) => (
								<div className="m-2 max-w-md bg-white rounded-lg overflow-hidden">
									<div
										key={index}
										className="p-4 border-b border-gray-200 last:border-b-0 pointer alert-item"
									>
										<div className="d-flex justify-content-between">
											<div className="flex items-center mb-2">
												<InfoOutlined
													className="w-5 h-5 text-red-500 me-2"
													style={{ fontSize: "12px", color: "red" }}
												/>
												<span className="text-red-500 font-bold alert-sourceName">
													{alert.source}
												</span>
											</div>
											<div className="d-flex">
												<StatusIndicator
													status="rejected"
													width={"12px"}
													height={"12px"}
													style={{
														margin: "0rem 2rem",
														position: "relative",
													}}
												/>
												<Close style={{ fontSize: "16px", marginTop: "3px" }} />
											</div>
										</div>
										<h2 className="text-lg font-semibold mb-2 alert-title">
											{alert.alertTitle}
										</h2>
										{alert.alertMessage && (
											<p className="text-gray-600 mb-2 alert-content">
												{alert.alertMessage}
											</p>
										)}
										<span className="text-sm text-gray-500">
											{calculateTimeAgoText(alert.alertdateTime)}
										</span>
									</div>
								</div>
							))}
					</div>
				</div>
				<div className="text-center  mb-3" style={{ height: "100%" }}>
					<button
						className="outlinedButton"
						style={{ width: "10rem", bottom: "0" }}
						onClick={() => {
							navigate("/alerts");
						}}
					>
						Go to Alerts
					</button>
				</div>
			</div>
		</>
	);
};
export default Dashboard;
