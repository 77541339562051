import React, { useState } from "react";
import { isJobManager } from "core/utils/roleUtils";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  MenuItem,
  IconButton,
  Typography,
  TextField,
  Box,
  Chip,
  InputAdornment,
} from "@mui/material";
import {
  AssignmentOutlined,
  Delete,
  EditOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import GeolocationField from "components/Location/GeoLocation";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import DatePickerField from "components/DateComponets/DatePicker";
import isUndefined from "lodash/isUndefined";
import HiddenOn from "components/Wrappers/HiddenOn";

import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { addUpdateCompany } from "actions/marketCompanyActions";
const getTextContent = (key) => getText(`marketCompanies.addCompany.${key}`);

const addCompanyValidationSchema = yup.object({
  companyName: yup
    .string("Enter Company Name")
    .required("Company Name is required"),
  bizDescription: yup
    .string("Enter Description")
    .required("Description is required"),
  serviceLocations: yup
    .string("Enter Service Locations")
    .required("Service Locations is required"),
  //   servicesOffered: yup
  //     .string("Enter Services Offered")
  //     .required("Services Offered is required"),
  industries: yup.string("Enter Industries").required("Industries required"),
  builderType: yup.string("Builder Type").required("Builder Types required"),
  address: yup.string("Enter Address").required("Address is required"),
  phoneNumber: yup
    .string("Enter Phone Number")
    .required("Phone Number is required"),
  email: yup.string("Enter Email").required("Email is required"),
  slugUrl: yup.string("Enter SLUG URL").required("SLUG URL is required"),
  logo: yup.string("Please upload Logo").required("Logo is required"),

  // budget: yup
  // 	.number("Enter total Budget")
  // 	.required("budget count is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("budget count must be an integer"),

  // costToDate: yup
  // 	.number("Enter cost till now")
  // 	.required("Cost is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("Cost must be an integer"),
});

export const builderTypeOptions = [
  "GeneralContractor",
  "Engineer",
  "Architect",
];

export const AddCompanyForm = {
  steps: Array.from(
    { length: 1 },
    (_, index) => `profile.contractor.step${index + 1}`
  ),
  stepDescription: {
    step0: {
      subHeading:
        "Please enter all the details of the company that are required to be filled.",
    },
  },
  formFields: {
    step0: [
      {
        key: "companyName",
        placeholder: "Company Name",
        label: getTextContent("companyName"),
        type: "text",
        required: true,
        width: "100%",
      },
      {
        key: "logo",
        placeholder: "Logo ",
        label: getTextContent("logo"),
        type: "file",
        required: true,
        width: "100%",
      },
      {
        key: "bizDescription",
        placeholder: getTextContent("bizDescription"),
        label: getTextContent("bizDescription"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "serviceLocations",
        placeholder: getTextContent("serviceLocations"),
        label: getTextContent("serviceLocations"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "builderType",
        placeholder: "Builder Type",
        label: getTextContent("builderType"),
        type: "select",
        select: true,
        required: true,
        options: builderTypeOptions,
        width: "50%",
        value: "GeneralContractor",
      },
      {
        key: "industries",
        placeholder: getTextContent("industries"),
        label: getTextContent("industries"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "address",
        placeholder: getTextContent("address"),
        label: getTextContent("address"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "phoneNumber",
        placeholder: getTextContent("phoneNumber"),
        label: getTextContent("phoneNumber"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "email",
        placeholder: getTextContent("email"),
        label: getTextContent("email"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "slugUrl",
        placeholder: getTextContent("slugUrl"),
        label: getTextContent("slugUrl"),
        type: "textArea",
        required: true,
        width: "100%",
      },
    ],
  },
  validationSchema: addCompanyValidationSchema,
  defaultState: (defaultState) => {
    if (defaultState) {
      return defaultState;
    } else {
      const dynamicInitialState = Object.fromEntries(
        Object.keys(addCompanyValidationSchema.fields).map((key) => [key, ""])
      );

      return {
        ...dynamicInitialState,
      };
    }
  },
};

export const companiesListColumns = (props = {}) => [
  { field: "companyName", headerName: "Company Name", width: 200 },
  {
    field: "logo",
    headerName: "Logo",
    renderCell: (value) => {
      return (
        <img
          height="72px"
          src={`data:image/png;base64, ${value.formattedValue}`}
          alt="logo"
        />
      );
    },
  },

  {
    field: "serviceLocations",
    headerName: "Service Locations",
  },
  {
    field: "builderType",
    headerName: "Builder Type",
  },
  {
    field: "servicesOffered",
    headerName: "Services Offered",
  },
  {
    field: "industries",
    headerName: "Industries",
  },
  {
    field: "address",
    headerName: "Address",
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
  },
  {
    field: "claimedBiz",
    headerName: "Claimed Business",
  },
  {
    field: "bizDescription",
    headerName: "Business Description",
  },
  {
    field: "slugUrl",
    headerName: "slugUrl",
  },
  {
    field: "actions",
    type: "actions",
    flex: 1,
    align: "right",
    getActions: (params) => [
      <BurgerMenu>
        <MenuItem
          onClick={() => {
            console.log("Check the Params : ", params);
            props.setEditJob(true);
            props.setAddNewJob(true);
            props.setSelectedJob({ id: params.mktCompanyId, data: params.row });
          }}
        >
          <EditOutlined /> <p className="m-0">Edit</p>
        </MenuItem>

        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.onCompanyDelete(params.id);
            }}
          >
            <Delete /> <p className="m-0">Delete</p>
          </MenuItem>
        </HiddenOn>
      </BurgerMenu>,
    ],
  },
];

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export async function fileToBase64() {
  const file = document.querySelector("#myfile").files[0];
  console.log(await getBase64(file));
}
